import { useState, useEffect } from "react";
import { Table } from 'antd';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import '../../Components/Navbar/NavbarHeader.css';
import NavbarHeader from '../../Components/Navbar/NavbarHeader';
import circleupload from "../../assets/circleupload.svg";
import moment from 'moment'
import axios from "axios";

import * as XLSX from 'xlsx';
import SideMenuAdmin from '../../Components/SideBarAdmin/SideMenuAdmin';
import logoCerti from "../../assets/logo/LogoCerti.png";


// Css
import "../Documentation/ListDocu.css"

const data = [
    {
        key: '1',
        cliente: "Asesoría integral SL",
        fecha: '02/01/2022',
        mensualidad: '200 €',
        consumon: "18",
        consumo: "180 €",
        total: "380 €",
        estado: "Pagado",
        pdf: "Descargar PDF"
    },
    {
        key: '2',
        cliente: "ASANA Certificador Industrial SA",
        fecha: '15/01/2022',
        mensualidad: '200 €',
        consumon: "12",
        consumo: "225 €",
        total: "425 €",
        estado: "Pagado",
        pdf: "Descargar PDF"
    },
    {
        key: '3',
        fecha: '12/01/2022',
        cliente: "Asesoría integral SL",
        mensualidad: '200 €',
        consumon: "25",
        consumo: "310 €",
        total: "510 €",
        estado: "Pendiente",
        pdf: "Descargar PDF"
    },
    {
        key: '4',
        fecha: '11/01/2022',
        cliente: "ASANA Certificador Industrial SA",
        mensualidad: '200 €',
        consumon: "26",
        consumo: "305 €",
        total: "505 €",
        estado: "Pagado",
        pdf: "Descargar PDF"
    },
    {
        key: '5',
        fecha: '11/01/2022',
        cliente: "Asesoría integral SL",
        mensualidad: '200 €',
        consumon: "21",
        consumo: "250 €",
        total: "550 €",
        estado: "Pagado",
        pdf: "Descargar PDF"
    },
    {
        key: '6',
        fecha: '12/01/2022',
        cliente: "ASANA Certificador Industrial SA",
        mensualidad: '200 €',
        consumon: "22",
        consumo: "225 €",
        total: "425 €",
        estado: "Pagado",
        pdf: "Descargar PDF"
    },
];



export default function Facturacion() {
    const [dataSource, setDataSource] = useState(null);
    const [usersList, setUsersList] = useState(null);
    const [filterTable, setFilterTable] = useState(null);
    const [value, setValue] = useState('');
    const [dataexcel, setDataexcel] = useState([]);
    const [inactive, setInative] = useState(false);
    const [showData, setShowData] = useState(false);
    const [showSpinnerData, setShowSpinnerData] = useState(true);

    const [datefilter, setDatefilter] = useState('');
    const [dateoriginal, setDateoriginal] = useState('');



    const columns = [
        {
            title: 'Cliente',
            dataIndex: 'nombre',
            key: 'nombre',
        },
        {
            title: 'Fecha Creación',
            dataIndex: 'fechacreacion',
            key: 'fechacreacion',
            render: text => <div style={{ textAlign: "center" }}><span>{text}</span></div>
        },
        {
            title: 'Plan',
            dataIndex: 'plan',
            key: 'plan',
            render: plan => <p style={{ textAlign: "center" }}><span>{plan.nombre}</span> <br /> <span>Descuento: {plan.descuento} %</span> <br /> <span>Costo: {plan.costo} €</span></p>
        },
        {
            title: 'Mensualidad',
            dataIndex: 'mensualidad',
            key: 'mensualidad',
            render: mensualidad => <div style={{ textAlign: "center" }}><span>{mensualidad} €</span></div>
        },
        {
            title: 'Total Almacenamiento',
            dataIndex: 'pesoarchivos',
            key: 'pesoarchivos',
            render: text => !text ? <div style={{ textAlign: "center" }}><span>0 Bytes</span></div> : <div style={{ textAlign: "center" }}><span>{text}</span></div>
        },
        {
            title: 'Costo Almacenamiento',
            dataIndex: 'consumoTotalAlmacenamiento',
            key: 'consumoTotalAlmacenamiento',
            render: text => <div style={{ textAlign: "center" }}><span>{text.toFixed(7)} €</span></div>
        },
        {
            title: 'Consumo',
            dataIndex: 'totalParcial',
            key: 'totalParcial',
            render: text => <div style={{ textAlign: "center" }}><span>{text.toFixed(7)} €</span></div>
        },
        {
            title: 'Total',
            dataIndex: 'totalConsumo',
            key: 'totalConsumo',
            render: text => !text ? <div style={{ textAlign: "center" }}><span> 0  € </span></div> : <div style={{ textAlign: "center" }}><span>{text.toFixed(7)} €</span></div>
        },
    ];

    useEffect(() => {
        callData()
    }, []);

    useEffect(() => {
        filtrar()
    }, [datefilter, dateoriginal]);

    const callData = () => {
        axios.get(`${process.env.REACT_APP_URLSERVER}/v2/usuarios`, {
            responseType: 'json',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": user?.body?.jwt
            }

        }).then((response) => {
            let data = response.data.body.reverse()
            setUsersList(data.length)
            data.map((item) => {
                item.ciudad = item.domicilioFiscal.ciudad
                item.comunidad = item.domicilioFiscal.comunidadAutonoma
                item.cp = item.domicilioFiscal.cp
                item.direccion = item.domicilioFiscal.direccion
                item.nombreContacto = item.contacto.nombre
                item.tlfnro = item.contacto.nroTlf
                item.estadoString = item.estado === true ? "Activo" : "Bloqueado"
                item.fechacreacion = moment(moment(item.fechaCreacion)).format('DD/MM/YYYY')
                const percentage = (100 / item.plan.descuento)
                const percentage2 = (item.plan.costo / percentage)
                let valorTotal = item.plan.costo - percentage2
                item.mensualidad = valorTotal
            })
            axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=EUR&ids=matic-network&order=market_cap_desc&per_page=100&page=1&sparkline=false`, {
                responseType: 'json',
                headers: {
                    "Access-Control-Allow-Origin": "*",
                }

            }).then((response) => {
                let currencyEur = " "
                let TotalParcial = " "
                let currency = " "
                let dataapi = response.data
                let dataExcel = []
                dataapi.forEach((item) => {
                    currency = item.current_price
                    data.forEach((item2) => {
                        currencyEur = item2.consumoTotal
                        TotalParcial = currencyEur * currency
                        item2.totalParcial = TotalParcial
                        let dataPeso = item2.pesoTotalArchivos
                        let decimals = 2
                        if (dataPeso === 0) return '0 Bytes';

                        const k = 1024;
                        const dm = decimals < 0 ? 0 : decimals;
                        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

                        const i = Math.floor(Math.log(dataPeso) / Math.log(k));
                        let arrayPeso = []
                        arrayPeso.push(parseFloat((dataPeso / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]);
                        item2.pesoarchivos = arrayPeso
                        item2.totalConsumo = item2.consumoTotalAlmacenamiento + item2.totalParcial
                        // EXCEL
                        // JSON
                        item2.planNombre = item2.plan.nombre
                        item2.planCosto = item2.plan.costo
                        item2.planDescuento = item2.plan.descuento
                        dataExcel.push(
                            {
                                Cliente: item2.nombre,
                                Fecha: item2.fechacreacion,
                                Plan_Nombre: item2.planNombre,
                                Plan_Descuento: `${item2.planDescuento} %`,
                                Plan_Costo: item2.planCosto,
                                Mensualidad: item2.mensualidad,
                                Total_Almacenamiento: item2.totalConsumo,
                                Costo_Almacenamiento: item2.consumoTotalAlmacenamiento,
                                Consumo: item2.totalParcial,
                                Total: item2.totalConsumo
                            },
                        )
                        setDataexcel(dataExcel)
                    });
                })
                setDataSource(data)
                setFilterTable(data)
                setShowData(true)
                setShowSpinnerData(false)

            });
        });
    }


    const user = JSON.parse(localStorage.getItem('user-info'));

    const logeOut = () => {
        localStorage.removeItem('rol-user');
        localStorage.removeItem('user-info')
        window.location.href = "/";
    }


    const handleFilterDate = (e) => {
        const currValueTransform = moment(moment(e.target.value)).format('DD/MM/YYYY');
        const dateOriginal = e.target.value
        const currValue = currValueTransform;
        setDateoriginal(dateOriginal)
        setDatefilter(currValue)
    }

    const filtrar = () => {
        let listaFiltrada = dataSource;
        if (datefilter) {
            listaFiltrada = listaFiltrada.filter((el) => el.fechacreacion === datefilter)
        }
        if (datefilter === "Invalid date") {
            setDatefilter('')
            return setFilterTable(null)
        }

        setFilterTable(listaFiltrada)

    }


    const exportfile = () => {
        
        let dataExcel = []
        if (filterTable === null) {
            const workSheet = XLSX.utils.json_to_sheet(dataexcel)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, "actividad")
            //Binary string
            XLSX.write(workBook, { bookType: "xlsx", type: "array" })
            //Download
            XLSX.writeFile(workBook, "FacturacionData.xlsx")
        } else {
            filterTable.map((item) => {
                dataExcel.push(
                    {
                        Cliente: item.nombre,
                        Fecha: moment(moment(item.fechaCreacion)).format('DD/MM/YYYY'),
                        Plan: item.plan.nombre,
                        Descuento: item.plan.descuento,
                        Costo: item.plan.costo,
                        Mensualidad: item.mensualidad,
                        Total_Almacenamiento: item.totalConsumo,
                        Costo_Almacenamiento: item.consumoTotalAlmacenamiento,
                        Consumo: item.totalParcial,
                        Total: item.totalConsumo
                        
                    },
                )

            })
            const workSheet = XLSX.utils.json_to_sheet(dataExcel)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, "actividad")
            //Binary string
            XLSX.write(workBook, { bookType: "xlsx", type: "array" })
            //Download
            XLSX.writeFile(workBook, "FacturacionData.xlsx")
        }
    }



    return (
        <>
            <SideMenuAdmin onCollapse={(inactive) => {
                setInative(inactive);
            }} />
            <div className={`containerTwo ${inactive ? "inactive" : ""}`}>
                <img className="logo-css  d-sm-none" src={logoCerti} alt="certiblock" />

                <NavbarHeader listUsers={usersList} inputPlaceHolder={"Buscar cliente"} titlePage={"Facturación"} setValue={setValue} setFilterTable={setFilterTable} dataFilter={dataSource} inputValue={value} />



                <div className="containerTable">

                    <div className="table-responsive">
                        <Row>

                            <Col lg="4">

                            </Col>

                            <Col lg="4">

                            </Col>

                            <Col lg="4" style={{ display: "flex", justifyContent: "end" }}>
                                <div className="SelectBusqueda">
                                    <p className="title-filter">Fecha</p>

                                    <Form.Group controlId="dob">
                                        <Form.Control onChange={handleFilterDate} className="select-css" type="date" value={dateoriginal} name="dob" placeholder="Date of Birth" />
                                    </Form.Group>
                                </div>
                            </Col>

                        </Row>
                        {
                            showSpinnerData === true && (
                                <>
                                    <div style={{ justifyContent: "center", height: "50vh" }} className="d-flex align-items-center">
                                        <strong>Cargando Data...</strong>
                                        <div className="spinner-borderData ml-auto" role="status" aria-hidden="true"></div>
                                    </div>
                                </>
                            )
                        }
                        {
                            showData === true && (
                                <>
                                    <Table columns={columns} scroll={{ x: 'max-content' }} pagination={{ pageSize: 6 }} dataSource={filterTable == null ? dataSource : filterTable} />

                                </>
                            )
                        }
                        <Button className="uploadUser" onClick={exportfile}>Exportar resultados <img src={circleupload} alt="circleupload" /></Button>

                    </div>
                </div>
            </div>
        </>
    );
}