import React, { useState, useEffect } from 'react';
import { Table, Checkbox } from 'antd';
import validator from 'validator'
import "antd/dist/antd.css";
import { useNavigate } from 'react-router-dom';


import OverlayTrigger from 'react-bootstrap/OverlayTrigger';


import { Row, Col, Form, Button, Modal, Alert, Tooltip, Card } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner'
import axios from "axios";
import moment from 'moment'
// Components
import NavbarHeader from '../../Components/Navbar/NavbarHeader';
import SideMenu from '../../Components/SideBarUser/SideMenu';
import '../../Components/Navbar/NavbarHeader.css';

// Services
import { getipData } from '../../Services/documentService';
// images

import QRCode from 'qrcode';
import ChipInput from 'material-ui-chip-input';

import { EditOutlined, UndoOutlined } from "@ant-design/icons";
import { isMobile } from 'react-device-detect';
import swal from 'sweetalert';

import Swal from 'sweetalert2'



import circleupload from "../../assets/circleupload.svg";
import info from "../../assets/info.svg";
import pdf from "../../assets/pdf.svg";
import plusdocuments from "../../assets/plusdocuments.svg"
import eyeplus from "../../assets/eyeplus.svg"
import storage from "../../assets/storage.svg"
import plans from "../../assets/plans.svg"
import descuento from "../../assets/descuento.svg"
import BurnIcon from "../../assets/burn4.png"


import cloud from "../../assets/cloudcheck.svg";
import closedicon from "../../assets/boton-x.png";
import share from "../../assets/share.png";
import './Modal.css'

export default function ListDocu() {


    const [busqueda, setBusqueda] = useState(null);
    const [page, setPage] = useState(1);
    const [dataSource, setDataSource] = useState([]);
    const [filterTable, setFilterTable] = useState([]);
    const [value, setValue] = useState('');
    const [category, setCategory] = useState('');
    const [categoryStep2, setCategoryStep2] = useState('');
    const [type, setType] = useState('');
    const [typeStep2, setTypeStep2] = useState('');
    const [visibility, setVisibility] = useState('');
    const [passwordChange, setPasswordChange] = useState(false);
    const [password, setPassword] = useState('');
    const [account, setAccount] = useState('');
    const [docName, setDocName] = useState('');
    const [correoAutomatico, setCorreoAutomatico] = useState(true);
    const [contrato, setContrato] = useState('');
    const [polygon, setPolygon] = useState(process.env.REACT_APP_URL_POLYGON)
    const [dataUsuario, setDataUsuario] = useState({
        "plan": {
            "costo": 0,
            "descuento": 0,
            "nombre": ""
        },
        "almacenamiento": 0,
        "visualizaciones": 0,
        "documentos": 0
    }); 

    const [emailError, setEmailError] = useState('')

    const [spinnermail, setSpinnermail] = useState(false);

    const [typefilter, setTypefilter] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');
    const [datefilter, setDatefilter] = useState('');
    const [dateoriginal, setDateoriginal] = useState('');

    const [studentName, setStudentName] = useState('');
    const [studentMail, setStudentMail] = useState('');
    const [studentId, setStudentId] = useState('');
    const [programId, setProgramId] = useState('');
    const [programDesc, setProgramDesc] = useState('');
    const [programDate, setProgramDate] = useState('');
    const [programDateInicio, setProgramDateInicio] = useState('');
    const [dateEmit, setDateEmit] = useState('')

    const [programName, setProgramName] = useState('');

    const [changeMail, setChangeMail] = useState(true);
    const [changeStudentID, setChangeStudentID] = useState(true);
    const [changeName, setChangeName] = useState(true);
    const [changeProgramName, setChangeProgramName] = useState(true);
    const [changeProgramID, setChangeProgramID] = useState(true);
    const [changeDescription, setChangeDescription] = useState(true);
    const [changeProgramDate, setChangeProgramDate] = useState(true);
    const [changeProgramDateInicio, setChangeProgramDateInicio] = useState(true);
    const [changeDateEmit, setChangeDateEmit] = useState(true);

    const [changeDateEmitStep1, setChangeDateEmitStep1] = useState(true);

    const [changeProgramDateStep1, setChangeProgramDateStep1] = useState(true);
    const [changeProgramDateInicioStep1, setChangeProgramDateInicioStep1] = useState(true);

    // navigate
    const navigate = useNavigate();

    // Var Clss 
    const [invalid, setInvalid] = useState(false)


    const [expediente, setExpediente] = useState('');
    const [newExpe, setNewExpe] = useState('');
    const [etiquetas, setEtiquetas] = useState([]);
    const [newEtiquetas, setNewEtiquetas] = useState([]);
    const [selectedFile, setSelectedFile] = useState();
    const [changeSelectedFile, setChangeSelectedFile] = useState();
    const [selectedFileName, setSelectedFileName] = useState('');
    const [newFileName, setNewFileName] = useState();
    const [showNewFile, setShowNewFile] = useState('')
    const [showFile, setShowFile] = useState(false);
    const [showSpinnerDocs, setShowSpinnerDocs] = useState(true);
    const [showSpinnerData, setShowSpinnerData] = useState(true);
    
    const [showRestar, setShowRestar] = useState(false);
    //Datos de modal nuevos
    const [newCategory, setNewCategory] = useState('');
    const [newType, setNewType] = useState('');
    const [newCuenta, setNewCuenta] = useState('');
    // Datos Post
    const [hash, setHash] = useState('');
    const [dateHash, setDateHash] = useState('');
    const [timeHash, setTimeHash] = useState('');
    const [pubEmit, setPubEmit] = useState('');
    const [creador, setCreador] = useState('');
    const [idDoc, setIdDoc] = useState('');
    const [correoDoc, setCorreoDoc] = useState('');
    const [documentCreate, setDocumentCreate] = useState(false);
    const [pinataUrl, setPinataUrl] = useState('');
    const [metadataUrl, setMetadataUrl] = useState('');
    const [idburn, setIdburn] = useState('');
    const [emit, setEmit] = useState(false);

    const [passwordbutton, setPasswordbutton] = useState(false)
    const [submitbutton, setSubmitbutton] = useState(true)

    // IP
    const [ip, setIP] = useState('');
    const [textDetalle, setTextDetalle] = useState('');


    const [fechaEdit, setFechaEdit] = useState('')

    // QR CODE 
    const [src, setSrc] = useState('');
    // State Visi

    const [visiID, setVisiID] = useState('');

    const [showBoxOne, setShowBoxOne] = useState(true);
    const [showBoxTwo, setShowBoxTwo] = useState(true);

    const [selecCategoria, setSelecCategoria] = useState([])
    const [selecCuenta, setSelecCuenta] = useState([])
    const [tiposModal, setTiposModal] = useState([])
    const [isEditing, setIsEditing] = useState(false);
    const [isWhowing, setIsWhowing] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [modalform, setModalform] = useState(false);


    // Modales
    const [modalEditCategory, setModalEditCategory] = useState(false);
    const [modalEditType, setModalEditType] = useState(false);
    const [modalEditCuenta, setModalEditCuenta] = useState(false);
    const [modalStep2Category, setModalStep2Category] = useState(false);
    const [modalChangeType, SetModalChangeType] = useState(false);
    const [modalChangeFile, SetModalChangeFile] = useState(false);
    const [modalChangeTags, setModalChangeTags] = useState(false);
    const [mailModal, setMailModal] = useState(false);
    const [multipleDelete, setMultipleDeleteModal] = useState(false);
    const [firstAlert, setFirstAlert] = useState(false);

    // Validate Fields
    const [categoryError, setCategoryError] = useState('');
    const [docError, setDocError] = useState('')
    const [tipoError, setTipoError] = useState('');
    const [documentError, setDocumentError] = useState('');
    const [etiquetasError, setEtiquetasError] = useState('');
    const [dateError, setDateError] = useState('');
    const [dateBetError, setDateBetError] = useState('');
    const [dateErrorInicio, setDateErrorInicio] = useState('');
    const [dateErrorFin, setDateErrorFin] = useState('');


    const [dateEmitError, setDateEmitError] = useState('');



    const [descripError, setDecripError] = useState('');
    const [idProgramError, setIdProgramError] = useState('');
    const [programNameError, setProgramNameError] = useState('');
    const [idstudentError, setIdstudentError] = useState('');
    const [mailError, setMailError] = useState('');
    const [nameError, setNameError] = useState('');
    const [truemail, setTruemail] = useState(true);

    // Modals Steps
    const [modalScreen, setModalScreen] = useState(0);

    // Navbar Selects Arr
    const [arrCategory, setArrCategory] = useState([]);
    const [arrTipo, setArrTipo] = useState([]);
    const [arrEstado, setArrEstado] = useState([]);


    const [inactive, setInative] = useState(false);

    // Total Visto
    const [totalVistos, setTotalVistos] = useState(0);
    const [totalDoc, setTotalDoc] = useState(0)
    const [totalPeso, setTotalPeso] = useState(0)

    // Alerts
    const [show, setShow] = useState(false);
    const [notburn, setNotburn] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [showSpinnerBurning, setShowSpinnerBurning] = useState(false);
    const [burning, setBurning] = useState(true);
    const [sort, setSort] = useState('ascend');

        // total Certificados
        const [totalCertificados, setTotalCertificados] = useState(1);
        const pageSize = 20;

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Ver
        </Tooltip>
    );

    const renderBurnTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Burned
        </Tooltip>
    );
    /**checkboxes */
    const [selectedItems, setSelectedItems] = useState([]);

    const columns = [
        {
            title: 'Seleccionar',
            key: 'select',
            width: '3%',
            render: (record) => (
              <Checkbox
                onChange={(e) => handleCheckboxChange(e, record)}
                checked={selectedItems.includes(record._id)}
              />
            ),
          },

        {
            title: (<><p className="text-center" style={{ marginBottom: "0px" }}>Fecha Creación</p></>),
            dataIndex: 'fecha',
            width: '5%',
            key: 'fechaTransform',
            render: fecha => <p style={{ textAlign: "center", marginBottom: "0px" }}>{moment(fecha).format("DD/MM/YYYY h:mm A")}</p>
        },
        
        {
            title: (<><p className="text-center" style={{ marginBottom: "0px" }}>ID Estudiante</p></>),
            dataIndex: 'estudiante',
            key: 'ID Estudiante',
            width: '10%',
            render: text => <p className="mb-0 fontWc" style={{ textAlign: "center" }} >{text.id}</p>
        },
        {
            title: (<><p className="text-center" style={{ marginBottom: "0px" }}>Nombre</p></>),
            dataIndex: 'estudiante',
            key: 'estudiante',
            render: text => <p className="mb-0 fontWc" style={{ textAlign: "center" }} >{text.nombre}</p>
        },
        {
            title: (<><p className="text-center" style={{ marginBottom: "0px" }}>ID Programa</p></>),
            dataIndex: 'programa',
            key: 'ID Programa',
            width: '10%',
            render: text => <p className="mb-0 fontWc" style={{ textAlign: "center" }} >{text.id}</p>
        },
        {
            title: (<><p className="text-start" style={{ marginBottom: "0px" }}>Documento</p></>),
            dataIndex: 'infoDocumento',
            key: 'nombreDoc',
            render: text => <div className="tooltip-container"><p className="text-start tooltip" style={{ color: "#181C32", whiteSpace: "nowrap", width: "330px", overflow: "hidden", marginBottom: "0px" }}>{text.nombre} </p>
                <span className="tooltiptext">{text.nombre}</span>
            </div>
        },
        {
            title: 'Ver',
            //dataIndex: 'infoDocumento',
            with: '3%',
            key: 'documeent',
            render: (record) => {
                return (
                    <>
                        {record.status === "DELETED"
                            ? <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderBurnTooltip}
                            >
                                <img src={BurnIcon} className="docColor" />
                            </OverlayTrigger>
                            :
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                            >
                                <img src={pdf} style={{ cursor: "pointer" }} className="docColor" onClick={() => {
                                    showDoc(record);
                                }} alt="pdf" />
                            </OverlayTrigger>
                        }

                    </>
                );
            },
        },
        {
            title: (<><p className="text-center" style={{ marginBottom: "0px" }}>Fecha Emisión</p></>),
            dataIndex: 'fechaEmision',
            width: '5%',
            key: 'fechaTransform',
            render: fecha => <p style={{ textAlign: "center", marginBottom: "0px" }}>{moment(fecha).format("DD/MM/YYYY")}</p>
        },
        {
            title: (<><p style={{ width: "50px", marginBottom: "0px" }}>Vistas</p></>),
            dataIndex: 'visto',
            key: 'visto',
            width: '5%',
            render: text => <p style={{ textAlign: "center", marginBottom: "0px" }}>{text}</p>
        },
        {
            key: "7",
            title: (<><p className="text-center" style={{ marginBottom: "0px" }}>Editar</p></>),
            width: '5%',
            render: (record) => {
                return (
                    <>
                        {record.status === "DELETED"
                            ?
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderBurnTooltip}
                            >
                                <img src={BurnIcon} className="docColor" />
                            </OverlayTrigger>
                            :
                            <div style={{ textAlign: "center" }}>
                                <EditOutlined style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => {
                                        onEditUser(record);
                                    }}
                                />

                            </div>
                        }

                    </>
                );
            },
        },

    ];



    useEffect(() => {
        //document.title = user?.body?.user.nombre
        getData();
        getDataUsuario();
        getipData(setIP);
        checkDevice();
        getFaviconEl();
        handleFavIcon()
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setPage(1);
            getData();
        }, 300)

        return () => clearTimeout(delayDebounceFn)
    }, [busqueda, datefilter])

    useEffect(() => {
        getData();
    }, [page])


    async function getDataUsuario(){

        let query = `${process.env.REACT_APP_URLSERVER}/v2/usuarios/data-home`;

        try {
            const data = await axios.get(query,{
                responseType: 'json',
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": user?.body?.jwt
                }
            });
            setDataUsuario(data.data.body);
            setShowSpinnerData(false);
        } catch (error) {
            console.log(error);
        }

    }

    const handleFavIcon = () => {
        const favicon = getFaviconEl(); // Accessing favicon element
        favicon.href = `${process.env.REACT_APP_URLSERVER}/imagenes/favicons/${user?.body?.user.favicon}`;
    };


    function getFaviconEl() {
        return document.getElementById("favicon");
    }


    const validateEmail = (e) => {
        var email = e.target.value
        setStudentMail(email)
        if (validator.isEmail(email)) {
            setEmailError('')
            setTruemail(true)
        }
        else if (email === '') {
            setEmailError('');
        }
        else {
            setEmailError('Coloca un Email valido');
            setTruemail(false)
        }
    }

    /** BEGIN Collect items ids logic */
    const handleCheckboxChange = (e, record) => {
        const { checked } = e.target;
    
        // Update the selected items based on checkbox state
        setSelectedItems((prevSelectedItems) => {
          if (checked) {
            return [...prevSelectedItems, record._id];
          } else {
            return prevSelectedItems.filter((itemId) => itemId !== record._id);
          }
        });
      };

      const handleCollectItems = async () => {
        const token = await localStorage.getItem('token');
        if (passwordConfirm === user?.body?.user.correo){

            let res = await axios.post(`${process.env.REACT_APP_URLSERVER}/v2/documentaciones/masivo`, {ids:selectedItems}, {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": token
                }
            })
            await getData();
            setMultipleDeleteModal(false);
            setSelectedItems([])
            swal({
                title: "Borrado por lotes iniciado! visita la seccion de actividades para ver el estado de los procesos!",
                icon: "success",
                button: "Aceptar"
            });
        }else{
            await getData();
            swal({
                title: "Correo de cliente Incorrecto!",
                icon: "warning",
                button: "Aceptar"
            });
        }
      };

      const showBurnMultipleItems = async () => {
        setMultipleDeleteModal(true);
        setFirstAlert(true);
      }

      const hideBurnMultipleItems = async () => {
        setMultipleDeleteModal(false);
      }


    /** END Collect items ids logic */


    const searchTags = async (etiquetas) => {
        setShowRestar(true)
        let currenTag = etiquetas
        await axios(`${process.env.REACT_APP_URLSERVER}/v2/documentaciones/tags/${currenTag}`, {
            responseType: 'json',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": user?.body?.jwt
            }

        }).then((response) => {
            let data = response.data.docs
            data.map((item) => {
                item.fechaTransform = moment(moment(item.fechaEmision)).format('DD/MM/YYYY')
                item.nombreDoc = item.infoDocumento.nombre
                item.studenName = item.estudiante.nombre
                item.studenId = item.estudiante.id
                item.programId = item.programa.id
            })
            setFilterTable(data)
            //setShowFilter(true)
        })
    }

    const restarInfo = () => {
        setShowRestar(false)
        setFilterTable(dataSource)
    }

    const showSecondAlert = () => {
        setFirstAlert(false);
        setShowSpinnerBurning(true)
        setTimeout(() => {
            setShowSpinnerBurning(false)
            setShowAlert(true)
        }, 1000);
        /*setShow(false)
        setShowAlert(true)*/
    }

    const confirmBurn = (e) => {
        let currenValue = e.target.value
        setPasswordConfirm(currenValue)
    }

    const getData = async () => {

        let query = `${process.env.REACT_APP_URLSERVER}/v2/documentaciones?page=${page}&`;

        if(busqueda) query += `busqueda=${busqueda}&`;
        if(datefilter) query += `fechaEmision=${datefilter}`

        await axios(query, {
            responseType: 'json',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": user?.body?.jwt
            }
        })
        .then((res) => { 
            let data = res.data.body
            console.log("DATA", res)
            console.log('categorias', data.categorias);
            setTotalCertificados(data.totalItems)
            setTotalDoc(data.totalItems)
            setDataSource(data.docs);
            setShowSpinnerDocs(false);
            setSelecCategoria(data.categorias)
            setTiposModal(data.tipos)

        }).catch((err) => {
            if(err.response.status == 401){
                localStorage.removeItem("user-info")
                localStorage.removeItem("rol-user")
                localStorage.removeItem("token")
                navigate("/login");
            }
        })
    }


    const user = JSON.parse(localStorage.getItem('user-info'));

    const checkDevice = () => {
        if (isMobile) {
            let tlfText = `Acceso mediante dispositivo móvil, IP`
            setTextDetalle(tlfText)
        } else {
            let tlfText = `Acceso mediante ordenador personal, IP`
            setTextDetalle(tlfText)
        }
    }

    const addChip = value => {
        const chips = etiquetas.slice();
        chips.push(value);
        setEtiquetas(chips)
    };
    const addChipEdit = value => {
        const chips = newEtiquetas.slice();
        chips.push(value);
        setNewEtiquetas(chips)
    };
    const removeChip = index => {
        const chips = etiquetas.slice();
        chips.splice(index, 1);
        setEtiquetas(chips)
    };

    const onFileChange = async (event) => {
        setShowFile(true);
        setSelectedFile(event.target.files[0])

        let fileName = event.target.files[0].name
        let finalString = ''
        //let finalString = fileName.substring(0, 10) + '...' + fileName.slice(-4)
        if (fileName.length < 10) {
            finalString = fileName
        } else {
            finalString = fileName.slice(0, 10) + '...' + fileName.slice(15, 18) + '.pdf'
        }

        await setSelectedFileName(finalString)
    };

    const onEditUser = (record) => {
        let offset = moment(record.fechaEmision).zone(record.fechaEmision)

        let fechaEmit = offset.format("DD/MM/YYYY")
        setFechaEdit(fechaEmit)
        const idDooc = record._id;
        setIdburn(record._id)
        QRCode.toDataURL(`${process.env.REACT_APP_QRURL}/landing/${record._id}`).then((data) => {
            setSrc(data);
        })
        setIsEditing(true);
        setEditingUser({ ...record });
    };

    const showDoc = (record) => {
        QRCode.toDataURL(`${process.env.REACT_APP_QRURL}/landing/${record._id}`).then((data) => {
            setSrc(data);
        })
        const idDoc = record._id
        setIdDoc(idDoc)
        setIsWhowing(true)
        setEditingUser({ ...record });
    }

    const resetEditing = () => {
        setIsEditing(false);
        setEditingUser(null);
    };


    const handleClosedModal = () => {
        setNewEtiquetas([])
        resetEditing()
    }

    const addNewcategory = async () => {
        setSelecCategoria([...selecCategoria, newCategory])
        setModalEditCategory(false)
        setCategory(newCategory);
        setNewCategory("");
    }

    const addNewType = async () => {
        setTiposModal([...tiposModal, newType])
        setModalEditType(false)
        setType(newType);
        setNewType("");
    }

    const addNewCuenta = async () => {
        const newCuentaArr = {
            nombre: newCuenta
        }
        setSelecCuenta([...selecCuenta, newCuentaArr])
        setModalEditCuenta(false)
    }

    const changeCategoryStep2 = (e) => {
        setCategoryStep2(e.target.value)
    }

    const saveChange = () => {
        setCategory(categoryStep2)
        setModalStep2Category(false)
    }

    const changeType = async (e) => {
        setTypeStep2(e.target.value)
    }

    const saveChangeType = () => {
        setType(typeStep2);
        SetModalChangeType(false)
    }

    const changeDoc = async (e) => {
        setChangeSelectedFile(e.target.files[0])
        await setNewFileName(e.target.files[0].name)
        setShowNewFile(true);
    }

    const saveChangeDoc = async () => {
        setSelectedFile(changeSelectedFile);
        await setSelectedFileName(newFileName);
        setShowNewFile(false)
        SetModalChangeFile(false)
    }

    const backModal = () => {
        setModalScreen(0);
    }

    const handleModalClosed = () => {
        setModalform(false);
        setModalScreen(0);
        setShowBoxOne(true);
        setPubEmit('');
        setProgramDateInicio('')
        setEmit(false);
        setEmailError('');
        setDateEmit('')
        setTruemail(false);
        setShowBoxTwo(true);
        setCategory('');
        setType('');
        setVisibility('');
        setPassword('');
        setSelectedFileName('');
        setExpediente('');
        setAccount('');
        setDocName('');
        setEtiquetas([]);
        setDateError('');
        setDateBetError('')
        setDecripError('');
        setDocumentCreate(false)
        setIdProgramError('');
        setProgramDesc('');
        setProgramNameError('');
        setDateEmitError('')
        setIdstudentError('');
        setMailError('');
        setNameError('');
        setCategoryError('');
        setDocError('');
        setTipoError('');
        setDocumentError('');
        setEtiquetasError('');
        setStudentName('');
        setStudentMail('');
        setStudentId('');
        setProgramId('');
        setProgramDate('');
        setProgramName('');
    }
    const cancelBurn = () => {
        setShowAlert(false)
        setPasswordConfirm('');
        setMultipleDeleteModal(false);
    }
    const burn = async () => {
        if (passwordConfirm === user?.body?.user.correo) {
            setBurning(false)
            setShowSpinnerBurning(true)
            try {
                const resp = await axios.delete(`${process.env.REACT_APP_URLSERVER}/v2/documentaciones/individual/${idburn}`, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": user?.body?.jwt
                    }
                })
                swal({
                    title: "Burn realizado con éxito!",
                    icon: "success",
                    button: "Aceptar"
                });
                setShowAlert(false)
                handleClosedModal()
                setBurning(true)
                setDocumentCreate(false)
                setPasswordConfirm('')
                setShowSpinnerBurning(false)
                getData()
            } catch (error) {
                swal({
                    title: "ha habido un error , por favor intente nuevamente",
                    icon: "warning",
                    button: "Aceptar"
                });
                console.log(error.response);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    swal({
                        title: "ha habido un error , por favor intente nuevamente",
                        icon: "warning",
                        button: "Aceptar"
                    });
                    setShowSpinnerBurning(false)
                    setBurning(true)
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the 
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    swal({
                        title: "ha habido un error , por favor intente nuevamente",
                        icon: "warning",
                        button: "Aceptar"
                    });
                    setShowSpinnerBurning(false)
                    setBurning(true)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    swal({
                        title: "ha habido un error , por favor intente nuevamente",
                        icon: "warning",
                        button: "Aceptar"
                    });
                    setShowSpinnerBurning(false)
                    setBurning(true)
                }
            }
        } else {
            swal({
                title: "Correo de cliente Incorrecto!",
                icon: "warning",
                button: "Aceptar"
            });
        }

    }

    const onAddFile = async () => {
        setIdDoc('')
        setShowBoxOne(false);
        setShowBoxTwo(false);
        if (dateEmit === '' || dateEmit === 'Invalid date') {
            setEmit(true)
        } else {
            setEmit(false)
        }
        const formData = new FormData();
        // Update the formData object
        formData.append(
            "file",
            selectedFile,
            selectedFile.name
        );

        formData.append(
            "descripcion",
            programDesc
        );

        formData.append(
            "fechaEmision",
            dateEmit
        );

        formData.append(
            "estudiante",
            JSON.stringify({
                id: studentId,
                nombre: studentName,
                correo: studentMail
            })
        );

        formData.append(
            "programa",
            JSON.stringify({
                id: programId,
                nombre: programName,
                descripcion: programDesc,
                fechaFin: programDate,
                fechaInicio: programDateInicio
            })
        );

        formData.append(
            "tipo",
            type
        );

        formData.append(
            "categoria",
            category
        );

        formData.append(
            "etiquetas",
            JSON.stringify(
                etiquetas
            )
        );
        formData.append(
            "infoActividad",
            JSON.stringify({
                detalles: `${textDetalle} ${ip}`,
                tipo: type,
            })
        );
        formData.append(
            "correoAutomatico",
            correoAutomatico
        )

        try {
            let res = await axios.post(`${process.env.REACT_APP_URLSERVER}/v2/documentaciones`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": user?.body?.jwt
                }
            })

            if (res.status === 200) {
                swal({
                    title: res.data.msg,
                    icon: "success",
                    button: "Aceptar"
                });
                QRCode.toDataURL(`${process.env.REACT_APP_QRURL}/landing/${res.data.body._id}`).then((data) => {
                    setSrc(data);
                })
                const hash = res.data.body.infoDocumento.hash
                const dateHash = res.data.body.fecha
                const dateHashFormate = moment(moment(dateHash)).format('DD/MM/YYYY')
                const timeHash = moment(moment(dateHash)).format("hh:mm:ss a")
                const creador = res.data.body.creador.nombre
                const idoc = res.data.body._id
                const pinataUrl = res.data.body.infoDocumento.url
                const metaurl = res.data.body.infoDocumento.urlMetadata
                let offset = moment(res.data.body.fechaEmision).zone(res.data.body.fechaEmision)
                offset.format("DD-MM-YYYY HH:mm:ss ZZ")
                let dateEmit = offset.format("DD/MM/YYYY")
                let contrato = `${process.env.REACT_APP_URL_POLYGON}address/${res.data.body.infoDocumento.direccionContrato}`;
                
                setContrato(contrato)
                setPolygon(polygon)
                setIdDoc(idoc)
                setCreador(creador)
                setPinataUrl(pinataUrl)
                setMetadataUrl(metaurl)
                setHash(hash);
                setDateHash(dateHashFormate);
                setTimeHash(timeHash)
                setPubEmit(dateEmit)
                getData();
                //setModalScreen(2);
                setDocumentCreate(true);
                setShowBoxOne(false);
                setShowBoxTwo(true);
                setDateError();
                setDateBetError('')
                setIdProgramError();
                setProgramNameError();
                setIdstudentError();
                setNameError();


                setCategoryError();
                setDocError();
                setTipoError();
                setDocumentError();
                setEtiquetasError()
            } else if (res.data.code === 400) {
                console.log("Error", res.data)
                swal({
                    title: res.data.msg,
                    icon: "warning",
                    button: "Aceptar"
                });
                setShowBoxOne(true);
                setShowBoxTwo(true);
            }
        } catch (e) {
            console.log("Error", e)
            swal({
                title: e,
                icon: "warning",
                button: "Aceptar"
            });
            swal({
                title: e.response.data.body,
                icon: "warning",
                button: "Aceptar"
            });
            setShowBoxOne(true);
            setShowBoxTwo(true);
        }
    };

    const postMail = async () => {
        setSpinnermail(true)
        let dataMail = {
            idDocumento: idDoc,
            correo: correoDoc
        }
        try {
            let res = await axios.post(`${process.env.REACT_APP_URLSERVER}/v2/share/qr`, dataMail, {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": user?.body?.jwt
                }
            })
            swal({
                title: "Correo enviado!",
                icon: "success",
                button: "Aceptar"
            });
            setMailModal(false)
            setSpinnermail(false)
        } catch (e) {
            setSpinnermail(false)
        }
    }

    const handleStep1 = () => {
        if (validate()) {
            setModalScreen(1)
            setNameError('')
            setIdstudentError('');
            setProgramNameError('');
            setIdProgramError('');
            setCategoryError('');
            setTipoError('');
            setDocumentError('');
            setEtiquetasError('')
            setDateErrorInicio('');
            setDateErrorFin('');
            setDateEmitError('')
            setChangeDateEmitStep1(true)
            setChangeProgramDateStep1(true)
            setChangeProgramDateInicioStep1(true)
            /*if(dateEmit === '' || dateEmit === 'Invalid date') {
                setEmit(true)
            } else {
                setEmit(false)
            }*/
        }
    }



    const handleDatePost = (e) => {
        const currValue = moment(moment(e.target.value)).format('YYYY-MM-DD');

        setProgramDate(currValue);
    }

    const handleDatePostInicio = (e) => {
        const currValue = moment(moment(e.target.value)).format('YYYY-MM-DD');

        setProgramDateInicio(currValue);
    }

    const handleDateEmit = (e) => {
        const currValue = moment(moment(e.target.value)).format('YYYY-MM-DD');

        /*if (currValue === 'Invalid date') {
            setEmit(false)
        } else {
            setEmit(true)
        }*/
        setDateEmit(currValue);
    }

    const validate = () => {


        let nombreError = "";
        let categoryError = "";
        let tipoError = "";
        let documentError = "";
        let etiquetasError = "";
        let nameError = "";
        let idstudentError = "";
        let programNameError = "";
        let idProgramError = "";
        //let descripError = "";
        let dateProgramErrorInicio = "";
        let dateProgramErrorFin = "";
        let dateProgramErrorBet = "";

        let dateEmitError = ""



        if (studentName == "") {
            nameError = "required"
        } else {
            nameError = ""
        }
        if (studentId == "") {
            idstudentError = "required"
        } else {
            idstudentError = ""
        }

        if (programName == "") {
            programNameError = "required"
        } else {
            programNameError = ""
        }

        if (programId == "") {
            idProgramError = "required"
        } else {
            idProgramError = ""
        }

        if (programDateInicio == "" || programDateInicio == "Invalid date") {
            dateProgramErrorInicio = "required"
        } else {
            dateProgramErrorInicio = ""
        }
        if (programDate == "" || programDate == "Invalid date") {
            dateProgramErrorFin = "required"
        } else {
            dateProgramErrorFin = ""
        }

        /*if (dateEmit == "" || dateEmit == "Invalid date") {
            dateEmitError = "required"
        } else {
            dateEmitError = ""
        }*/


        if (category === "" || category === "Selecciona una categoría") {
            categoryError = "required"
        } else {
            categoryError = ""
        }
        if (type === "" || type === "Selecciona un tipo") {
            tipoError = "required"
        } else {
            tipoError = ""
        }

        if (!selectedFile) {
            documentError = "required";
        } else {
            documentError = ""
        }

        if (etiquetas.length === 0) {
            etiquetasError = "required";
        } else {
            etiquetasError = ""
        }

        if (programDate <= programDateInicio) {
            /*swal({
                title: "La fecha fin de Programa no puede ser menor que la de inicio.",
                icon: "warning",
                button: "Aceptar"
            });*/
            //dateProgramErrorBet = "Error en fecha"
        } else if (programDateInicio >= programDate) {
            /*swal({
                title: "La fecha fin de Programa no puede ser menor que la de inicio.",
                icon: "warning",
                button: "Aceptar"
            });*/
            //dateProgramErrorBet = "Error en fecha"
        } else {
            dateProgramErrorBet = ""
        }


        if (dateEmitError || dateProgramErrorInicio || dateProgramErrorFin || idProgramError || programNameError || idstudentError || nameError || nombreError || categoryError || tipoError || documentError || etiquetasError) {
            let alertStudent = studentName === "" ? "- Nombre del Estudiante <br/>" : "";
            let alertStudentId = studentId === "" ? "- ID del Estudiante <br/>" : "";
            let alertProgramName = programName === "" ? "- Nombre del Programa <br/>" : "";
            let alertProgramId = programId === "" ? "- ID del Programa <br/>" : "";
            let alertCategory = category === "" || category === "Selecciona una categoría" ? "- Categoría <br/>" : "";
            let alertType = type === "" || type === "Selecciona un tipo" ? "- Tipo <br/>" : "";
            let alertEtiquetas = etiquetas.length === 0 ? "- Etiquetas <br/>" : "";
            let alertFile = !selectedFile ? "- Documento" : "";
            //let alertFechaEmit = dateEmit === "" || dateEmit === "Invalid date" ? "- Fecha de Emisión <br/>" : "";
            let alertFechaProgram = programDate === "" || programDate === "Invalid date" ? "- Fecha de Programa Final <br/>" : "";
            let alertFechaInicio = programDateInicio === "" || programDateInicio === "Invalid date" ? "- Fecha de Programa Inicio <br/>" : "";

            Swal.fire({
                title: '<strong>Campos requeridos.</strong>',
                icon: 'info',
                iconColor: '#FAD02C',
                background: 'rgba(255,255,255,0.9)',
                color: '#181C32',
                textAlign: 'start',
                width: '390px',
                padding: '0',
                html:
                    `<div class="text-start"><b>${alertStudent}</b>
                     <b>${alertStudentId}</b>
                     <b>${alertProgramName}</b> 
                     <b>${alertProgramId}</b>
                     <b>${alertFechaInicio}</b>
                     <b>${alertFechaProgram}</b>
                     <b>${alertCategory}</b>
                     <b>${alertType}</b>
                     <b>${alertEtiquetas}</b>
                     <b>${alertFile}</b>
                     </div>`,
                showCloseButton: true,
                showCancelButton: false,
                focusConfirm: false,
                customClass: {
                    confirmButton: 'custom-button',
                },
                confirmButtonText:
                    'Aceptar',
            });
            setNameError(nameError)
            setIdstudentError(idstudentError);
            setProgramNameError(programNameError);
            setIdProgramError(idProgramError);
            setCategoryError(categoryError);
            setTipoError(tipoError);
            setDocumentError(documentError);
            setEtiquetasError(etiquetasError)
            setDateErrorInicio(dateProgramErrorInicio);
            setDateErrorFin(dateProgramErrorFin);

            return false;
        }
        return true;
    }


    const handleCategory = (e) => {
        const currValue = e.target.value;
        setCategoryFilter(currValue);
    }

    const handleType = (e) => {
        const currValue = e.target.value;
        setTypefilter(currValue);
    }

    const handleFilterDate = (e) => {
        const currValueTransform = moment(moment(e.target.value)).format('MM-DD-YYYY');
        const dateOriginal = e.target.value
        setDateoriginal(dateOriginal)
        if(e.target.value === ''){
            setDatefilter(null);
        }else{
            setDatefilter(currValueTransform);
        }
        getData();
    }

    const closeModalForm = () => {
        setModalform(false)
        setTruemail(false);
        setShowBoxTwo(true);
        setCategory('');
        setType('');
        setVisibility('');
        setPassword('');
        setSelectedFileName('');
        setExpediente('');
        setAccount('');
        setDocName('');
        setEtiquetas([]);
        setDateError('');
        setDateBetError('')
        setDecripError('');
        setDocumentCreate(false)
        setIdProgramError('');
        setProgramDesc('');
        setProgramNameError('');
        setDateEmit('');
        setProgramDateInicio('')

        setChangeDateEmitStep1(true)
        setChangeProgramDateStep1(true)
        setChangeProgramDateInicioStep1(true)


        setDateErrorInicio('');
        setDateErrorFin('');




        setDateEmitError('')
        setIdstudentError('');
        setMailError('');
        setNameError('');
        setCategoryError('');
        setDocError('');
        setTipoError('');
        setDocumentError('');
        setEtiquetasError('');
        setStudentName('');
        setStudentMail('');
        setStudentId('');
        setProgramId('');
        setProgramDate('');
        setProgramName('');
    }

    const handleMail = (e) => {
        setCorreoAutomatico(!correoAutomatico)
    }

    const handleRemoveChip = (chip) => {
        setEtiquetas(
            etiquetas.filter(c => c != chip) //filter will produce a copy of the array
        );
    }

    const handleRemoveChipEdit = (chip) => {
        setNewEtiquetas(
            newEtiquetas.filter(c => c != chip)
        )
    }


    const validateBurn = () => {
        /*if (user?.body?.user.rol === "CLIENT_ROL") {
            setShow(true)
        } else {*/
        setShow(true)
        //}
    }


    return (
        <>
            <SideMenu onCollapse={(inactive) => {
                setInative(inactive);
            }} />
            <div className={`containerTwo ${inactive ? "inactive" : ""}`}>
                <Row>
                    <Col lg="12" className="d-flex justify-content-center align-items-center">
                    <img className="logo-css  d-sm-none" src={`${user?.body?.user.logo}`} alt="certiblock" />
                    </Col>
                </Row>

                <NavbarHeader searchName={setBusqueda} inputPlaceHolder={"Buscar estudiante"} titlePage={"Documentos"} setValue={setValue} setFilterTable={setFilterTable} dataFilter={dataSource} inputValue={value} testing={totalCertificados} />

                <div className="containerTable">

                    <div className="table-responsive">
                        <Row style={{alignContent: 'end'}}>

{/* 
                            <Col xs="12" lg="3" className="flexCol">
                                <div className="SelectBusqueda">
                                    <p className="title-filter">Categoría</p>
                                    <Form.Select className="select-css" onChange={handleCategory} aria-label="Default select example">
                                        <option key="0" value="" >Todas las categorías</option>
                                        {
                                            arrCategory.map((item, index) => (
                                                <option key={index} value={item.nombre} >{item.nombre}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </div>
                            </Col>

                            <Col xs="6" lg="3" className="flexCol">
                                <div className="SelectBusqueda">
                                    <p className="title-filter">Tipo</p>
                                    <Form.Select className="select-css" onChange={handleType} aria-label="Default select example">
                                        <option key="0" value="" >Todos los tipos</option>
                                        {
                                            arrTipo.map((item, index) => (
                                                <option key={index} value={item.nombre} >{item.nombre}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </div>
                            </Col> */}

                            <Col xs="6" lg="3" className="flexCol" id="filtro-fecha-docs">
                                <div className="SelectBusqueda">
                                    <p className="title-filter">Fecha de Emisión</p>

                                    <Form.Group controlId="dob">
                                        <Form.Control onChange={handleFilterDate} className="select-css" type="date" value={dateoriginal} name="dob" placeholder="Date of Birth"/>
                                    </Form.Group>

                                </div>
                            </Col>
                        </Row>
                        {
                            showSpinnerData === true && (
                                <>
                                    <div style={{ justifyContent: "center", height: "15vh" }} className="d-flex align-items-center">
                                        <strong>Cargando Data...</strong>
                                        <div className="spinner-borderData ml-auto" role="status" aria-hidden="true"></div>
                                    </div>
                                </>
                            )
                        }
                        {user?.body?.user.rol === "CLIENT_ROL" && showSpinnerData === false && (
                            <>
                                <Row style={{ textAlign: "center" }}>
                                    <Col xs="6" lg="1" className="d-none d-sm-block colRowClient mb-3">

                                    </Col>
                                    <Col xs="6" lg="2" className="colRowClient mb-3">
                                        <Card title="Documentos" className="card-docu boxCardDocuments">
                                            <Card.Title>Documentos</Card.Title>
                                            <img className="imgDocu" src={plusdocuments} />
                                            <p className="textContent">{dataUsuario.documentos}</p>
                                        </Card>
                                    </Col>
                                    <Col xs="6" lg="2" className="colRowClient mb-3">
                                        <Card title="Visualizaciones" className="card-almacenamiento boxCardVisual">
                                            <Card.Title>Visualizaciones</Card.Title>
                                            <img className="imgDocu" src={eyeplus} />
                                            <p className="textContent">{dataUsuario.visualizaciones}</p>
                                        </Card>
                                    </Col>
                                    <Col xs="6" lg="2" className="colRowClient mb-3">
                                        <Card title="Almacenamiento" className="card-Visualizaciones boxCardAlmace">
                                            <Card.Title>Almacenamiento</Card.Title>
                                            <img className="imgDocu" src={storage} />
                                            <p className="textContent">{dataUsuario.almacenamiento} MB</p>
                                        </Card>
                                    </Col>
                                    <Col xs="6" lg="2" className="colRowClient mb-3">
                                        <Card title="Plan Activo" className="card-almacenamiento boxCardPlan">
                                            <Card.Title>Plan Activo</Card.Title>
                                            <img className="imgDocu" src={plans} />
                                            <p className="textContent">{dataUsuario.plan.nombre}</p>
                                        </Card>
                                    </Col>
                                    <Col xs="6" lg="2" className="colRowClient mb-3">
                                        <Card title="Descuento" className="card-docu boxCardDescuento">
                                            <Card.Title>Descuento</Card.Title>
                                            <img className="imgDocu" src={descuento} />
                                            <p className="textContent">{`${dataUsuario.plan.descuento}%`}</p>
                                        </Card>
                                    </Col>
                                    <Col xs="6" lg="1" className="d-none d-sm-block colRowClient mb-3">

                                    </Col>

                                </Row>
                            </>
                        )}
                        {
                            showSpinnerDocs === true && (
                                <>
                                    <div style={{ justifyContent: "center", height: "50vh" }} className="d-flex align-items-center">
                                        <strong>Cargando Data...</strong>
                                        <div className="spinner-borderData ml-auto" role="status" aria-hidden="true"></div>
                                    </div>
                                </>
                            )
                        }
                        {
                            showSpinnerDocs === false && (
                                <>
                                    <Table showSorterTooltip={{ title: 'Clic para ordenar' }} columns={columns} scroll={{ x: 'max-content' }}  dataSource={dataSource} 
                                     pagination={{
                                        current: page,
                                        total: totalCertificados,
                                        pageSize: pageSize,
                                        onChange: (page, pageSize) => {
                                            setPage(page);
                                        },
                                    }}
                                    />
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        { selectedItems.length >= 1 &&(

                                        <Button className="batchDeleteButton" style={{ marginLeft: "17px" }} onClick={showBurnMultipleItems}>Burn documentos seleccionados</Button>
                                        )}

                                        <Button className="uploadButton" style={{ marginLeft: "17px" }} onClick={() => setModalform(true)}>Cargar nuevo documento <img src={circleupload} alt="circleupload" /></Button>
                                    </div>

                                    
                                </>
                            )
                        }

                    </div>
                </div>


                <Modal show={modalform} size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    {modalScreen === 0 && (
                        <>
                            <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter" style={{ width: "100%" }}>
                                    <img style={{ margin: "auto auto 0px", width: "150px", display: "flex" }} src={`${process.env.REACT_APP_URLSERVER}/imagenes/logos/${user?.body?.user?.logo}`} alt="certiblock" />
                                </Modal.Title>
                                <Button className="out-css-header dOndevice" onClick={handleModalClosed}><img className="closed-css" src={closedicon} alt="closedicon" />
                                </Button>

                            </Modal.Header>

                            <Modal.Body>
                                <Form>

                                    <p className="modal-title" style={{ paddingBottom: "30px" }} >NUEVO DOCUMENTO</p>
                                    <Row className="row-select" style={{ padding: "0px" }}>
                                        <Col lg="5">
                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                <Form.Label className="title-filter-modal">Nombre del Estudiante</Form.Label>
                                                <Form.Control

                                                    className={`input-Form newCategory-css ${nameError === "required" ? "select-css-modal-alert" : "select-css-modal"}`}

                                                    type="text"
                                                    placeholder=""
                                                    value={studentName}
                                                    onChange={(e) => setStudentName(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg="3">
                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                <Form.Label className="title-filter-modal">ID Estudiante</Form.Label>
                                                <Form.Control
                                                    className={`input-Form newCategory-css ${idstudentError === "required" ? "select-css-modal-alert" : "select-css-modal"}`}
                                                    type="text" placeholder="" value={studentId} onChange={(e) => setStudentId(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg="4">
                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                <Form.Label className="title-filter-modal">Nombre del Programa</Form.Label>
                                                <Form.Control
                                                    className={`input-Form newCategory-css ${programNameError === "required" ? "select-css-modal-alert" : "select-css-modal"}`}
                                                    type="text" placeholder="" value={programName} onChange={(e) => setProgramName(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row className="row-select">
                                        <Col lg="4">
                                            <div>
                                                <Form.Group controlId="dob">
                                                    <Form.Label className="title-filter-modal">Fecha de Emisión</Form.Label>
                                                    <Form.Control
                                                        onChange={handleDateEmit}
                                                        value={dateEmit}
                                                        className="sselect-css"
                                                        type="date"
                                                        name="dob"
                                                        placeholder={dateEmit}
                                                    //disabled={changeDateEmitStep1 === true ? true : false}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col lg="1"></Col>
                                        <Col lg="6" className="flexiEmit" style={{ border: "1px dashed", marginTop: "20px" }}>
                                            <div>
                                                <p className="textEmit">
                                                    Si la fecha de emisión esta vacia será reemplazada por la fecha Actual.
                                                </p>
                                            </div>
                                        </Col>
                                        <Col lg="1"></Col>
                                    </Row>
                                    <hr></hr>
                                    <Row className="row-select">
                                        <Col lg="4">
                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                <Form.Label className="title-filter-modal">Descripción del Programa</Form.Label>
                                                <Form.Control className="input-Form newCategory-css select-css-modal" type="text" placeholder="" value={programDesc} onChange={(e) => setProgramDesc(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg="4">
                                            <div >
                                                <p className="title-filter-modal">Categoría</p>
                                                <Form.Select onChange={(e) => {setCategory(e.target.value)}} value={category}
                                                    className={` ${categoryError === "required" ? "select-css-modal-alert" : "select-css-modal"}`}
                                                    aria-label="Default select example">
                                                        <option key={-1} value={""} >Selecciona uno</option>
                                                    {
                                                        selecCategoria.map((valor, index) => (
                                                            <option key={index} value={valor} >{valor}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                <Button className="redColorModal1" onClick={() => setModalEditCategory(true)}>Añadir nueva categoría</Button>
                                            </div>
                                        </Col>
                                        <Modal show={modalEditCategory} aria-labelledby="contained-modal-title-vcenter"
                                            centered>
                                            <Modal.Header>
                                                <p className="modal-title">Agregar nueva Categoría</p>
                                                <Button className="out-css-header" onClick={() => setModalEditCategory(false)}><img className="closed-css-modal" src={closedicon} alt="closedicon" />
                                                </Button>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Form.Group className="mb-3" controlId="formBasicName">
                                                    <Form.Label className="title-filter-modal">Nombre de la categoría</Form.Label>
                                                    <Form.Control className="input-Form select-css-modal" type="text" placeholder="" value={newCategory} onChange={(e) => setNewCategory(e.target.value)} />
                                                </Form.Group>
                                                {newCategory === "" && (
                                                    <>
                                                        <span className="text-validate">No se puede dejar el campo vacio.</span>
                                                    </>
                                                )}
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button className="out-csz" onClick={() => setModalEditCategory(false)}>
                                                    Cancelar
                                                </Button>
                                                <Button className="next-css" onClick={addNewcategory} disabled={!newCategory}>
                                                    Agregar Categoría
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        <Col lg="4">
                                            <div>
                                                <p className="title-filter-modal">Tipo</p>
                                                <Form.Select onChange={(e) => setType(e.target.value)} value={type} className={` ${tipoError === "required" ? "select-css-modal-alert" : "select-css-modal"}`}

                                                    aria-label="Default select example">
                                                        <option key={-1} value={""} >Selecciona uno</option>

                                                    {
                                                        tiposModal.map((valor, index) => (
                                                            <option key={index} value={valor} >{valor}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                <Button className="redColorModal1" onClick={() => setModalEditType(true)}>Añadir nuevo tipo</Button>
                                            </div>
                                        </Col>
                                        <Modal show={modalEditType} aria-labelledby="contained-modal-title-vcenter"
                                            centered>
                                            <Modal.Header>
                                                <p className="modal-title">Agregar nuevo Tipo</p>
                                                <Button className="out-css-header" onClick={() => setModalEditType(false)}><img className="closed-css-modal" src={closedicon} alt="closedicon" />
                                                </Button>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Form.Group className="mb-3" controlId="formBasicName">
                                                    <Form.Label className="title-filter-modal">Nombre del Tipo</Form.Label>
                                                    <Form.Control className="input-Form select-css-modal" type="text" placeholder="" value={newType} onChange={(e) => setNewType(e.target.value)} />
                                                </Form.Group>
                                                {newType === "" && (
                                                    <>
                                                        <span className="text-validate">No se puede dejar el campo vacio.</span>
                                                    </>
                                                )}
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button className="out-csz" onClick={() => setModalEditType(false)}>
                                                    Cancelar
                                                </Button>
                                                <Button className="next-css" onClick={addNewType} disabled={!newType}>
                                                    Agregar Nuevo Tipo
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </Row>
                                    <hr></hr>
                                    <Row className="row-select">

                                        <Col lg="4">
                                            <div>
                                                <Form.Group controlId="dob">
                                                    <Form.Label className="title-filter-modal">Fecha Inicio de Programa</Form.Label>
                                                    <Form.Control
                                                        onChange={handleDatePostInicio}
                                                        value={programDateInicio}
                                                        className={`${dateErrorInicio === "required" ? "select-css-modal-alert" : "sselect-css"}`}
                                                        type="date"
                                                        name="dob"
                                                        placeholder={programDateInicio}
                                                    //disabled={changeProgramDateInicioStep1 === true ? true : false}
                                                    />
                                                </Form.Group>


                                            </div>
                                        </Col>

                                        <Col lg="4">
                                            <div>
                                                <Form.Group controlId="dob">
                                                    <Form.Label className="title-filter-modal">Fecha fin de Programa</Form.Label>
                                                    <Form.Control
                                                        onChange={handleDatePost}
                                                        value={programDate}
                                                        className={`${dateErrorFin === "required" ? "select-css-modal-alert" : "sselect-css"}`}
                                                        type="date"
                                                        name="dob"
                                                        placeholder={programDate}
                                                    //disabled={changeProgramDateStep1 === true ? true : false}
                                                    />
                                                </Form.Group>


                                            </div>
                                        </Col>

                                        <Col lg="3">
                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                <Form.Label className="title-filter-modal">ID Programa</Form.Label>
                                                <Form.Control
                                                    className={`input-Form newCategory-css ${idProgramError === "required" ? "select-css-modal-alert" : "select-css-modal"}`}
                                                    type="text" placeholder="" value={programId} onChange={(e) => setProgramId(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {showFile === true && (
                                        <>
                                            <Row className={`${selectedFileName === "" ? "d-none" : ""}`}>
                                                <Col lg="12" className="dis-row">
                                                    <label><span className="fileName-css">Nombre del Documento:</span> {selectedFileName}</label>
                                                </Col>
                                            </Row>
                                        </>
                                    )}

                                    <Row>
                                        <Col lg="4">
                                            <Form.Group className="mb-3" controlId="formBasicEtiquetas">
                                                <Form.Label className="title-filter-modal">Etiquetas</Form.Label>
                                                <div
                                                    className={`${etiquetasError === "required" ? "select-css-modal-alert" : "newCategory-css border"}`}
                                                >
                                                    <ChipInput
                                                        value={etiquetas}
                                                        onAdd={value => addChip(value)}
                                                        onDelete={handleRemoveChip}
                                                        newChipKeyCodes={[9, 13, 187, 188]}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col lg="8">
                                            <label htmlFor="files" className="uploadButtonModal wBtn btn">Seleccionar el documento <img src={circleupload} alt="circleupload" /></label>
                                            <input id="files" style={{ visibility: "hidden" }} onChange={onFileChange} type="file" accept="application/pdf" />
                                        </Col>
                                    </Row>

                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="out-csz" onClick={closeModalForm}>Salir</Button>
                                <Button className="next-css" onClick={handleStep1}>Siguiente Paso</Button>
                            </Modal.Footer>

                        </>
                    )}

                    {modalScreen === 1 && (
                        <>
                            <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter" style={{ width: "100%" }}>
                                    <img style={{ margin: "auto auto 0px", width: "150px", display: "flex" }} src={`${user?.body?.user.logo}`} alt="certiblock" />
                                </Modal.Title>
                                <Button className="out-css-header" onClick={handleModalClosed}>X</Button>

                            </Modal.Header>

                            <Modal.Body>
                                <Form>
                                    {
                                        documentCreate === true ? <p className="modal-title" style={{ paddingBottom: "30px" }}>INFORMACIÓN  REGISTRADA</p> : <p className="modal-title" style={{ paddingBottom: "30px" }}>INFORMACIÓN A REGISTRAR</p>
                                    }
                                    <Row className="row-select" style={{ padding: "0" }}>
                                        <Col lg="5" className="marginLabels">
                                            <div>
                                                <Form.Group controlId="formBasicName">
                                                    <Form.Label className="title-filter-modal">Nombre del Estudiante</Form.Label>
                                                    <Form.Control
                                                        className={changeName === true ? "input-Form registered-input  colorEdit" : "input-Form registered-input editInput"}
                                                        type="text"
                                                        placeholder=""
                                                        value={studentName}
                                                        onChange={(e) => setStudentName(e.target.value)}
                                                        disabled={changeName === true ? true : false}
                                                    />
                                                </Form.Group>
                                                {showBoxOne === true && (
                                                    <>
                                                        {
                                                            changeName === true && (
                                                                <Button className="redColorModal1" onClick={() => setChangeName(false)}>Cambiar</Button>
                                                            )
                                                        }
                                                        {
                                                            changeName === false && (
                                                                <div>
                                                                    <Button className="redColorModal1" onClick={() => setChangeName(true)}>Cancelar</Button>
                                                                    <Button className="redColorModal1" onClick={() => setChangeName(true)}>Cambiar</Button>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )}
                                            </div>
                                        </Col>

                                        <Col lg="3" className="marginLabels">
                                            <div>
                                                <Form.Group controlId="formBasicName">
                                                    <Form.Label className="title-filter-modal">ID Estudiante</Form.Label>
                                                    <Form.Control
                                                        className={changeStudentID === true ? "input-Form registered-input colorEdit" : "input-Form registered-input editInput"}
                                                        type="text"
                                                        placeholder=""
                                                        value={studentId}
                                                        onChange={(e) => setStudentId(e.target.value)}
                                                        disabled={changeStudentID === true ? true : false} />
                                                </Form.Group>
                                                {showBoxOne === true && (
                                                    <>
                                                        {
                                                            changeStudentID === true && (
                                                                <Button className="redColorModal1" onClick={() => setChangeStudentID(false)}>Cambiar</Button>
                                                            )
                                                        }
                                                        {
                                                            changeStudentID === false && (
                                                                <div>
                                                                    <Button className="redColorModal1" onClick={() => setChangeStudentID(true)}>Cancelar</Button>
                                                                    <Button className="redColorModal1" onClick={() => setChangeStudentID(true)}>Cambiar</Button>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )}
                                            </div>
                                        </Col>


                                        <Col lg="4" className="marginLabels">
                                            <div>
                                                <Form.Group controlId="formBasicName">
                                                    <Form.Label className="title-filter-modal">Nombre del Programa</Form.Label>
                                                    <Form.Control
                                                        className={changeProgramName === true ? "input-Form registered-input colorEdit" : "input-Form registered-input editInput"}
                                                        type="text"
                                                        placeholder=""
                                                        value={programName}
                                                        onChange={(e) => setProgramName(e.target.value)}
                                                        disabled={changeProgramName === true ? true : false}
                                                    />
                                                </Form.Group>
                                                {showBoxOne === true && (
                                                    <>
                                                        {
                                                            changeProgramName === true && (
                                                                <Button className="redColorModal1" onClick={() => setChangeProgramName(false)}>Cambiar</Button>
                                                            )
                                                        }
                                                        {
                                                            changeProgramName === false && (
                                                                <div>
                                                                    <Button className="redColorModal1" onClick={() => setChangeProgramName(true)}>Cancelar</Button>
                                                                    <Button className="redColorModal1" onClick={() => setChangeProgramName(true)}>Cambiar</Button>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row className="row-select" style={{ padding: "0px" }}>
                                        <Col lg="4" className="marginLabels">

                                            <div>
                                                {
                                                    emit === false && dateEmit != "" && (

                                                        <Form.Group controlId="dob">
                                                            <Form.Label className="title-filter-modal">Fecha de Emisión</Form.Label>
                                                            <Form.Control
                                                                onChange={handleDateEmit}
                                                                value={dateEmit}
                                                                className={changeDateEmit === true ? "noBorder colorEdit" : "select-css"}
                                                                type="date"
                                                                name="dob"
                                                                placeholder={dateEmit}
                                                                disabled={changeDateEmit === true ? true : false}
                                                            />
                                                        </Form.Group>
                                                    )
                                                }


                                                {showBoxOne === false && pubEmit != "" && emit === true && (
                                                    <>
                                                        <Form.Group controlId="dob">
                                                            <Form.Label className="title-filter-modal">Fecha de Emisión</Form.Label>
                                                            <Form.Control
                                                                value={pubEmit}
                                                                className={changeDateEmit === true ? "noBorder colorEdit" : "select-css"}
                                                                type="text"
                                                                name="dob"
                                                                placeholder={pubEmit}
                                                                disabled={changeDateEmit === true ? true : false}
                                                            />
                                                        </Form.Group>
                                                    </>
                                                )}
                                                {showBoxOne === true && (
                                                    <>
                                                        {
                                                            changeDateEmit === true && emit === false && dateEmit != "" && (
                                                                <Button className="redColorModal1" onClick={() => setChangeDateEmit(false)}>Cambiar</Button>
                                                            )
                                                        }
                                                        {
                                                            changeDateEmit === false && (
                                                                <div>
                                                                    <Button className="redColorModal1" onClick={() => setChangeDateEmit(true)}>Cancelar</Button>
                                                                    <Button className="redColorModal1" onClick={() => setChangeDateEmit(true)}>Cambiar</Button>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )}

                                            </div>
                                        </Col>
                                        <Col lg="1"></Col>
                                        <Col lg="6" className="flexiEmit" style={{ border: "1px dashed", marginTop: "20px" }}>
                                            <div>
                                                <p className="textEmit">
                                                    Si la fecha de emisión esta vacia será reemplazada por la fecha Actual.
                                                </p>
                                            </div>
                                        </Col>
                                        <Col lg="1"></Col>
                                    </Row>
                                    <hr></hr>

                                    <Row className="row-select" style={{ padding: "0" }}>
                                        <Col lg="4" className="marginLabels">
                                            <div>
                                                <Form.Group controlId="formBasicName">
                                                    <Form.Label className="title-filter-modal">Descripción del Programa</Form.Label>
                                                    <Form.Control
                                                        className={changeDescription === true ? "input-Form registered-input colorEdit" : "input-Form registered-input editInput"}
                                                        type="text"
                                                        placeholder=""
                                                        value={programDesc}
                                                        onChange={(e) => setProgramDesc(e.target.value)}
                                                        disabled={changeDescription === true ? true : false} />
                                                </Form.Group>
                                                {showBoxOne === true && (
                                                    <>
                                                        {
                                                            changeDescription === true && (
                                                                <Button className="redColorModal1" onClick={() => setChangeDescription(false)}>Cambiar</Button>
                                                            )
                                                        }
                                                        {
                                                            changeDescription === false && (
                                                                <div>
                                                                    <Button className="redColorModal1" onClick={() => setChangeDescription(true)}>Cancelar</Button>
                                                                    <Button className="redColorModal1" onClick={() => setChangeDescription(true)}>Cambiar</Button>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )}
                                            </div>
                                        </Col>
                                        <Col lg="4" className="marginLabels">
                                            <div>
                                                <Form.Group controlId="formBasicName">
                                                    <Form.Label className="title-filter-modal">Categoría</Form.Label>
                                                    <Form.Control className="input-Form registered-input colorEdit"
                                                        type="text"
                                                        value={category}
                                                        placeholder=""
                                                        disabled />
                                                </Form.Group>
                                                {showBoxOne === true && (
                                                    <>
                                                        <Button className="redColorModal1" onClick={() => setModalStep2Category(true)}>Cambiar</Button>
                                                    </>
                                                )}
                                            </div>
                                        </Col>
                                        <Modal show={modalStep2Category} aria-labelledby="contained-modal-title-vcenter"
                                            centered>
                                            <Modal.Header>
                                                <p className="modal-title">Seleccione la nueva Categoría</p>
                                                <Button className="out-css-header" onClick={() => setModalStep2Category(false)}><img className="closed-css-modal" src={closedicon} alt="closedicon" />
                                                </Button>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div>
                                                    <Form.Select onClick={changeCategoryStep2} className="select-css-modal" aria-label="Default select example">
                                                        {
                                                            selecCategoria.map((valor, index) => (
                                                                <option key={index} value={valor} >{valor}</option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button className="out-csz" onClick={() => setModalStep2Category(false)}>
                                                    Cancelar
                                                </Button>
                                                <Button className="next-css" onClick={saveChange}>
                                                    Guardar cambio
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                        <Col lg="3" className="marginLabels">
                                            <div>
                                                <Form.Group controlId="formBasicName">
                                                    <Form.Label className="title-filter-modal">Tipo</Form.Label>
                                                    <Form.Control className="input-Form registered-input colorEdit" type="text" value={type} placeholder="" disabled />
                                                </Form.Group>
                                                {showBoxOne === true && (
                                                    <>
                                                        <Button className="redColorModal1" onClick={() => SetModalChangeType(true)}>Cambiar</Button>
                                                    </>
                                                )}
                                            </div>
                                        </Col>
                                        <Modal show={modalChangeType} aria-labelledby="contained-modal-title-vcenter"
                                            centered>
                                            <Modal.Header>
                                                <p className="modal-title">Seleccione el Tipo</p>
                                                <Button className="out-css-header" onClick={() => SetModalChangeType(false)}><img className="closed-css-modal" src={closedicon} alt="closedicon" />
                                                </Button>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="SelectBusqueda">
                                                    <Form.Select onClick={changeType} className="select-css-modal" aria-label="Default select example">
                                                        {
                                                            tiposModal.map(valor => (
                                                                <option key={valor} value={valor} >{valor}</option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button className="out-csz" onClick={() => SetModalChangeType(false)}>
                                                    Cancelar
                                                </Button>
                                                <Button className="next-css" onClick={saveChangeType}>
                                                    Guardar cambio
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </Row>
                                    <hr></hr>
                                    <Row className="row-select" style={{ padding: "0" }}>

                                        <Col lg="4" className="marginLabels">
                                            <div>
                                                <Form.Group controlId="dob">
                                                    <Form.Label className="title-filter-modal">Fecha Inicio de Programa</Form.Label>
                                                    <Form.Control
                                                        onChange={handleDatePostInicio}
                                                        value={programDateInicio}
                                                        className={changeProgramDateInicio === true ? "noBorder colorEdit" : "select-css"}
                                                        type="date"
                                                        name="dob"
                                                        placeholder={programDateInicio}
                                                        disabled={changeProgramDateInicio === true ? true : false}
                                                    />
                                                </Form.Group>
                                                {showBoxOne === true && (
                                                    <>
                                                        {
                                                            changeProgramDateInicio === true && (
                                                                <Button className="redColorModal1" onClick={() => setChangeProgramDateInicio(false)}>Cambiar</Button>
                                                            )
                                                        }
                                                        {
                                                            changeProgramDateInicio === false && (
                                                                <div>
                                                                    <Button className="redColorModal1" onClick={() => setChangeProgramDateInicio(true)}>Cancelar</Button>
                                                                    <Button className="redColorModal1" onClick={() => setChangeProgramDateInicio(true)}>Cambiar</Button>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )}
                                            </div>
                                        </Col>

                                        <Col lg="4" className="marginLabels">
                                            <div>
                                                <Form.Group controlId="dob">
                                                    <Form.Label className="title-filter-modal">Fecha fin de Programa</Form.Label>
                                                    <Form.Control
                                                        onChange={handleDatePost}
                                                        value={programDate}
                                                        className={changeProgramDate === true ? "noBorder colorEdit" : "select-css"}
                                                        type="date"
                                                        name="dob"
                                                        placeholder={programDate}
                                                        disabled={changeProgramDate === true ? true : false}
                                                    />
                                                </Form.Group>
                                                {showBoxOne === true && (
                                                    <>
                                                        {
                                                            changeProgramDate === true && (
                                                                <Button className="redColorModal1" onClick={() => setChangeProgramDate(false)}>Cambiar</Button>
                                                            )
                                                        }
                                                        {
                                                            changeProgramDate === false && (
                                                                <div>
                                                                    <Button className="redColorModal1" onClick={() => setChangeProgramDate(true)}>Cancelar</Button>
                                                                    <Button className="redColorModal1" onClick={() => setChangeProgramDate(true)}>Cambiar</Button>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )}
                                            </div>
                                        </Col>

                                        <Col lg="3" className="marginLabels">
                                            <div>
                                                <Form.Group controlId="formBasicName">
                                                    <Form.Label className="title-filter-modal">ID Programa</Form.Label>
                                                    <Form.Control
                                                        className={changeProgramID === true ? "input-Form registered-input colorEdit" : "input-Form registered-input editInput"}
                                                        type="text"
                                                        placeholder=""
                                                        value={programId}
                                                        onChange={(e) => setProgramId(e.target.value)}
                                                        disabled={changeProgramID === true ? true : false} />
                                                </Form.Group>
                                                {showBoxOne === true && (
                                                    <>
                                                        {
                                                            changeProgramID === true && (
                                                                <Button className="redColorModal1" onClick={() => setChangeProgramID(false)}>Cambiar</Button>
                                                            )
                                                        }
                                                        {
                                                            changeProgramID === false && (
                                                                <div>
                                                                    <Button className="redColorModal1" onClick={() => setChangeProgramID(true)}>Cancelar</Button>
                                                                    <Button className="redColorModal1" onClick={() => setChangeProgramID(true)}>Cambiar</Button>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )}
                                            </div>
                                        </Col>

                                    </Row>
                                    <hr></hr>
                                    <Row className="row-select" style={{ padding: "0" }}>
                                        <Col lg="5" className="marginLabels">
                                            <Form.Group controlId="formBasicName">
                                                <Form.Label className="title-filter-modal">Documento a publicar:</Form.Label>
                                                <Form.Control className="input-Form registered-input colorEdit" type="text" placeholder="" value={selectedFileName} disabled />
                                            </Form.Group>
                                            {showBoxOne === true && (
                                                <>
                                                    <Button className="redColorModal1" onClick={() => SetModalChangeFile(true)}>Cambiar</Button>
                                                </>
                                            )}
                                        </Col>
                                        <Modal show={modalChangeFile} aria-labelledby="contained-modal-title-vcenter"
                                            centered>
                                            <Modal.Header>
                                                <p className="modal-title">Cambiar Documento</p>
                                                <Button className="out-css-header" onClick={() => SetModalChangeFile(false)}><img className="closed-css-modal" src={closedicon} alt="closedicon" />
                                                </Button>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Row>
                                                    <Col lg="12">
                                                        {showNewFile === true && (
                                                            <>
                                                                <Row>
                                                                    <Col lg="12" className="dis-row">
                                                                        <label><span className="fileName-css">Nombre del Documento:</span> {newFileName}</label>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <label htmlFor="files" className="uploadButton btn" > Seleccionar el documento a cargar </label>
                                                        <input id="files" style={{ visibility: "hidden" }} type="file" onChange={changeDoc} />
                                                    </Col>
                                                </Row>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button className="out-csz" onClick={() => SetModalChangeFile(false)}>
                                                    Cancelar
                                                </Button>
                                                <Button className="next-css" onClick={saveChangeDoc}>
                                                    Guardar cambio
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        <Col lg="4">
                                            <Form.Group style={{ marginBottom: "8px !important" }} controlId="formBasicExpediente">
                                                <Form.Label className="title-filter-modal">Etiquetas:</Form.Label>
                                            </Form.Group>
                                            {
                                                etiquetas.map(valor => (
                                                    <p className="tag-span colorEdit" style={{ marginBottom: "4rem !important" }} key={valor}>{valor}</p>
                                                ))
                                            }
                                            {showBoxOne === true && (
                                                <>
                                                    <Button className="redColorModal1 positionChange" onClick={() => setModalChangeTags(true)} >Cambiar</Button>
                                                </>
                                            )}
                                        </Col>
                                        <Modal show={modalChangeTags} aria-labelledby="contained-modal-title-vcenter"
                                            centered>
                                            <Modal.Header>
                                                <p className="modal-title">Cambiar Etiqueta</p>
                                                <Button className="out-css-header" onClick={() => setModalChangeTags(false)}><img className="closed-css-modal" src={closedicon} alt="closedicon" />
                                                </Button>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Form.Group className="mb-3" controlId="formBasicEtiquetas">
                                                    <Form.Label className="title-filter-modal">Etiquetas</Form.Label>
                                                    <div className="newCategory-css border">
                                                        <ChipInput
                                                            value={etiquetas}
                                                            onAdd={value => addChip(value)}
                                                            onDelete={(chip, index) => removeChip(chip, index)}
                                                            newChipKeyCodes={[9, 13, 187, 188]}
                                                        />
                                                    </div>
                                                </Form.Group>

                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button className="out-csz" onClick={() => setModalChangeTags(false)}>
                                                    Cancelar
                                                </Button>
                                                <Button className="next-css" onClick={() => setModalChangeTags(false)}>
                                                    Guardar cambio
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                    </Row>

                                    <div className="info-box" style={{ marginTop: "35px" }}>
                                        {showBoxOne === true && (
                                            <>
                                                <Row>
                                                    <Col lg="2" className="flex">
                                                        <img className="info-css" src={info} alt="info" />
                                                    </Col>
                                                    <Col lg="10">
                                                        <p className="info-text">Se va a publicar el documento en una red pública de tipo Blockchain. Por favor, revise antes que toda la información es correcta.</p>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                        {showBoxTwo === false && (
                                            <>
                                                <Row>
                                                    <Col lg="3" style={{ textAlign: "center", alignSelf: "self-end" }}>
                                                        <Spinner animation="border" variant="primary" />
                                                    </Col>
                                                    <Col lg="8">
                                                        <p className="info-text">Por favor, espere mientras se realiza la conexión con la Blockchain.
                                                            Realizando conexión…</p>
                                                    </Col>
                                                    <Col lg="2">

                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                        {documentCreate === true && (
                                            <Row style={{ padding: "0 !important" }}>
                                                <Col lg="2" className="flex">
                                                    <img className="cloud-css" src={cloud} alt="cloud" />
                                                </Col>
                                                <Col lg="7" style={{ alignSelf: "center" }}>
                                                    <p className="info-text-yellow">Públicación realizada con éxito</p>
                                                    <p className="info-text">Inscrita en la red POLYGON</p>
                                                    <p className="info-text">Publicado por: {creador}, el {dateHash} a las {timeHash} </p>
                                                    <p className="info-text"><a href={`${process.env.REACT_APP_URL_POLYGON}tx/${hash}`} target="_blank" >Identificador de la transacción</a></p>
                                                    <p className="info-text"><a href={`${pinataUrl}`} target="_blank" >Acceso al PDF Certificado</a></p>
                                                    <p className="info-text"><a href={`${metadataUrl}`} target="_blank" >Acceso a información en Blockchain</a></p>
                                                    <p className="info-text"><a href={`${contrato}`} target="_blank" >Acceso al contrato</a></p>
                                                </Col>
                                                <Col lg="3">
                                                    <a href={`${process.env.REACT_APP_QRURL}/landing/${idDoc}`} target="_blank">
                                                        <img className="info-css" style={{ margin: "0 auto", display: "flex" }} src={src} alt="qr" />
                                                    </a>
                                                    {/*<button className="btn btn-outline-secondary  border-bottom-0 border rounded-pill ms-n5 nav-button" onClick={() => setMailModal(true)} style={{ margin: "0 auto", display: "flex" }} type="button"><img className="share-icon" src={share} alt="share" />Compartir</button>*/}
                                                </Col>
                                            </Row>
                                        )}
                                    </div>

                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                {showBoxOne === true && (
                                    <>
                                        <Button className="out-csz" onClick={backModal}>Volver atrás</Button>
                                        <Button className="next-css" onClick={onAddFile}>Continuar</Button>
                                    </>
                                )}
                                {documentCreate === true && (
                                    <>
                                        <Button className="next-css" onClick={handleModalClosed}>
                                            Cerrar
                                        </Button>
                                    </>
                                )}
                            </Modal.Footer>
                        </>
                    )}
                </Modal>


                <Modal
                    show={mailModal}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Compartiendo documento</Modal.Title>
                        <Button className="out-css-header" onClick={() => setMailModal(false)}><img className="closed-css-modal" src={closedicon} alt="closedicon" />
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="formBasicCuenta">
                            <Form.Label className="title-filter-modal">Correo Electroníco:</Form.Label>
                            <Form.Control className="input-Form select-css-modal" type="text" placeholder=""
                                onChange={(e) => setCorreoDoc(e.target.value)}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            spinnermail === true && (
                                <>
                                    <div style={{ justifyContent: "center" }} className="d-flex align-items-center">
                                        <strong>Compartiendo documento...</strong>
                                        <div className="spinner-borderData ml-auto" role="status" aria-hidden="true"></div>
                                    </div>
                                </>
                            )
                        }
                        {
                            spinnermail === false && (
                                <>
                                    <Button variant="secondary" onClick={() => setMailModal(false)}>
                                        Cancelar
                                    </Button>
                                    <Button variant="primary" onClick={postMail}>Enviar</Button>
                                </>
                            )
                        }
                    </Modal.Footer>
                </Modal>

                {/**Modal borrado multiple BEGIN */}

                
                <Modal show={multipleDelete}  size="lg"
                >
                    <Modal.Header>

                        <Modal.Title id="contained-modal-title-vcenter" style={{ width: "100%" }}>
                            <img style={{ margin: "auto auto 0px", width: "150px", display: "flex" }} src={`${user?.body?.user.logo}`} alt="certiblock" />
                        </Modal.Title>
                        <Button className="out-csz" onClick={hideBurnMultipleItems}>X</Button>

                    </Modal.Header>

                    <Modal.Body>
                        
            
                            <Row  style={{ padding: "0px" }}>
                                
                                <Alert show={firstAlert && multipleDelete} className="alertBurn" variant="success" >
                                    <Alert.Heading>¿ Estas seguro ?!</Alert.Heading>
                                    <p className="mt-4" style={{ fontSize: "1.30rem" }}>
                                        Esta operación es irreversible e implica la perdida del <b>Token</b>  y el archivo.
                                    </p>
                                    <hr />
                                    <div className="d-flex justify-content-end">
                                        <Button onClick={() => setMultipleDeleteModal(false)} className="cancelButton" variant="outline-success" style={{ marginRight: "20px" }}>
                                            Cancelar
                                        </Button>
                                        <Button onClick={showSecondAlert} className="confirmButton" variant="outline-success">
                                            Continuar
                                        </Button>
                                    </div>
                                </Alert>
                                {
                                        showSpinnerBurning === true && (
                                            <>
                                                <div style={{ justifyContent: "center" }} className="d-flex align-items-center">
                                                    <strong>Preparando borrado...</strong>
                                                    <div className="spinner-borderData ml-auto" role="status" aria-hidden="true"></div>
                                                </div>
                                            </>
                                        )
                                    }

                                <Alert className="alertBurn" show={showAlert} variant="success" >
                                    <Alert.Heading>Confirmación</Alert.Heading>
                                    <p className="mt-4" style={{ fontSize: "1.30rem" }}>
                                        Escribe el correo de la sesión actual ({user?.body?.user.correo}) para poder eliminar definitivamente el certificado (Burn)
                                    </p>
                                    <Row>
                                        <Col lg={3}></Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                <Form.Label className="title-filter-modal text-center">Correo:</Form.Label>
                                                <Form.Control className="select-css input-Form newCategory-css text-center" type="text" placeholder=""
                                                    value={passwordConfirm} onChange={confirmBurn}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3}></Col>
                                    </Row>
                                    <hr />
                                    {
                                        burning === true && (
                                            <div className="d-flex justify-content-end">
                                                <Button onClick={cancelBurn} className="cancelButton" variant="outline-success" style={{ marginRight: "20px" }}>
                                                    Cancelar
                                                </Button>
                                                <Button onClick={handleCollectItems} className="confirmButton" disabled={!passwordConfirm} variant="outline-success">
                                                    Continuar
                                                </Button>
                                            </div>
                                        )
                                    }

                                    {
                                        showSpinnerBurning === true && (
                                            <>
                                                <div style={{ justifyContent: "center" }} className="d-flex align-items-center">
                                                    <strong>Realizando petición...</strong>
                                                    <div className="spinner-borderData ml-auto" role="status" aria-hidden="true"></div>
                                                </div>
                                            </>
                                        )
                                    }
                                </Alert>

                            </Row>
                    </Modal.Body>
                    <Modal.Footer>



                    </Modal.Footer>
                </Modal>




                {/**Modal borrado multiple END */}

                <Modal show={isEditing} size="lg"
                >
                    <Modal.Header>

                        <Modal.Title id="contained-modal-title-vcenter" style={{ width: "100%" }}>
                            <img style={{ margin: "auto auto 0px", width: "150px", display: "flex" }} src={`https://back.credentials.esade.edu/imagenes/logos/${user?.body?.user.logo}`} alt="certiblock" />
                        </Modal.Title>
                        <Button className="out-csz" onClick={handleClosedModal}>X</Button>

                    </Modal.Header>

                    <Modal.Body>
                        <Form>

                            <h3 className="modal-title" style={{ paddingBottom: "30px", fontSize: "14px !important" }}>Editar Información Registrada</h3>
                            <Row style={{ padding: "0px" }}>
                                <Col lg="5" className="marginLabels">
                                    <Form.Group className="mb-3" controlId="formBasicName">
                                        <Form.Label className="title-filter-modal">Nombre del Estudiante:</Form.Label>
                                        <Form.Control className="input-Form newCategory-css disabledInput" type="text" placeholder=""
                                            value={editingUser?.estudiante.nombre} disabled
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg="3" className="marginLabels">
                                    <Form.Group className="mb-3" controlId="formBasicName">
                                        <Form.Label className="title-filter-modal">ID Estudiante:</Form.Label>
                                        <Form.Control className="input-Form newCategory-css" type="text" placeholder=""
                                            value={editingUser?.estudiante.id} disabled
                                        />
                                    </Form.Group>
                                </Col>

                                <Col lg="4" className="marginLabels">
                                    <Form.Group className="mb-3" controlId="formBasicName">
                                        <Form.Label className="title-filter-modal">Nombre del Programa:</Form.Label>
                                        <Form.Control className="input-Form newCategory-css" type="text" placeholder=""
                                            value={editingUser?.programa.nombre} disabled
                                        />
                                    </Form.Group>
                                </Col>

                            </Row>
                            <hr></hr>
                            <Row className="row-select">
                                <Col lg="9" className="marginLabels">
                                    <Form.Group className="mb-3" controlId="dob">
                                        <Form.Label className="title-filter-modal">Documento Publicado:</Form.Label>
                                        <Form.Control className="input-Form newCategory-css" type="text" placeholder=""
                                            value={editingUser?.infoDocumento.nombre} disabled
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg="3">
                                    <Form.Group controlId="formBasicName">
                                        <Form.Label className="title-filter-modal">Fecha de Emisión:</Form.Label>
                                        <Form.Control className="input-Form newCategory-css" type="text" placeholder=""
                                            value={moment(editingUser?.fechaEmision).format('DD-MM-YYYY')} disabled
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row className="row-select" style={{ padding: "0px" }}>
                                <Col lg="4">
                                    <Form.Group className="mb-3" controlId="formBasicName">
                                        <Form.Label className="title-filter-modal">Descripción del Programa:</Form.Label>
                                        <Form.Control className="input-Form newCategory-css" type="text" placeholder=""
                                            value={editingUser?.programa.descripcion} disabled
                                        />
                                    </Form.Group>
                                </Col>
                                {/* <Alert className="alertBurn" show={notburn} variant="success" >
                                    <Alert.Heading>¡Atención!</Alert.Heading>
                                    <p className="mt-4" style={{ fontSize: "1.30rem" }}>
                                        Solo el propietario puede hacer burn del token.  <b>Token</b>.
                                    </p>
                                    <hr />
                                    <div className="d-flex justify-content-end">
                                        <Button onClick={() => setNotburn(false)} className="confirmButton" variant="outline-success">
                                            Aceptar
                                        </Button>
                                    </div>
                    </Alert>*/}
                                <Alert className="alertBurn" show={show} variant="success" >
                                    <Alert.Heading>¿ Estas seguro ?!</Alert.Heading>
                                    <p className="mt-4" style={{ fontSize: "1.30rem" }}>
                                        Esta operación es irreversible e implica la perdida del <b>Token</b>  y el archivo.
                                    </p>
                                    <hr />
                                    <div className="d-flex justify-content-end">
                                        <Button onClick={() => setShow(false)} className="cancelButton" variant="outline-success" style={{ marginRight: "20px" }}>
                                            Cancelar
                                        </Button>
                                        <Button onClick={showSecondAlert} className="confirmButton" variant="outline-success">
                                            Continuar
                                        </Button>
                                    </div>
                                </Alert>

                                <Alert className="alertBurn" show={showAlert} variant="success" >
                                    <Alert.Heading>Confirmación</Alert.Heading>
                                    <p className="mt-4" style={{ fontSize: "1.30rem" }}>
                                        Escribe el correo de la sesión actual ({user?.body?.user.correo}) para poder eliminar definitivamente el certificado (Burn)
                                    </p>
                                    <Row>
                                        <Col lg={3}></Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                <Form.Label className="title-filter-modal text-center">Correo:</Form.Label>
                                                <Form.Control className="select-css input-Form newCategory-css text-center" type="text" placeholder=""
                                                    value={passwordConfirm} onChange={confirmBurn}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3}></Col>
                                    </Row>
                                    <hr />
                                    {
                                        burning === true && (
                                            <div className="d-flex justify-content-end">
                                                <Button onClick={cancelBurn} className="cancelButton" variant="outline-success" style={{ marginRight: "20px" }}>
                                                    Cancelar
                                                </Button>
                                                <Button onClick={burn} className="confirmButton" disabled={!passwordConfirm} variant="outline-success">
                                                    Continuar
                                                </Button>
                                            </div>
                                        )
                                    }

                                    {
                                        showSpinnerBurning === true && (
                                            <>
                                                <div style={{ justifyContent: "center" }} className="d-flex align-items-center">
                                                    <strong>Realizando petición...</strong>
                                                    <div className="spinner-borderData ml-auto" role="status" aria-hidden="true"></div>
                                                </div>
                                            </>
                                        )
                                    }
                                </Alert>
                                <Col lg="4" className="marginLabels">
                                    <div>
                                        <Form.Group controlId="formBasicName">
                                            <Form.Label className="title-filter-modal">Categoría</Form.Label>
                                            <Form.Select className="select-css" style={{ fontSize: "1rem", fontWeight: "600" }} onChange={(e) => {
                                                setEditingUser((pre) => {
                                                    return { ...pre, categoria: e.target.value };
                                                });
                                            }} defaultValue={editingUser?.categoria} aria-label="Default select example">
                                                {
                                                    selecCategoria.map((valor, index) => (
                                                        <option key={index} value={valor} >{valor}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                            <Button className="redColorModal1" onClick={() => setModalEditCategory(true)}>Añadir nueva categoría</Button>

                                        </Form.Group>
                                        <Modal show={modalEditCategory} aria-labelledby="contained-modal-title-vcenter"
                                            centered>
                                            <Modal.Header>
                                                <p className="modal-title">Agregar nueva Categoría</p>
                                                <Button className="out-css-header" onClick={() => setModalEditCategory(false)}><img className="closed-css-modal" src={closedicon} alt="closedicon" />
                                                </Button>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Form.Group className="mb-3" controlId="formBasicName">
                                                    <Form.Label className="title-filter-modal">Nombre de la categoría</Form.Label>
                                                    <Form.Control className="input-Form newCategory-css" type="text" placeholder="" value={newCategory} onChange={(e) => setNewCategory(e.target.value)} />
                                                </Form.Group>
                                                {newCategory === "" && (
                                                    <>
                                                        <span className="text-validate">No se puede dejar el campo vacio.</span>
                                                    </>
                                                )}
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button className="out-csz" onClick={() => setModalEditCategory(false)}>
                                                    Cancelar
                                                </Button>
                                                <Button className="next-css" onClick={addNewcategory} disabled={!newCategory}>
                                                    Agregar Categoría
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        {!editingUser?.categoria && (
                                            <>
                                                <span className="text-validate">No puede enviar campos vacios</span>
                                            </>
                                        )}
                                    </div>
                                </Col>

                                <Col lg="3" className="marginLabels">
                                    <div>
                                        <Form.Group controlId="formBasicName">
                                            <Form.Label className="title-filter-modal">Tipo</Form.Label>
                                            <Form.Select className="select-css" style={{ fontSize: "1rem", fontWeight: "600" }} onChange={(e) => {
                                                setEditingUser((pre) => {
                                                    return { ...pre, tipo: e.target.value };
                                                });
                                            }} defaultValue={editingUser?.tipo} aria-label="Default select example">
                                                {
                                                    tiposModal.map((valor, index) => (
                                                        <option key={index} value={valor} >{valor}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                            <Button className="redColorModal1" onClick={() => setModalEditType(true)}>Añadir nuevo tipo</Button>
                                        </Form.Group>
                                        <Modal show={modalEditType} aria-labelledby="contained-modal-title-vcenter"
                                            centered>
                                            <Modal.Header>
                                                <p className="modal-title">Agregar nuevo Tipo</p>
                                                <Button className="out-css-header" onClick={() => setModalEditType(false)}><img className="closed-css-modal" src={closedicon} alt="closedicon" />
                                                </Button>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Form.Group className="mb-3" controlId="formBasicName">
                                                    <Form.Label className="title-filter-modal">Nombre del Tipo</Form.Label>
                                                    <Form.Control className="input-Form newCategory-css" type="text" placeholder="" value={newType} onChange={(e) => setNewType(e.target.value)} />
                                                </Form.Group>
                                                {newType === "" && (
                                                    <>
                                                        <span className="text-validate">No se puede dejar el campo vacio.</span>
                                                    </>
                                                )}
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button className="out-csz" onClick={() => setModalEditType(false)}>
                                                    Cancelar
                                                </Button>
                                                <Button className="next-css" onClick={addNewType} disabled={!newType}>
                                                    Agregar Nuevo Tipo
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        {!editingUser?.tipo && (
                                            <>
                                                <span className="text-validate">No puede enviar campos vacios</span>
                                            </>
                                        )}
                                    </div>
                                </Col>

                            </Row>

                            <hr></hr>
                            <Row className="row-select" style={{ padding: "0px" }}>
                                <Col lg="3" className="marginLabels">
                                    <Form.Group className="mb-3" controlId="formBasicName">
                                        <Form.Label className="title-filter-modal">ID Programa:</Form.Label>
                                        <Form.Control className="input-Form newCategory-css" type="text" placeholder=""
                                            value={editingUser?.programa.id} disabled
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" className="marginLabels">
                                    <Form.Group controlId="dob">
                                        <Form.Label className="title-filter-modal">Fecha Inicio de Programa:</Form.Label>
                                        <Form.Control className="input-Form newCategory-css" type="text" placeholder=""
                                            value={moment(moment(editingUser?.programa.fechaInicio)).format('DD/MM/YYYY')
                                            } disabled
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" className="marginLabels">
                                    <Form.Group controlId="dob">
                                        <Form.Label className="title-filter-modal">Fecha Fin de Programa:</Form.Label>
                                        <Form.Control className="input-Form newCategory-css" type="text" placeholder=""
                                            value={moment(moment(editingUser?.programa.fechaFin)).format('DD/MM/YYYY')} disabled
                                        />
                                    </Form.Group>
                                </Col>

                            </Row>
                            <hr></hr>

                            <Row className="row-select" style={{ padding: "0px", marginBottom: "10px" }}>
                                

                                <Col lg="4" className="marginLabels">
                                    <Form.Group controlId="formBasicExpediente">
                                        <Form.Label className="title-filter-modal">Etiquetas:</Form.Label>
                                        <div className="newCategory-css showing">
                                            <ChipInput
                                                value={editingUser?.etiquetas}
                                                onAdd={value => addChip(value)}
                                                onDelete={handleRemoveChip}
                                                newChipKeyCodes={[9, 13, 187, 188]}
                                                disabled
                                            />
                                        </div>
                                    </Form.Group>

                                </Col>
                                <Col lg="4" className="marginLabels">
                                    <Form.Group controlId="formBasicExpediente">
                                        <Form.Label className="title-filter-modal">Nuevas Etiquetas:</Form.Label>
                                        <div className="newCategory-css">
                                            <ChipInput
                                                value={newEtiquetas}
                                                onAdd={value => addChipEdit(value)}
                                                onDelete={handleRemoveChipEdit}
                                                newChipKeyCodes={[9, 13, 187, 188]}
                                            />
                                        </div>
                                    </Form.Group>

                                </Col>
                            </Row>
                        </Form>
                        <Row className="mt-5">
                            <Col lg={2} xs={3} className="text-center">
                                <Button className="burn-bottom" onClick={validateBurn}>Burn</Button>
                            </Col>
                            <Col lg={4} xs={3} className="d-none d-sm-block">
                            </Col>
                            <Col lg={3} xs={5} className="text-center d-flex justify-content-end">
                                <Button className="out-csz" onClick={handleClosedModal}>Cancelar</Button>
                            </Col>
                            <Col lg={3} xs={4} className="text-center d-flex justify-content-end">
                                {submitbutton === true &&
                                    (
                                        <>
                                            <Button className="next-css" disabled={!editingUser?.categoria || !editingUser?.tipo} onClick={() => {
                                                setDataSource((pre) => {
                                                    return pre.map(async (student) => {
                                                        if (student._id === editingUser._id) {
                                                            const idDoc = editingUser._id;
                                                            let DataUpdate = {

                                                                idDocumento: editingUser?._id,
                                                                categoria: editingUser?.categoria,
                                                                tipo: editingUser?.tipo,
                                                                fecha: editingUser?.fecha,
                                                                etiquetas: newEtiquetas.length == 0 ? editingUser?.etiquetas : newEtiquetas,
                                                                infoActividad: {
                                                                    detalles: `${textDetalle} ${ip}`,
                                                                    tipo: editingUser?.tipo,
                                                                }


                                                            }
                                                            //editDocument(DataUpdate,setPasswordbutton,setSubmitbutton)

                                                            try {
                                                                let res = await axios.put(`${process.env.REACT_APP_URLSERVER}/v2/documentaciones`, DataUpdate, {
                                                                    headers: {
                                                                        'Content-Type': 'application/json',
                                                                        "Access-Control-Allow-Origin": "*",
                                                                        "Authorization": user?.body?.jwt
                                                                    }
                                                                })
                                                                if (res.status === 200) {
                                                                    swal({
                                                                        title: "Documento Actualizado!",
                                                                        icon: "success",
                                                                        button: "Aceptar"
                                                                    });
                                                                    // getData()
                                                                    setNewEtiquetas([])
                                                                    setPasswordbutton(false)
                                                                    setSubmitbutton(true)
                                                                    //window.location.reload();
                                                                } else if (res.status === 400) {
                                                                    swal({
                                                                        title: res.data.msg,
                                                                        icon: "warning",
                                                                        button: "Aceptar"
                                                                    });
                                                                }

                                                            } catch (error) {
                                                                swal({
                                                                    title: "Ocurrio un error , por favor verifica e intenta nuevamente",
                                                                    icon: "warning",
                                                                    button: "Aceptar"
                                                                });
                                                            }

                                                        } else {
                                                            return student;
                                                        }
                                                    });
                                                });
                                                resetEditing();
                                            }}>
                                                Guardar
                                            </Button>
                                        </>
                                    )
                                }
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>



                    </Modal.Footer>
                </Modal>


                <Modal show={isWhowing} size="lg"
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter" style={{ width: "100%" }}>
                            <img style={{ margin: "auto auto 0px", width: "150px", display: "flex" }} src={`https://back.credentials.esade.edu/imagenes/logos/${user?.body?.user.logo}`} alt="certiblock" />
                        </Modal.Title>
                        <Button className="out-css-header" onClick={() => setIsWhowing(false)}>X</Button>

                    </Modal.Header>

                    <Modal.Body>
                        <Form>

                            <p className="modal-title" style={{ paddingBottom: "30px" }}>INFORMACIÓN REGISTRADA</p>
                            <Row className="row-select">
                                <Col lg="5" className="marginLabels">
                                    <div>
                                        <Form.Group controlId="formBasicName">
                                            <Form.Label className="title-filter-modal">Nombre del Estudiante</Form.Label>
                                            <Form.Control className="input-Form newCategory-css" type="text" placeholder=""
                                                value={editingUser?.estudiante.nombre} disabled
                                            />
                                        </Form.Group>
                                    </div>
                                </Col>

                                <Col lg="3" className="marginLabels">
                                    <div>
                                        <Form.Group controlId="formBasicName">
                                            <Form.Label className="title-filter-modal">ID Estudiante</Form.Label>
                                            <Form.Control className="input-Form newCategory-css" type="text" placeholder=""
                                                value={editingUser?.estudiante.id} disabled
                                            />
                                        </Form.Group>
                                    </div>
                                </Col>

                                <Col lg="4" className="marginLabels">
                                    <div>
                                        <Form.Group controlId="formBasicName">
                                            <Form.Label className="title-filter-modal">Nombre del Programa</Form.Label>
                                            <Form.Control className="input-Form newCategory-css" type="text" placeholder=""
                                                value={editingUser?.programa.nombre} disabled
                                            />
                                        </Form.Group>
                                    </div>
                                </Col>

                            </Row>
                            <hr></hr>
                            <Row className="row-select">
                            <Col lg="9" className="marginLabels">
                                    <Form.Group controlId="formBasicName">
                                        <Form.Label className="title-filter-modal">Documento Publicado:</Form.Label>
                                        <Form.Control className="input-Form newCategory-css" type="text" placeholder=""
                                            value={editingUser?.infoDocumento.nombre} disabled
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg="3">
                                    <Form.Group controlId="dob">
                                        <Form.Label className="title-filter-modal">Fecha de Emisión:</Form.Label>
                                        <Form.Control className="input-Form newCategory-css" type="text" placeholder=""
                                            value={moment(editingUser?.fechaEmision).format('DD-MM-YYYY')} disabled
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row className="row-select">
                                <Col lg="4" className="marginLabels">
                                    <Form.Group controlId="formBasicName">
                                        <Form.Label className="title-filter-modal">Descripción del Programa:</Form.Label>
                                        <Form.Control className="input-Form newCategory-css" type="text" placeholder=""
                                            value={editingUser?.programa.descripcion} disabled
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" className="marginLabels">
                                    <div>
                                        <Form.Group controlId="formBasicName">
                                            <Form.Label className="title-filter-modal">Categoría</Form.Label>
                                            <Form.Control className="input-Form newCategory-css" type="text" placeholder=""
                                                value={editingUser?.categoria} disabled
                                            />
                                        </Form.Group>
                                    </div>
                                </Col>

                                <Col lg="3" className="marginLabels">
                                    <div>
                                        <Form.Group controlId="formBasicName">
                                            <Form.Label className="title-filter-modal">Tipo</Form.Label>
                                            <Form.Control className="input-Form newCategory-css" type="text"
                                                value={editingUser?.tipo}
                                                placeholder="" disabled />
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row className="row-select">
                                <Col lg="4" className="marginLabels">
                                    <Form.Group controlId="formBasicName">
                                        <Form.Label className="title-filter-modal">Fecha Inicio de Programa:</Form.Label>
                                        <Form.Control className="input-Form newCategory-css" type="text" placeholder=""
                                            value={moment(moment(editingUser?.programa.fechaInicio)).format('DD/MM/YYYY')} disabled
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" className="marginLabels">
                                    <Form.Group controlId="formBasicName">
                                        <Form.Label className="title-filter-modal">Fecha fin de Programa:</Form.Label>
                                        <Form.Control className="input-Form newCategory-css" type="text" placeholder=""
                                            value={moment(moment(editingUser?.programa.fechaFin)).format('DD/MM/YYYY')} disabled
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg="3" className="marginLabels">
                                    <Form.Group controlId="formBasicName">
                                        <Form.Label className="title-filter-modal">ID Programa:</Form.Label>
                                        <Form.Control className="input-Form newCategory-css" type="text" placeholder=""
                                            value={editingUser?.programa.id} disabled
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row className="row-select">
                                

                                <Col lg="5" className="marginLabels">
                                    <Form.Group controlId="formBasicExpediente">
                                        <Form.Label className="title-filter-modal">Etiquetas:</Form.Label>
                                        <div className="newCategory-css showing">
                                            <ChipInput
                                                value={editingUser?.etiquetas}
                                                onAdd={value => addChip(value)}
                                                onDelete={handleRemoveChip}
                                                newChipKeyCodes={[9, 13, 187, 188]}
                                                disabled
                                            />
                                        </div>
                                    </Form.Group>

                                </Col>
                            </Row>
                            <div className="info-box">
                                <Row style={{ padding: "0 !important" }}>
                                    <Col lg="2" className="flex">
                                        <img className="cloud-css" src={cloud} alt="cloud" />
                                    </Col>
                                    <Col lg="7" style={{ alignSelf: "center" }}>
                                        <p className="info-text-yellow">Públicación realizada con éxito</p>
                                        <p className="info-text">Inscrita en la red POLYGON</p>
                                        <p className="info-text">Publicado por: {editingUser?.cliente.nombre} , el {moment(moment(editingUser?.fecha)).format('DD/MM/YYYY')} a las {moment(moment(editingUser?.fecha)).format("hh:mm:ss a")} </p>
                                        <p className="info-text"><a href={`${polygon}tx/${editingUser?.infoDocumento.hash}`} target="_blank" >Identificador de la transacción</a></p>
                                        <p className="info-text"><a href={`${editingUser?.infoDocumento.url}`} target="_blank" >Acceso al PDF Certificado</a></p>
                                        <p className="info-text"><a href={`${editingUser?.infoDocumento.urlMetadata}`} target="_blank" >Acceso a información en Blockchain</a></p>
                                        <p className="info-text"><a href={`${polygon}address/${editingUser?.infoDocumento.direccionContrato}`} target="_blank" >Acceso al contrato</a></p>
                                    </Col>
                                    <Col lg="3">
                                        <a href={`${process.env.REACT_APP_QRURL}/landing/${editingUser?._id}`} target="_blank">
                                            <img className="info-css" style={{ margin: "0 auto", display: "flex" }} src={src} alt="qr" />
                                        </a>
                                        { /*<button className="btn btn-outline-secondary  border-bottom-0 border rounded-pill ms-n5 nav-button" onClick={() => setMailModal(true)} style={{ margin: "0 auto", display: "flex" }} type="button"><img className="share-icon" src={share} alt="share" />Compartir</button>*/}
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="next-css" onClick={() => setIsWhowing(false)}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div >


        </>
    );
}
