import { useEffect, useState } from 'react';
import {
    Navigate,
    useLocation
} from "react-router-dom";
import ListDocu from '../Documentation/ListDocu';
import Login from '../Login/Login';
import Usuario from '../Usuarios/Usuario';
import Actividad from '../Actividad/Actividad';
import ActividadAdmin from '../AdminInterface/ActividadAdmin';
import Consumo from '../AdminInterface/Consumo';
import Clientes from '../AdminInterface/Clientes';
import Facturacion from '../AdminInterface/Facturacion';



const componentesCliente = {
    '/documentacion': <ListDocu />,
    '/login': <Login />,
    '/actividad': <Actividad />,
    '/usuario': <Usuario />,
}

const componentesAdmin = {
    '/actividadadm': <ActividadAdmin />,
    '/consumo': <Consumo />,
    '/clientes': <Clientes />,
    '/facturacion': <Facturacion />,
}


export function LoginGuard() {
    const url = useLocation()

    let auth = localStorage.getItem("rol-user")



    if (auth === 'CLIENT_ROL') {
        return <Navigate to="/documentacion" />
    } else if (auth === 'USER_ROL') {
        return <Navigate to="/documentacion" />
    } else if (auth === 'ADMIN_ROL') {
        return <Navigate to="/clientes" />
    } else if (!auth) {
        return <Login />
    }

}

export function DocsGuard() {
    const url = useLocation()

    let auth = localStorage.getItem("rol-user")



    if (auth === 'CLIENT_ROL' || auth === 'USER_ROL') {
        return componentesCliente[url.pathname]
    }
    else if (auth === 'ADMIN_ROL') {
        return <Navigate to="/clientes" />
    } else if (!auth) {
        return <Navigate to="/" />
    }


}

export function AdminGuard() {
    const url = useLocation()
    let auth = localStorage.getItem("rol-user")


    if (auth === 'CLIENT_ROL' || auth === 'USER_ROL') {
        return <Navigate to="/documentacion" />
    }
    else if (auth === 'ADMIN_ROL') {
        return componentesAdmin[url.pathname]
    } else if (!auth) {
        return <Navigate to="/" />
    }

}



