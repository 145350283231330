import React, { useContext, useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import QRCode from 'qrcode';
import { Row, Col, Button, Collapse, Form, Container, Modal, Spinner } from 'react-bootstrap';
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import linken from "../../assets/linkedin.svg";
import checkLanding from "../../assets/checkLanding.svg"
import checkCerti from "../../assets/checkcerti2.svg"

import chevronRight from "../../assets/chevronRight.svg"
import chevronDown from "../../assets/chevronDown.svg"
import FooterImg from "../../assets/footer.png"

import { Document, pdfjs, Page } from "react-pdf";

import swal from 'sweetalert';

import axios from "axios";

import { IntlProvider } from 'react-intl';


// css
import './cssqr.css'
// Logo
import logo from "../../assets/logo/esade_svg_logo.svg";
import logoCerti from "../../assets/logo/LogoCerti.png";
import pdf from "../../assets/fileDow.svg";
import closedicon from "../../assets/boton-x.png";

import logoletra from "../../assets/logo/esadeletter.png";
import en from '../../lang/en-US.json';
import es from '../../lang/es-ES.json';
import ca from '../../lang/ca-ES.json';

import linkedInLogo from "../../assets/logo/Icono_linkedin.svg";
import downloadLogo from "../../assets/logo/Icono-download.svg";
import bitcoinLogo from "../../assets/logo/Icono-bitcoin.svg";
import bitcoinIcon from "../../assets/logo/check-icon.svg"


// lenguaje
import { FormattedMessage, FormattedDate } from 'react-intl';
import { langContext } from '../../context/langContext';
import moment from 'moment'



export default function Landing() {
    const polygon = process.env.REACT_APP_URL_POLYGON;
    const [password, setPassword] = useState('');
    const [nombre, setNombre] = useState('');
    const [status, setStatus] = useState('DELETED');
    const [fecha, setFecha] = useState('');
    const [programName, setProgramName] = useState('');
    const [urlPdf, setUrlPdf] = useState('');
    const [urlToken, setUrlToken] = useState('');
    const [shareUrl, setShareUrlLinkedIn] = useState('');
    const [open, setOpen] = useState(false);
    const [metaurl, setMetaurl] = useState('');
    const [hash, setHash] = useState('');
    const [contrato, setContrato] = useState('');
    const [tokenId, setTokenId] = useState();
    const [spinner, setSpinner] = useState(true);
    // QR CODE 
    const [src, setSrc] = useState('');
    // IP
    const [ip, setIP] = useState('');
    const [textDetalle, setTextDetalle] = useState('');
    // Modal
    const [dateModal, setDateModal] = useState(false);


    const [idim, setIdim] = useState('ca-ES')
    const [msg, setMsg] = useState(ca);


    let { ids } = useParams();

    const idioma = useContext(langContext);

    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    console.log('alfin var polygon', process.env.REACT_APP_URL_POLYGON);



    /*To Prevent right click on screen*/
    document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
    });

    /*When document gets loaded successfully*/
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }



    useEffect(() => {
        callData()
        getipData()
        getFaviconEl();
        checkDevice()
        handleFavIcon()
    }, []);

    const getipData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4)
    }
    const handleFavIcon = () => {
        const favicon = getFaviconEl(); // Accessing favicon element
        favicon.href = "%PUBLIC_URL%/favicon_certi.png";
    };

    const user = JSON.parse(localStorage.getItem('user-info'));


    function getFaviconEl() {
        return document.getElementById("favicon");
    }

    const checkDevice = () => {
        if (isMobile) {
            let tlfText = `Acceso mediante dispositivo móvil, IP`
            setTextDetalle(tlfText)
        } else {
            let tlfText = `Acceso mediante ordenador personal, IP`
            setTextDetalle(tlfText)
        }
    }

    const callData = async () => {
        /*let dataConsulta = {
            infoActividad: {
                "detalles": `${textDetalle} ${ip}`,
                "tipo": "tipo",
            }
        }*/
        //console.log("Body", dataConsulta)
        setUrlPdf('')
        setUrlToken('')
        try {
            const result = await axios(`${process.env.REACT_APP_URLSERVER}/v2/documentacionAccess/${ids}`);

            let data = result.data.body

            console.log("data: ",data);

            if (data.status === "DELETED") {
                swal({
                    title: "Este certificado ha sido eliminado (Burn).",
                    text: "______",
                    icon: 'error',
                    buttons: false,
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    allowOutsideClick: false,
                });

                let offset = moment(data.fechaEmision).zone(data.fechaEmision)
                offset.format("DD-MM-YYYY HH:mm:ss ZZ")
                let fechaEmit = ' ' +offset.format("DD/MM/YYYY");
                let nameCertificate = data.programa.nombre
                let year = moment(moment(data.fecha)).format('YYYY')
                let month = moment(moment(data.fecha)).format('MM')
                let endYear = moment(moment(data.programa.fechaFin)).format('YYYY')
                let endMonth = moment(moment(data.programa.fechaFin)).format('MM')
                let certiId = `Esade Credential ${data.infoDocumento.tokenId}`
                setStatus(data.status)
                setNombre(data.estudiante.nombre)
                setFecha(fechaEmit)
                setProgramName(data.programa.nombre)
                let url = result.data.body.infoDocumento.url;
                const metaurl = result.data.body.infoDocumento.urlMetadata
                let hash = result.data.body.infoDocumento.hash;
                let idDoc = result.data.body._id;
                let contratoCode = result.data.body.infoDocumento.direccionContrato
                let urlDoc = `https://certisigner.mypinata.cloud/ipfs/${idDoc}`;
                let contrato = `${polygon}address/${result.data.body.infoDocumento.direccionContrato}`;
                let token = `${polygon}token/${contratoCode}?a=${result.data.body.infoDocumento.tokenId}`;
                let tokenUrl= `${polygon}token/${contratoCode}?a=${result.data.body.infoDocumento.tokenId}`;
                setTokenId(token)
                setContrato(contrato)
                setUrlPdf(url);
                setUrlToken(tokenUrl);
                setMetaurl(metaurl);
                let urlShare = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${nameCertificate}&organizationId=163678&issueYear=${year}&issueMonth=${month}&certUrl=${url}&certId=${certiId}`;
                setHash(hash);
                setShareUrlLinkedIn(urlShare)
                QRCode.toDataURL(url).then((data) => {
                    setSrc(data);
                    openNewTab(data);
                })
            } else {
                let offset = moment(data.fechaEmision).zone(data.fechaEmision)
                offset.format("DD-MM-YYYY HH:mm:ss ZZ")
                let fechaEmit = offset.format("DD/MM/YYYY");

                let nameCertificate = data.programa.nombre
                let year = moment(moment(data.fecha)).format('YYYY')
                let month = moment(moment(data.fecha)).format('MM')
                let endYear = moment(moment(data.programa.fechaFin)).format('YYYY')
                let endMonth = moment(moment(data.programa.fechaFin)).format('MM')
                let certiId = `Esade Credential ${data.infoDocumento.tokenId}`
                setNombre(data.estudiante.nombre)
                setFecha(fechaEmit)
                setStatus(data.status)

                setProgramName(data.programa.nombre)
                let url = result.data.body.infoDocumento.url;
                const metaurl = result.data.body.infoDocumento.urlMetadata
                let hash = result.data.body.infoDocumento.hash;
                let idDoc = result.data.body._id;
                let contratoCode = result.data.body.infoDocumento.direccionContrato
                let urlDoc = `https://certisigner.mypinata.cloud/ipfs/${idDoc}`;
                let contrato = `${polygon}address/${result.data.body.infoDocumento.direccionContrato}`;
                let token = `${polygon}token/${contratoCode}?a=${result.data.body.infoDocumento.tokenId}`;
                let tokenUrl = `${polygon}token/${contratoCode}?a=${result.data.body.infoDocumento.tokenId}`;
                setTokenId(token)
                setContrato(contrato)
                setUrlPdf(url);
                setUrlToken(tokenUrl);
                setMetaurl(metaurl);
                let urlShare = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${nameCertificate}&organizationId=163678&issueYear=${year}&issueMonth=${month}&certUrl=${url}&certId=${certiId}`;
                setHash(hash);
                setShareUrlLinkedIn(urlShare)
                QRCode.toDataURL(url).then((data) => {
                    setSrc(data);
                })
            }
        } catch (error) {
            console.log(error.response)
            QRCode.toDataURL(error.response.data.body).then((data) => {
                setSrc(data);
            })
            swal({
                title: error.response.data.body,
                icon: 'error',
                text: "______",
                buttons: false,
                closeOnClickOutside: false,
                closeOnEsc: false,
                allowOutsideClick: false,
            });
        }
    }


    const openDoc = () => {
        //window.location.href = urlPdf
        window.open(
            urlPdf,
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    const openTokenUrl = () => {
        console.log('urlToken', urlToken);
        window.open(
            urlToken,
            '_blank'
        );
    }

    const shareDoc = () => {
        window.open(
            shareUrl,
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    const shareContract = () => {
        window.open(
            metaurl,
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    const openTx = () => {
        window.open(
            tokenId,
            '_blank' // <- This is what makes it open in a new window.
        );
    }
    const openNewTab = (dataURL) => {
        console.log('dataURL', src);
        const newWindow = window.open();
        newWindow.document.write(`
        <html>
        <head>
          <title>QR Code</title>
        </head>
        <body>
            <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <img style=" width: 20%;" src="${src}" alt="QR Code" />
                <button id="downloadButton">Descargar código QR</button>
              </div>
          <script>
            const downloadButton = document.getElementById('downloadButton');
            downloadButton.addEventListener('click', function() {
              const link = document.createElement('a');
              link.href = '${src}';
              link.download = 'qrcode.png';
              link.click();
            });

            window.onload = function() {
              window.print();
            };
          </script>
        </body>
      </html>
        `);
      };

      const downloadQR = () => {
        const link = document.createElement('a');
        link.href = src;
        link.download = 'qrcode.png';
        link.click();
      };

    const handleidioma = (e) => {
        const currValue = e.target.value;
        if (currValue === "es") {
            idioma.setLenguaje('es-ES')
            setIdim('es-ES')
            setMsg(es)
        } else if (currValue === "en") {
            idioma.setLenguaje('en-US')
            setIdim('en-US')
            setMsg(en)
        } else if (currValue === "ca") {
            idioma.setLenguaje('ca-ES')
            setIdim('ca-ES')
            setMsg(ca)
        }
    }

    const dateModalinfo = () => {
        setDateModal(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500);
    }

    const closeDateModal = () => {
        setDateModal(false)
        setSpinner(true)
    }


    const arrLenguaje = [
        {
            nombre: "en"
        },
        {
            nombre: "ca"
        },
        {
            nombre: "es"
        },

    ]



    return (
        <>
            <div className="headerQr">
                <div className="logo">
                    <a href='https://www.esade.edu/' target="_blank">
                        <img src={logo} width="150px" alt="certiblock" />
                    </a>
                </div>
                <Row className="d-flex justify-content-end mt-2">
                    <Col xs="12" lg="2" className="flexCol d-flex justify-content-end" style={{ marginRight: "20px" }}>
                        <div className="SelectBusqueda">
                            <Form.Select className="select-css" style={{ width: "70px" }} onClick={handleidioma} aria-label="Default select example">
                                {
                                    arrLenguaje.map((item, index) => (
                                        <option key={index} value={item.nombre} >{item.nombre}</option>
                                    ))
                                }
                            </Form.Select>
                        </div>
                    </Col>
                </Row>
                <div className="header_bar_clip"></div>
            </div>

            {
                status === "DELETED" && (
                    <div style={{ width: "100%", height: "100vh" }}></div>
                )
            }

            {
                status != "DELETED" && (
                    <Container>

                        <Row style={{ marginTop: "40px" }}>
                            <h1 className="text-center colorEsade fontW700 mbottom">{programName}</h1>
                            <div className="text-center flexi mt-2">
                                <hr className="dividerQR"></hr><hr className="dividerQR"></hr>
                            </div>
                            <h1 className="text-center colorEsade  text-muted mt-4">{nombre}</h1>
                        </Row>

                        <Row className="row justify-content-around" >
                            <Col xs={12} lg={12} className="container-father-certi" >

                                <Document
                                    file={urlPdf}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    <Page pageNumber={pageNumber} />

                                </Document>

                            </Col>
                        </Row>
                        <Row >

                            <Col xs={12} lg={12}>
                                <h5 className="text-start colorEsade">
                                    <FormattedMessage
                                        id="app.fecha"
                                        defaultMessage="Fecha de Emisión"
                                    />
                                    <span> &nbsp; {fecha}</span>
                                </h5>
                            </Col>

                            <Col xs={12} lg={12} style={{ marginTop: "3%" }}>
                                <p className="colorEsade font20">
                                    <FormattedMessage
                                        id="app.certitext"
                                        defaultMessage="El certificado de tu programa académico en Esade se registra con blockchain en la red Polygon para que sea auténtico, inalterable y público."
                                    />

                                </p>
                            </Col>
                        </Row>

                        <Row className='row-btns'>
                            <Col lg="3" className="btn-column">
                                <Row className='div-info-btns'>
                                    <div className='info-logo-btns'>
                                        <img src={linkedInLogo} style={{ width: "50px" }} alt="linkedInLogo" />
                                    </div>
                                    <Col lg="9" className='info-text-btns'>
                                        <p>
                                            <span style={{ fontWeight: "900"}}><FormattedMessage
                                                id="app.linkedInTitle"
                                                defaultMessage="Verificar credencial"
                                            /></span><br></br>
                                            <FormattedMessage
                                                id="app.linkedInTxt"
                                                defaultMessage="Verificar credencial"
                                            />
                                        </p>
                                    </Col>
                                </Row>
                                <Button className="btn" onClick={shareDoc}>
                                    <FormattedMessage
                                        id="app.linkedInBtn"
                                        defaultMessage="Verificar credencial"
                                    />
                                    
                                </Button>
                            </Col>
                            <Col lg="3" className="btn-column">
                            <Row className='div-info-btns'>
                                    <div className='info-logo-btns'>
                                        <img src={downloadLogo} style={{ width: "50px" }} alt="downloadLogo" />
                                    </div>
                                    <Col lg="9" className='info-text-btns'>
                                        <p>
                                            <span style={{ fontWeight: "900"}}><FormattedMessage
                                                id="app.shareTitle"
                                                defaultMessage="Verificar credencial"
                                            /></span><br></br>
                                            <FormattedMessage
                                                id="app.shareTxt"
                                                defaultMessage="Verificar credencial"
                                            />
                                        </p>
                                    </Col>
                                </Row>
                                <Button className="btn" onClick={openDoc}>
                                    <FormattedMessage
                                        id="app.shareBtn"
                                        defaultMessage="Verificar credencial"
                                    />
                                </Button>
                            </Col>
                            <Col lg="3" className="btn-column">
                            <Row className='div-info-btns'>
                                    <div className='info-logo-btns'>
                                        <img src={bitcoinIcon} style={{ width: "50px" }} alt="bitcoinIcon" onClick={openDoc} />
                                    </div>
                                    <Col lg="9" className='info-text-btns'>
                                        <p>
                                            <span style={{ fontWeight: "900"}}><FormattedMessage
                                                id="app.bitcoinTitle"
                                                defaultMessage="Verificar credencial"
                                            /></span><br></br>
                                           <FormattedMessage
                                                id="app.bitcoinTxt"
                                                defaultMessage="Verificar credencial"
                                            />
                                        </p>
                                    </Col>
                                </Row>
                                <Button className="btn" onClick={openTokenUrl}>
                                    <FormattedMessage
                                        id="app.bitcoinBtn"
                                        defaultMessage="Verificar credencial"
                                    />
                                </Button>
                            </Col>
                            <div className="QR-column">
                                <Row className='div-info-btns'>
                                    <Col lg="12" className='info-text-btns'>
                                        <p>
                                            <span style={{ fontWeight: "900"}}><FormattedMessage
                                                id="app.QRTitle"
                                                defaultMessage="Verificar credencial"
                                            /></span><br></br>
                                            <FormattedMessage
                                                id="app.QRTxt"
                                                defaultMessage="Verificar credencial"
                                            />
                                        </p>
                                    </Col>
                                </Row>
                                <div className='QR-div'>
                                    <img src={src} alt="qr" onClick={downloadQR}/>
                                </div>
                            </div>
                        </Row>



                        <Row className="containerInfo mt-5">

                            <Col xs={12} lg={12} className="flexi mtResponsive">
                                <Row>
                                    <Col lg={9} xs={7} style={{ display: "flex", alignItems: "center" }}>
                                        <p className="font20" style={{ marginBottom: "0px", fontWeight: "300", color: "red", fontSize: ".1rem" }}>
                                            <FormattedMessage
                                                id="app.info"
                                                defaultMessage="¿Cómo validar de forma independiente que tu certificado ha sido registrado en blockchain?"
                                            />

                                        </p>
                                    </Col>
                                    <Col lg={3} xs={5} className="flexi">
                                       {/* <Button className="button-chevron" onClick={() => setOpen(!open)}
                                        >
                                            {open === false &&
                                                (
                                                    <>
                                                        <img src={chevronRight} width="90px" />
                                                    </>
                                                )
                                            }
                                            {open === true &&
                                                (
                                                    <>
                                                        <img src={chevronDown} width="90px" />
                                                    </>
                                                )
                                            }
                                        </Button>*/}
                                        <p style={{color: "red", marginBottom: "0px", fontWeight: "900", fontSize: "2.5rem", cursor: "pointer"}} onClick={() => setOpen(!open)}>&#x2192;</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>



                        <Collapse in={open}>
                            <Row className="pcontainer">
                                <Row className="p60">
                                    <h5 className="letraPrimary text-center">
                                        <FormattedMessage
                                            id="app.titleBlock"
                                            defaultMessage="¿COMO VALIDAR QUE TU CERTIFICADO HA SIDO REGISTRADO EN BLOCKCHAIN?"
                                        />
                                    </h5>

                                    <Row style={{ marginTop: "1%", marginBottom: "20px" }}>
                                        <Col lg="12" className="d-flex justify-content-center">
                                            <div className="numberBlock">
                                                <p className="textNumber">1</p>
                                            </div>
                                        </Col>
                                        <Col lg="12" className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
                                            <p className="blackText font17 mbottom text-center">
                                                <FormattedMessage
                                                    id="app.1"
                                                    defaultMessage="Entra al NFT - verificación de credenciales. Copia o apúntate el número de tokenID."
                                                />
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row style={{ marginTop: "1%", marginBottom: "20px" }}>
                                        <Col lg="12" className="d-flex justify-content-center">
                                            <div className="numberBlock">
                                                <p className="textNumber">2</p>
                                            </div>
                                        </Col>
                                        <Col lg="12" className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
                                            <p className="blackText font17 mbottom text-center">
                                                <FormattedMessage
                                                    id="app.2"
                                                    defaultMessage="Entra a leer el contrato."
                                                />
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row style={{ marginTop: "1%", marginBottom: "20px" }}>
                                        <Col lg="12" className="d-flex justify-content-center">
                                            <div className="numberBlock">
                                                <p className="textNumber">3</p>
                                            </div>
                                        </Col>
                                        <Col lg="12" className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
                                            <p className="blackText font17 mbottom text-center">
                                                <FormattedMessage
                                                    id="app.3"
                                                    defaultMessage="En el apartado de tokenURI pega el número de tokenID."
                                                />
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row style={{ marginTop: "1%", marginBottom: "20px" }}>
                                        <Col lg="12" className="d-flex justify-content-center">
                                            <div className="numberBlock">
                                                <p className="textNumber">4</p>
                                            </div>
                                        </Col>
                                        <Col lg="12" className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
                                            <p className="blackText font17 mbottom text-center">
                                                <FormattedMessage
                                                    id="app.4"
                                                    defaultMessage="Click a *query*."
                                                />
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row style={{ marginTop: "1%", marginBottom: "20px" }}>
                                        <Col lg="12" className="d-flex justify-content-center">
                                            <div className="numberBlock">
                                                <p className="textNumber">5</p>
                                            </div>
                                        </Col>
                                        <Col lg="12" className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
                                            <p className="blackText font17 mbottom text-center">
                                                <FormattedMessage
                                                    id="app.5"
                                                    defaultMessage="Copia la url que aparecera debajo de query."
                                                />
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row style={{ marginTop: "1%", marginBottom: "20px" }}>
                                        <Col lg="12" className="d-flex justify-content-center">
                                            <div className="numberBlock">
                                                <p className="textNumber">6</p>
                                            </div>
                                        </Col>
                                        <Col lg="12" className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
                                            <p className="blackText font17 mbottom text-center">
                                                <FormattedMessage
                                                    id="app.6"
                                                    defaultMessage="Engancha la url en el navegador y verás un json con todos los datos asociados a tu certificado, entre ellos podrás encontrar el dato *image*."
                                                />
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row style={{ marginTop: "1%", marginBottom: "20px" }}>
                                        <Col lg="12" className="d-flex justify-content-center">
                                            <div className="numberBlock">
                                                <p className="textNumber">7</p>
                                            </div>
                                        </Col>
                                        <Col lg="12" className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
                                            <p className="blackText font17 mbottom  text-center">
                                                <FormattedMessage
                                                    id="app.7"
                                                    defaultMessage="Copia la url que hay detrás de *image* y pégala a tu navegador."
                                                />
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row style={{ marginTop: "1%" }}>
                                        <Col lg="12" className="d-flex justify-content-center">
                                            <div className="numberBlock">
                                                <p className="textNumber">8</p>
                                            </div>
                                        </Col>
                                        <Col lg="12" className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
                                            <p className="blackText font17 mbottom text-center">
                                                <FormattedMessage
                                                    id="app.8"
                                                    defaultMessage="Podrás ver tu certificado PDF registrado en el Inter Planetary File System (IPFS)."
                                                />
                                            </p>
                                        </Col>
                                    </Row>
                                </Row>

                                <div className="infoBlock">
                                    <p className="mbottom infoTextBlock">
                                        <FormattedMessage
                                            id="app.warning"
                                            defaultMessage="Una vez realizada estos pasos podrás verificar que tu certificado ha sido registrado como NFT en tecnología Blockchain y que es propiedad de esade.
                                    Si lo deseas en un futuro tendrás la opción de transferirlo a tu wallet personal!."
                                        />


                                    </p>
                                </div>

                            </Row>


                        </Collapse>
                    </Container>
                )
            }

            <Modal
                show={dateModal}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header className="d-flex justify-content-center">
                    <div className="d-flex justify-content-center">
                        <img src={logoletra} alt="checkCerti" width={150} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg="12">
                            {
                                spinner === true && (
                                    <>
                                        <div className="flexi">
                                            <Spinner animation="border" className="spinner1" role="status">

                                            </Spinner>
                                            <Spinner animation="border" className="spinner2" role="status">

                                            </Spinner>
                                            <Spinner animation="border" className="spinner3" role="status">

                                            </Spinner>
                                            &nbsp;&nbsp;<span style={{ fontSize: "17px !important" }}>
                                                <FormattedMessage
                                                    id="app.data"
                                                    defaultMessage="Cargando información.."
                                                /></span>
                                        </div>
                                    </>
                                )
                            }
                            {
                                spinner === false && (
                                    <>

                                        <Row>
                                            <Col xs="2" lg="2"  className="d-flex align-items-center">
                                                <img src={checkCerti} alt="checkCerti" width={30} />
                                            </Col>
                                            <Col xs="10" lg="10" className="d-flex align-items-center">
                                                <p className="mbottom font17">
                                                    <FormattedMessage
                                                        id="app.modal1"
                                                        defaultMessage="Esta credencial está VERIFICADA"
                                                    />
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="2" lg="2" className="d-flex align-items-center">
                                                <img src={checkCerti} alt="checkCerti" width={30} />
                                            </Col>
                                            <Col xs="10" lg="10" className="d-flex align-items-center">
                                                <p className="mbottom font17">
                                                    <FormattedMessage
                                                        id="app.modal2"
                                                        defaultMessage="El propietario de está credencial está verificado"
                                                    />
                                                </p>
                                            </Col>
                                        </Row>

                                        <Row className="borderRow" style={{ marginTop: "3%" }}>
                                            <Col xs="6" lg="6" className="d-flex align-items-center justify-content-center">
                                                <p className="mbottom font17">
                                                    <FormattedMessage
                                                        id="app.modal3"
                                                        defaultMessage="EMITIDA EN"
                                                    />
                                                </p>
                                            </Col>
                                            <Col xs="6" lg="6" className="d-flex align-items-center justify-content-center">
                                                <p className="mbottom font17">
                                                    <FormattedMessage
                                                        id="app.modal4"
                                                        defaultMessage="PRESCRIBE EL"
                                                    />
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="6" lg="6" className="d-flex align-items-center justify-content-center">
                                                <span>{fecha}</span>
                                            </Col>
                                            <Col xs="6" lg="6" className="d-flex align-items-center justify-content-center">
                                                <p className="mbottom font17">
                                                    <FormattedMessage
                                                        id="app.modal5"
                                                        defaultMessage="No Caduca"
                                                    />
                                                </p>
                                            </Col>
                                        </Row>

                                        <Row className="borderRow">
                                            <Col xs="12" lg="12" className="d-flex align-items-center">
                                                <p className="mbottom font17">
                                                    <FormattedMessage
                                                        id="app.modal6"
                                                        defaultMessage="HISTORIAL"
                                                    />
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row style={{ padding: "0", marginTop: "2%" }}>
                                            <Col xs="4" lg="4">
                                                <p className="mbottom font17 d-flex align-items-center"><span>{fecha}</span>
                                                </p>
                                            </Col>
                                            <Col xs="8" lg="8">
                                                <FormattedMessage
                                                    id="app.modal7"
                                                    defaultMessage="Credencial emitida y Registrado en blockchain"
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )
                            }
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>


                    <Button variant="secondary" onClick={closeDateModal}>
                        <FormattedMessage
                            id="app.modal8"
                            defaultMessage="Cerrar"
                        />
                    </Button>


                </Modal.Footer>
            </Modal>


            <div className="text-center mt-5">
                <div className="footer-good">
                    <Row style={{ display: "flex", alignItems: "end" }}>
                        <Col xs={12} lg={12}>

                            <img src={FooterImg} className="footerLandingImg" />

                        </Col>
                    </Row>
                </div>
                <div className="footer-copy">
                    <Row className="noPadding">
                        <Col xs={12} lg={4}>
                            <span className="fontFooter_blockchain" style={{ marginBottom: "0px" }}>Tecnología blockchain by CertiBlock
                            </span>
                        </Col>
                        <Col xs={12} lg={4}>
                            <span>© 2022 Esade Ramon Llull University</span>
                        </Col>
                        <Col xs={12} lg={4}>

                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}