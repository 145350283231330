import { useEffect, useState } from "react";
import "./App.css";

import { DocsGuard, LoginGuard, AdminGuard } from "./Pages/404/AuthGuard";

// QR Landing
import Landing from "./Pages/QrLanding/Landing";

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import NoRoute from "./Pages/404/NoRoute";



function App() {






  return <div className="App">

    <Routes>
      <Route path="/" element={<LoginGuard />} />
      <Route path="/landing/:ids" element={<Landing />} />
      <Route path="/login" element={<LoginGuard />} />

      <Route path="/documentacion" element={<DocsGuard />} />
      <Route path="/actividad" element={<DocsGuard />} />
      <Route path="/usuario" element={<DocsGuard />} />

      <Route path="/clientes" element={<AdminGuard />} />
      <Route path="/consumo" element={<AdminGuard />} />
      <Route path="/actividadadm" element={<AdminGuard />} />
      <Route path="/facturacion" element={<AdminGuard />} />

      <Route path="*" element={<NoRoute />} />

    </Routes>


  </div>
}

export default App;