import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import logo from "../../assets/logo/LogoCerti.png";
import docwhite from "../../assets/docwhite.svg";
import actividadwhite from "../../assets/actividadwhite.svg";
import docacti from "../../assets/docacti.svg";
import userwhite from "../../assets/userwhite.svg";
import menuIcon from "../../assets/ic_menu.svg";
import { Row, Col} from 'react-bootstrap';

import './Sidemenu.css'
import './asideMenu.css'

const menuItems = [
  {
    name: "Clientes", exact: true, to: '/clientes', iconClassNameWhite: userwhite,
  },
  {
    name: "Consumo", exact: true, to: '/consumo', iconClassNameWhite: actividadwhite,
  },
  {
    name: "Actividad", exact: true, to: '/actividadadm', iconClassNameWhite: docacti,
  },
  {
    name: "Facturación", exact: true, to: '/facturacion', iconClassNameWhite: docwhite,
  },
]

const SideMenuAdmin = (props) => {
  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
  const [inactive, setInative] = useState(sidebarCollapsed ? false : true);


  useEffect(() => {
    props.onCollapse(inactive);
  }, [inactive]);

  const handleToggler = () => {
    if (inactive) {
      setInative(false);
      localStorage.setItem('sidebar-collapsed', true);
      return;
    }
    setInative(true);
    localStorage.removeItem('sidebar-collapsed');
  };



  return (
    <div
      id='kt_aside'
      className={`aside py-9 pt-4 d-none d-sm-block side-menu ${inactive ? "inactive" : "side-menu.inactive"}`}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_toggle'
    >
      <div onClick={handleToggler}
        className="toggle-menu-btn">
        {inactive ? (
          <img className="menu-icon-css" src={menuIcon} alt="menuIcon" />
        ) : (
          <img className="menu-icon-css" src={menuIcon} alt="menuIcon" />
        )}
      </div>
      <div className={`aside-logo flex-column-auto px-9 mb-9 ${inactive ? "inactive" : ""}`}>
        <img className="logo-css-certi" src={logo} alt="certiblock" />
      </div>
      <div className={`aside-menu flex-column-fluid  mb-9  ${inactive ? "inactive" : ""}`}>
        <ul style={{ width: "100%" }}>
          {
            menuItems.map(item => (
              <div className='menu-item flex-column-auto px-9 mb-9' id='kt_aside_logo'>
                <li className="padding-li" onClick={props.onClick}>
                  <NavLink
                    exact
                    to={item.to}
                    className={`menu-item`}
                  >

                    <div className="menu-icon-new">
                      <img className="share-icon2" src={item.iconClassNameWhite} alt="share" />
                    </div>

                    <span>{item.name}</span>
                  </NavLink>
                </li>
              </div>
            ))
          }
          <div
            className={`menu-content ${inactive ? "inactive" : ""}`}
          >
          
          </div>
        </ul>

      </div>
      <div className="side-menu-footer">
        <div className="user-info">
          <Row>
            <Col lg="12">
              <h5 style={{fontSize: "12px"}}>Certiblock Portal Admin Dashboard</h5>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <p style={{fontSize: "10px"}}>ⓒ Certiblock v1.0.1</p>
            </Col>
          </Row>
        </div>
      </div>

    </div>
  );
};

export default SideMenuAdmin