import react, { useState, useEffect } from "react";
import { Table } from 'antd';
import { Row, Col, Form, Button } from 'react-bootstrap';
import moment from 'moment'
import circleupload from "../../assets/circleupload.svg";
import { useNavigate } from 'react-router-dom';

// components
import NavbarHeader from "../../Components/Navbar/NavbarHeader";
// services
import axios from "axios";
import * as XLSX from 'xlsx';

import '../../Components/Navbar/NavbarHeader.css';
// Css
import "../Documentation/ListDocu.css"

import SideMenu from '../../Components/SideBarUser/SideMenu';


export default function Actividad() {
    const [dataSource, setDataSource] = useState(null);
    const [value, setValue] = useState('');
    const [selectActividad, setSelectActividad] = useState([])
    const [filterTable, setFilterTable] = useState(null);
    const [showSpinnerData, setShowSpinnerData] = useState(true);
    const [actividadesList, setActividadesList] = useState(null);
    const [dataexcel, setDataexcel] = useState([]);
    const [activy, setActivy] = useState('')
    const [datefilter, setDatefilter] = useState('');
    const [dateoriginal, setDateoriginal] = useState('');
    const [dataOriginal, setDataOriginal] = useState(null);
    const [nombreActividad, setNombreActividad] = useState(null);
    const [cantActividades, setCantActividades] = useState(0);
    const [cantActividadesBySearch, setCantActividadesBySearch] = useState(0);
    const [page, setPage] = useState(1);

    const [inactive, setInative] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {
        callData()
    }, []);


    useEffect(() => {
        setPage(1);
        callData();
    }, [activy, datefilter, dateoriginal]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            callData();
        }, 300)

        return () => clearTimeout(delayDebounceFn)
    }, [nombreActividad])

    useEffect(() => { callData() }, [page]);

    const user = JSON.parse(localStorage.getItem('user-info'));

    const columns = [
        {
            title: 'Fecha',
            //dataIndex: 'fechaTransform',
            key: 'fechaTransform',
            width: '10%',
            //render: text => <p style={{ textAlign: "center" }}>{text}</p>
            render: (record) => {
                return (
                    <>
                        <p style={{ textAlign: "center", marginBottom: "0px" }}>{record.fecha.replace('-', '/').split('T')[0].replace('-', '/')}</p>
                        <p style={{ textAlign: "center", marginBottom: "0px" }}>{record.timeDate}</p>
                    </>
                );
            },

        },
        {
            title: (<><p className="text-start" style={{ marginBottom: "0px" }}>Actividad</p></>),
            dataIndex: 'actividad',
            key: 'actividad',
            width: '20%',
            render: text => <div style={{ textAlign: "start" }}><p className="breakText2">{text}</p></div>
        },
        {
            title: (<><p className="text-start" style={{ marginBottom: "0px" }}>Detalles</p></>),
            dataIndex: 'detalles',
            key: 'detalles',
            width: '30%',
            render: text => <div style={{ textAlign: "start" }}><p className="breakText2">{text}</p></div>
        },
        {
            title: (<><p className="text-start" style={{ marginBottom: "0px" }}>Documento</p></>),
            dataIndex: 'infoDocumento',
            key: 'infoDocumento',
            render: text => <div style={{ textAlign: "start" }}><p className="breakTextDoc2">{text.nombre}</p></div>,
        },
        {
            title: 'Usuario',
            key: 'infoUsuario',
            dataIndex: 'infoUsuario',
            render: text => <p style={{ textAlign: "center", marginBottom: "0px !important" }}>{text.nombre}</p>
        },
    ];

    const callData = async () => {

        console.log("date filter: ", datefilter);
        console.log("date original: ", dateoriginal);

        let query = `${process.env.REACT_APP_URLSERVER}/v2/actividades?page=${page}&`;

        if(nombreActividad) query += `actividad=${nombreActividad}&`;
        if(datefilter) query += `fecha=${datefilter}&`;

        console.log(query);

        await axios.get(query, {
            responseType: 'json',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": user?.body?.jwt
            }
        })
        .then((res) => {
            let data = res.data.body;
            console.log(data);
            if(!query.includes('&actividad') && !query.includes('&fecha')){
                setCantActividadesBySearch(data.totalItems);
            }
            let actividades = data.actividades;
            setActividadesList(data.actividades.length)
            // Nuevo Arreglo Actividades
            actividades.map((item) => {
                let offset = moment(item.fecha).zone(item.fecha)
                item.fechaTransform = moment(moment(item.fecha)).format('DD/MM/YYYY');
                item.timeDate = offset.format("hh:mm a")
                item.infoDocumento = item.infoDocumento?? {nombre: ""}
                return data;
            })
            setDataOriginal(actividades);
            setDataSource(actividades);
            setFilterTable(actividades);
            setCantActividades(data.totalItems);
            setShowSpinnerData(false)
            // Arreglo Excel
            let actiArr = [];
            let dataExcel = []
            // ForEach Actividades
            actividades.forEach((item) => {
                // For Actividades
                const actividades = actiArr.find((el) => el.nombre == item.actividad);
                if (actividades === undefined) {
                    actiArr.push({
                        nombre: item.actividad
                    })
                }
                
                dataExcel.push(
                    {
                        Fecha: moment(moment(item.fecha)).format('DD/MM/YYYY'),
                        Hora: item.timeDate,
                        Actividad: item.actividad,
                        Detalles: item.detalles,
                        //Documento: item.infoDocumento.nombre,
                        Usuario: item.infoUsuario.nombre
                    },
                )
                setDataexcel(dataExcel)

            });
            setSelectActividad(actiArr)
        }).catch((error) => {
            if(error.response.status == 401){
                localStorage.removeItem("user-info")
                localStorage.removeItem("rol-user")
                localStorage.removeItem("token")
                navigate("/login");
            }
        });
        /*try {
            
        } catch (error) {
            console.log("error", error)
        }*/
    }

    const exportfile = () => {
        let dataExcel = []
        if (filterTable === null) {
            const workSheet = XLSX.utils.json_to_sheet(dataexcel)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, "actividad")
            //Binary string
            XLSX.write(workBook, { bookType: "xlsx", type: "array" })
            //Download
            XLSX.writeFile(workBook, "actividadData.xlsx")
        } else {
            filterTable.map((item) => {
                dataExcel.push(
                    {
                        Fecha: moment(moment(item.fecha)).format('DD/MM/YYYY'),
                        Hora: item.timeDate,
                        Actividad: item.actividad,
                        Detalles: item.detalles,
                        Documento: item.infoDocumento.nombre,
                        Usuario: item.infoUsuario.nombre
                    },
                )

            })
            const workSheet = XLSX.utils.json_to_sheet(dataExcel)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, "actividad")
            //Binary string
            XLSX.write(workBook, { bookType: "xlsx", type: "array" })
            //Download
            XLSX.writeFile(workBook, "actividadData.xlsx")
        }

    }


    const handleActivy = (e) => {
        const currValue = e.target.value;
        setActivy(currValue);
        console.log('filtro activiadd nombre');
    }

    const handleFilterDate = (e) => {
        const currValueTransform = moment(moment(e.target.value)).format('MM-DD-YYYY');
        console.log('filtro activiadd fecha: ',e.target.value);
        setDateoriginal(e.target.value);

        if(e.target.value === ''){
            setDatefilter(null);
        }else{
            setDatefilter(currValueTransform);
        }
    }


    return (
        <>
            <SideMenu onCollapse={(inactive) => {
                setInative(inactive);
            }} />
            <div className={`containerTwo ${inactive ? "inactive" : ""}`}>
                <Row>
                    <Col lg="12" className="d-flex justify-content-center align-items-center">
                        <img className="logo-css  d-sm-none" src={`${process.env.REACT_APP_URLSERVER}/imagenes/logos/${user?.body?.user.logo}`} alt="certiblock" />
                    </Col>
                </Row>

                <NavbarHeader searchName={setNombreActividad} listUsers={cantActividadesBySearch} inputPlaceHolder={"Buscar actividad"} titlePage={"Actividad"} setValue={setValue} setFilterTable={setFilterTable} dataFilter={dataSource} inputValue={value} />

                <div className="containerTable">

                    <div className="table-responsive">
                        <Row style={{float: "right"}}>

                            {/* <Col xs="6" lg="2">
                                <div className="SelectBusqueda">
                                    <p className="title-filter">Tipo de Actividad</p>

                                    <Form.Select onChange={handleActivy} className="select-css" aria-label="Default select example">
                                        <option key="0" value="" >Mostrar todo</option>
                                        {
                                            selectActividad.map((actividad, index) => (
                                                <option key={index} value={actividad.nombre} >{actividad.nombre}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </div>
                            </Col> */}

                            <Col style={{width: 200}}>
                                <div className="SelectBusqueda">
                                    <p className="title-filter">Fecha de Carga</p>

                                    <Form.Group controlId="dob">
                                        <Form.Control onChange={handleFilterDate} className="select-css" type="date" value={dateoriginal} name="dob" placeholder="Date of Birth" />
                                    </Form.Group>
                                </div>
                            </Col>

                        </Row>
                        {
                            showSpinnerData === true && (
                                <>
                                    <div style={{ justifyContent: "center", height: "50vh" }} className="d-flex align-items-center">
                                        <strong>Cargando Data...</strong>
                                        <div className="spinner-borderData ml-auto" role="status" aria-hidden="true"></div>
                                    </div>
                                </>
                            )
                        }
                        {
                            showSpinnerData === false && (
                                
                                <>
                                    <Table columns={columns} scroll={{ x: 'max-content' }} dataSource={filterTable}
                                        pagination={{
                                            current: page,
                                            total: cantActividades,
                                            onChange: (page, pageSize) => {
                                                setPage(page);
                                            },
                                        }}
                                    />
                                </>
                            )
                        }

                        <Button className="uploadUser" onClick={exportfile}>Exportar resultados <img src={circleupload} alt="circleupload" /></Button>

                    </div>
                </div>
            </div>
        </>
    );
}