import react, { useState, useEffect } from "react";
import { Table } from 'antd';
import { Row, Col, Form, Button } from 'react-bootstrap';
import circleupload from "../../assets/circleupload.svg";
import moment from 'moment-timezone'
import axios from "axios";
import logo from "../../assets/logo/LogoCerti.png";

// Components
import '../../Components/Navbar/NavbarHeader.css';
import NavbarHeader from '../../Components/Navbar/NavbarHeader';
import SideMenuAdmin from '../../Components/SideBarAdmin/SideMenuAdmin';


// Services
import * as XLSX from 'xlsx';

// Css
import "../Documentation/ListDocu.css"






export default function ActividadAdmin() {
    const [dataSource, setDataSource] = useState(null);
    const [filterTable, setFilterTable] = useState(null);
    const [value, setValue] = useState('');
    const [dataexcel, setDataexcel] = useState([]);
    const [showData, setShowData] = useState(false);
    const [showSpinnerData, setShowSpinnerData] = useState(true);
    const [actividadesList, setActividadesList] = useState(null);
    const [inactive, setInative] = useState(false);
    const [activy, setActivy] = useState('')
    const [datefilter, setDatefilter] = useState('');
    const [dateoriginal, setDateoriginal] = useState('');
    // total Actividades
    const [totalActividades, setTotalactividades] = useState(1);

    // Arreglo
    const [actividadArr, setActividadArr] = useState([]);


    const user = JSON.parse(localStorage.getItem('user-info'));


    const columns = [
        {
            title: 'Fecha',
            //dataIndex: 'fechaformat',
            key: 'fechaformat',
            width: '10%',
            render: (record) => {
                return (
                    <>
                        <p style={{ textAlign: "center", marginBottom: "0px" }}>{record.fecha.replace('-', '/').split('T')[0].replace('-', '/')}</p>
                        <p style={{ textAlign: "center", marginBottom: "0px" }}>{record.timeDate}</p>
                    </>
                );
            },
        },
        {
            title: (<><p className="text-start" style={{ marginBottom: "0px" }}>Cliente</p></>),
            dataIndex: 'infoCliente',
            key: 'infoCliente',
            render: text => <div style={{ textAlign: "start" }}><p className="breakText2">{text.nombre}</p></div>
        },
        {
            title: (<><p className="text-start" style={{ marginBottom: "0px" }}>Actividad</p></>),
            dataIndex: 'actividad',
            key: 'actividad',
            width: '20%',
            render: text => <div style={{ textAlign: "start" }}><p className="breakText2">{text}</p></div>
        },
        {
            title: (<><p className="text-start" style={{ marginBottom: "0px" }}>Detalles</p></>),
            key: 'detalles',
            dataIndex: 'detalles',
            width: '30%',
            render: text => <div style={{ textAlign: "start" }}><p className="breakText2">{text}</p></div>
        },
        {
            title: (<><p className="text-start" style={{ marginBottom: "0px" }}>Documento</p></>),
            dataIndex: 'infoUsuario',
            key: 'infoUsuario',
            width: '10%',
            render: text => <div style={{ textAlign: "start" }}><p className="breakTextDoc2">{text.nombre == "" ? "Sin certificado." : text.nombre}</p></div>,
        },
        {
            title: 'Usuario',
            dataIndex: 'infoUsuario',
            key: 'infoUsuario',
            render: text => <span>{text.nombre}</span>
        },
    ];


    useEffect(() => {
        callData(1, 2000)
    }, []);

    useEffect(() => {
        filtrar()
    }, [activy, datefilter, dateoriginal]);


    const callData = async (page, pageSize) => {

        await axios.get(`${process.env.REACT_APP_URLSERVER}/v2/admin/actividades?page=${page}&limit=${pageSize}`, {
            responseType: 'json',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": user?.body?.jwt
            }

        })
            .then((res) => {


                console.log("data", res)
                let data = res.data.body.actividades
                console.log("Data Body", data)
                setTotalactividades(res.data.body.totalItems)
                setActividadesList(res.data.body.totalItems)
                setDataSource(data)
                setFilterTable(data)
                setShowData(true)
                setShowSpinnerData(false)
                // Nuevo Arreglo Actividades
                let actiArr = [];
                // Arreglo Excel
                let dataExcel = []
                // ForEach Actividades
                data.forEach((item) => {
                    // For Actividades
                    const actividades = actiArr.find((el) => el.nombre == item.actividad);
                    if (actividades === undefined) {
                        actiArr.push({
                            nombre: item.actividad
                        })
                    }
                    let offset = moment(item.fecha).zone(item.fecha)
                    item.fechaformat = moment(moment(item.fecha)).format('DD/MM/YYYY')
                    item.timeDate = offset.format("hh:mm a")
                    //item.timeDate = moment(moment(item.fecha)).format("LTS")

                    dataExcel.push(
                        {
                            fecha: moment(moment(item.fecha)).format('DD/MM/YYYY'),
                            cliente: item.infoCliente.nombre,
                            actividad: item.actividad,
                            detalles: item.detalles,
                            //documento: item.infoDocumento.nombre === '' ? "No Existe" : item.infoDocumento.nombre,
                            usuario: item.infoUsuario.nombre
                        },
                    )
                    setDataexcel(dataExcel)


                });
                setActividadArr(actiArr)
            })
    }




    const handleActivy = (e) => {
        const currValue = e.target.value;
        setActivy(currValue)

    }

    const handleFilterDate = (e) => {
        const currValueTransform = moment(moment(e.target.value)).format('DD/MM/YYYY');
        const dateOriginal = e.target.value
        const currValue = currValueTransform;
        setDateoriginal(dateOriginal)
        setDatefilter(currValue)
    }

    const filtrar = () => {
        let listaFiltrada = dataSource;
        if (activy) {
            listaFiltrada = listaFiltrada.filter((el) => el.actividad === activy)
        }
        if (datefilter) {
            listaFiltrada = listaFiltrada.filter((el) => el.fechaformat === datefilter)
        }
        if (datefilter === "Invalid date") {
            setDatefilter('')
            return setFilterTable(null)
        }

        setFilterTable(listaFiltrada)

    }

    const exportfile = () => {
        let dataExcel = []
        if (filterTable === null) {
            const workSheet = XLSX.utils.json_to_sheet(dataexcel)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, "actividad")
            //Binary string
            XLSX.write(workBook, { bookType: "xlsx", type: "array" })
            //Download
            XLSX.writeFile(workBook, "actividadData.xlsx")
        } else {
            filterTable.map((item) => {
                dataExcel.push(
                    {
                        Fecha: moment(moment(item.fecha)).format('DD/MM/YYYY'),
                        Cliente: item.infoCliente.nombre,
                        Hora: item.timeDate,
                        Actividad: item.actividad,
                        Detalles: item.detalles,
                        Documento: item.infoDocumento ? item.infoDocumento.nombre : "Sin certificado.",
                        Usuario: item.infoUsuario.nombre
                    },
                )

            })
            const workSheet = XLSX.utils.json_to_sheet(dataExcel)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, "actividad")
            //Binary string
            XLSX.write(workBook, { bookType: "xlsx", type: "array" })
            //Download
            XLSX.writeFile(workBook, "actividadData.xlsx")
        }

    }

    return (
        <>
            <SideMenuAdmin onCollapse={(inactive) => {
                setInative(inactive);
            }} />
            <div className={`containerTwo ${inactive ? "inactive" : ""}`}>
                <img className="logo-css  d-sm-none" src={logo} alt="certiblock" />

                <NavbarHeader listUsers={actividadesList} idNav={"1"} inputPlaceHolder={"Buscar actividad"} titlePage={"Actividad"} setValue={setValue} setFilterTable={setFilterTable} dataFilter={dataSource} inputValue={value} />



                <div className="containerTable">

                    <div className="table-responsive">
                        <Row>

                            <Col lg="3" className="d-none d-sm-block">

                            </Col>

                            <Col lg="3" className="d-none d-sm-block">

                            </Col>

                            <Col xs="6" lg="3" style={{ display: "flex", justifyContent: "end" }}>
                                <div className="SelectBusqueda">
                                    <p className="title-filter">Tipo de Actividad</p>

                                    <Form.Select onChange={handleActivy} className="select-css" aria-label="Default select example">
                                        <option key="0" value="" >Todas las actividades</option>
                                        {
                                            actividadArr.map(item => (
                                                <option key={item.id} value={item.nombre} >{item.nombre}</option>
                                            ))
                                        }
                                    </Form.Select>

                                </div>
                            </Col>

                            <Col xs="6" lg="3" style={{ display: "flex", justifyContent: "end" }}>
                                <div className="SelectBusqueda">
                                    <p className="title-filter">Fecha</p>

                                    <Form.Group controlId="dob">
                                        <Form.Control onChange={handleFilterDate} className="select-css" type="date" value={dateoriginal} name="dob" placeholder="Date of Birth" />
                                    </Form.Group>

                                </div>
                            </Col>

                        </Row>
                        {
                            showSpinnerData === true && (
                                <>
                                    <div style={{ justifyContent: "center", height: "50vh" }} className="d-flex align-items-center">
                                        <strong>Cargando Data...</strong>
                                        <div className="spinner-borderData ml-auto" role="status" aria-hidden="true"></div>
                                    </div>
                                </>
                            )
                        }
                        {
                            showData === true && (
                                <>
                                    <Table columns={columns} scroll={{ x: 'max-content' }} dataSource={filterTable == null ? dataSource : filterTable}
                                        pagination={{
                                            total: totalActividades,
                                            onChange: (page, pageSize) => {
                                                callData(page, pageSize);
                                            },
                                        }}
                                    />

                                </>
                            )
                        }
                        <Button className="uploadUser" onClick={exportfile}>Exportar resultados <img src={circleupload} alt="circleupload" /></Button>

                    </div>
                </div>

            </div>
        </>
    );
}