import React, { useState, useEffect } from 'react';
import { Table, Input } from 'antd';

import { Row, Col, Button, Modal, ModalFooter } from 'react-bootstrap';
import '../../Components/Navbar/NavbarHeader.css';
import circleupload from "../../assets/circleupload.svg";

import closedicon from "../../assets/boton-x.png";
import { EditOutlined } from "@ant-design/icons";
import Spinner from 'react-bootstrap/Spinner'
import swal from 'sweetalert';

import axios from "axios";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import './css.css'
import moment from 'moment'

// Components
import SideMenu from '../../Components/SideBarUser/SideMenu';
import NavbarHeader from '../../Components/Navbar/NavbarHeader';





// Css
import "../Documentation/ListDocu.css"
import { useNavigate } from 'react-router-dom';




export default function Usuario() {
    const navigate = useNavigate();
    const [dataSource, setDataSource] = useState(null);
    const [filterTable, setFilterTable] = useState(null);
    const [value, setValue] = useState('');
    const [modalform, setModalform] = useState(false);
    const [nombre, setNombre] = useState("");
    const [mail, setMail] = useState("");
    const [password, setPassword] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [showOptions, setShowOptions] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showData, setShowData] = useState(false);
    const [showSpinnerData, setShowSpinnerData] = useState(true);
    const [usersList, setUsersList] = useState(null);
    const [resetModalUser, setResetModalUser] = useState(false);
    const [rollModal, setRollModal] = useState(false);
    const [roll, setRoll] = useState({});
    const [rollName, setRollName] = useState('')

    const [arrRols, setArrRols] = useState([])


    const [inactive, setInative] = useState(false);



    useEffect(() => {
        checkUser();
        callUsers();
    }, []);

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'nombre',
            key: "1",
            render: text => <p style={{ textAlign: "center", marginBottom: "0px" }}>{text}</p>
        },
        {
            title: 'Email',
            dataIndex: 'correo',
            key: "2",
            render: text => <p style={{ textAlign: "center", marginBottom: "0px" }}>{text}</p>
        },
        {
            title: 'Documentos',
            dataIndex: 'nroDocumentos',
            key: "3",
            render: text => <p style={{ textAlign: "center", marginBottom: "0px" }}>{text}</p>
        },
        {
            title: 'F.creación',
            dataIndex: 'fechaCreacion',
            key: "4",
            render: text => moment(moment(text)).format('DD/MM/YYYY')
        },
        {
            title: 'Último Acceso',
            key: "5",
            dataIndex: 'ultAcceso',
            render: text => moment(moment(text)).format('DD/MM/YYYY hh:mm a')
        },
        {
            title: 'Estado',
            key: "6",
            dataIndex: 'estado',
            render: estado => estado === true ? <p style={{ textAlign: "center", marginBottom: "0px" }}>Activo</p> : <p style={{ textAlign: "center", marginBottom: "0px" }}>Bloqueado</p>
        },
        {
            key: "7",
            title: "Opciones",
            render: (record) => {
                return (
                    <>
                        {showAdd === true && (
                            <>
                                <div style={{ textAlign: "center" }}>
                                    <EditOutlined style={{ color: "red", cursor: "pointer" }}
                                        onClick={() => {
                                            onEditUser(record);
                                        }}
                                    />
                                </div>
                            </>
                        )}
                        {showEdit === true && (
                            <>
                                <div style={{ textAlign: "center" }}>
                                    <EditOutlined style={{ color: "red", cursor: "pointer" }}
                                        onClick={() => {
                                            tryEdit();
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </>
                );
            },
        },
    ];

    const callUsers = () => {
        axios.get(`${process.env.REACT_APP_URLSERVER}/v2/usuarios`, {
            responseType: 'json',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": userLogin.body.jwt
            }

        }).then((response) => {
            let data = response.data.body.reverse()
            setUsersList(data.length)
            setDataSource(data)
            setShowData(true)
            setShowSpinnerData(false)
            let rollsArr = []

            data.forEach((item) => {

                const rolls = rollsArr.find((el) => el.value == item.rolEmpresa);

                if (rolls === undefined) {
                    rollsArr.push(
                        {
                            value: item.rolEmpresa
                        }
                    )
                }
            })
            setArrRols(rollsArr)
        }).catch((error) => {
            if(error.response.status == 401){
                localStorage.removeItem("user-info")
                localStorage.removeItem("rol-user")
                localStorage.removeItem("token")
                navigate("/login");
            }
        });
    }

    const tryEdit = () => {
        swal({
            title: "No tienes permisos para Editar Usuarios!",
            icon: "warning",
            button: "Aceptar"
        });
    }

    const handleModal = () => {
        setModalform(true)
    }

    const handleClosedModal = () => {
        setModalform(false)
        resetEditing()
    }

    const saveRoll = () => {
        const newRoll = {
            value: rollName,
        }
        setArrRols([...arrRols, newRoll])
        setRollModal(false)
    }


    const onEditUser = (record) => {
        setIsEditing(true);
        setEditingUser({ ...record });
    };
    const resetEditing = () => {
        setIsEditing(false);
        setEditingUser(null);
    };


    const userLogin = JSON.parse(localStorage.getItem('user-info'));

    const checkUser = () => {
        if (userLogin.body.user.rol === 'USER_ROL') {
            setShowEdit(true)
        } else if (userLogin.body.user.plan.nombre === 'Pack TEST') {
            setShowAdd(true)
        } else if (userLogin.body.user.rol === 'CLIENT_ROL') {
            setShowAdd(true)
        }
        else {
            setShowEdit(false)
        }
    }


    const addUser = async () => {
        setShowOptions(false)
        setShowSpinner(true)
        let formData = {
            "user": {
                "nombre": nombre,
                "correo": mail.toLowerCase(),
                "password": password,
                "rolEmpresa": "usuario"

            }
        };
        try {
            let result = await axios.post(`${process.env.REACT_APP_URLSERVER}/v2/auth/signin/usuario`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": userLogin.body.jwt

                },
            });

            //result = await result.json();
            if (result.status === 200) {
                swal({
                    title: "Usuario creado exitosamente!!",
                    icon: "success",
                    button: "Aceptar"
                });
                callUsers();
                setModalform(false)
                setIsEditing(false);
                setEditingUser(null);
                setRoll('')
                setShowOptions(true)
                setShowSpinner(false)
            }

        } catch (error) {

            swal({
                title: error.response.data.body,
                icon: "warning",
                button: "Aceptar"
            });
            setShowOptions(true)
            setShowSpinner(false)
        }
    }

    const handleRoll = (event) => {
        setRoll(event.value)
    }
    const changeStatus = (checked) => {

        let DataUpdate = {
            "idActualizar": editingUser?._id,
            "estado": editingUser?.estado
        }
        try {
            axios.put(`${process.env.REACT_APP_URLSERVER}/v2/usuarios/estado`, DataUpdate, {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": userLogin.body.jwt
                }
            })
                .then(res => {

                })
        } catch (e) {
            console.log(e)
        }
    }

    const changeProfile = () => {
        setShowOptions(false)
        setShowSpinner(true)
        let DataUpdateProfile = {
            "nombre": editingUser?.nombre,
            "password": editingUser?.password,
            "estado": editingUser?.estado,
            "idActualizar": editingUser?._id,
            "rolEmpresa": "usuario"
        }
        try {
            const result = axios.put(`${process.env.REACT_APP_URLSERVER}/v2/usuarios/usuario`, DataUpdateProfile, {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": userLogin.body.jwt
                }
            })
            setShowOptions(true)
            setShowSpinner(false)
            swal({
                title: "Usuario actualizado!",
                icon: "success",
                button: "Aceptar"
            });
            callUsers()
            setModalform(false)
            setIsEditing(false);
            setEditingUser(null);

        } catch (e) {
            console.log(e)
        }
    }




    return (
        <>
            {<SideMenu onCollapse={(inactive) => {
                setInative(inactive);
            }} />}
            <div className={`containerTwo ${inactive ? "inactive" : ""}`}>
                <Row>
                    <Col lg="12" className="d-flex justify-content-center align-items-center">
                        <img className="logo-css  d-sm-none" src={`${process.env.REACT_APP_URLSERVER}/imagenes/logos/${userLogin.body.user.logo}`} alt="certiblock" />
                    </Col>
                </Row>

                <NavbarHeader listUsers={usersList} inputPlaceHolder={"Buscar usuario"} titlePage={"Usuarios"} setValue={setValue} setFilterTable={setFilterTable} dataFilter={dataSource} inputValue={value} />



                <div className="containerTable">
                    <div className="table-responsive">
                        {
                            showSpinnerData === true && (
                                <>
                                    <div style={{ justifyContent: "center", height: "50vh" }} className="d-flex align-items-center">
                                        <strong>Cargando Data...</strong>
                                        <div className="spinner-borderData ml-auto" role="status" aria-hidden="true"></div>
                                    </div>
                                </>
                            )
                        }
                        {
                            showData === true && (
                                <>
                                    <Table columns={columns} scroll={{ x: 'max-content' }} pagination={{ pageSize: 6 }} dataSource={filterTable == null ? dataSource : filterTable} />
                                    {showAdd === true && (
                                        <>
                                            <Button className="uploadUser" style={{ marginLeft: "20px" }} onClick={handleModal}>Crear nuevo usuario <img src={circleupload} alt="circleupload" /></Button>
                                        </>
                                    )}
                                </>
                            )
                        }


                    </div>
                </div>
                <Modal size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered style={{ padding: "40px" }}
                    show={isEditing}
                >
                    <div className="Content">
                        <form>

                            <Modal.Header>
                                <p className="modal-title" style={{ fontSize: "24px" }}>Edición de la cuenta de usuario</p>
                                <Button className="out-css-header" onClick={handleClosedModal} ><img className="closed-css-modal" src={closedicon} alt="closedicon" />
                                </Button>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col lg="6">
                                        <div className="form-group">
                                            <label className="title-filter-modal">Nombre</label>
                                            <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => {
                                                setEditingUser((pre) => {
                                                    return { ...pre, nombre: e.target.value };
                                                });
                                            }} value={editingUser?.nombre} />
                                            {!editingUser?.nombre && (
                                                <>
                                                    <span className="text-validate">No se pueden enviar campos Vacios</span>
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="form-group">
                                            <label className="title-filter-modal">Correo Electrónico</label>
                                            <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => {
                                                setEditingUser((pre) => {
                                                    return { ...pre, correo: e.target.value };
                                                });
                                            }} value={editingUser?.correo} disabled />
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "5%" }}>
                                    <Col lg="6">
                                        <div className="form-group">
                                            <label className="title-filter-modal">Contraseña</label>
                                            <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => {
                                                setEditingUser((pre) => {
                                                    return { ...pre, password: e.target.value };
                                                });
                                            }} value={editingUser?.password} />
                                        </div>
                                    </Col>
                                    <Col lg="6" style={{ alignSelf: "center" }}>
                                        <label>Estado</label>
                                        <div className="form-group" style={{ paddingTop: "5px" }}>
                                            <BootstrapSwitchButton
                                                checked={editingUser?.estado === true ? true : false}
                                                onlabel='Activo'
                                                offlabel='Bloqueado'
                                                offstyle="danger"
                                                width={100}
                                                onChange={(e) => {
                                                    setEditingUser((pre) => {
                                                        return { ...pre, estado: e }
                                                    })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                { /*<Row>
                                    <Col lg="6" style={{ alignSelf: "center" }}>

                                        <div className="form-group">
                                            <label className="title-filter-modal">Rol Actual</label>
                                            <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} 
                                                 value={editingUser?.rolEmpresa} disabled />
                                        </div>
                                    </Col>
                                    <Col lg="6" style={{ alignSelf: "center" }}>

                                        <div className="SelectBusqueda">
                                            <label className="title-filter-modal">Cambiar Rol*</label>
                                            <Select
                                                options={arrRols}
                                                defaultValue={editingUser?.rolEmpresa}
                                                onChange={(e) => {
                                                    setEditingUser((pre) => {
                                                        console.log("Evento", e.value)
                                                        return { ...pre, rolEmpresa: e.value }
                                                    });
                                                }}
                                                getOptionLabel={(arrRols) => arrRols.value}
                                                getOptionValue={(arrRols) => arrRols.value}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                            */}

                            </Modal.Body>

                            <ModalFooter>
                                {showSpinner === true && (
                                    <>
                                        <Spinner style={{ marginTop: "35px" }} animation="border" variant="primary" />
                                    </>
                                )}
                                {showOptions === true && (
                                    <>
                                        <Button className="out-modal" onClick={handleClosedModal} >
                                            Salir
                                        </Button>

                                        <Button className="uploadLogotipo" disabled={!editingUser?.nombre} onClick={changeProfile}>
                                            Guardar cambios
                                        </Button>
                                    </>
                                )}
                            </ModalFooter>
                        </form>

                    </div>
                </Modal>




                <Modal size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered style={{ padding: "40px" }}
                    show={modalform}
                >
                    <div className="Content">
                        <form>

                            <Modal.Header>
                                <p className="modal-title" style={{ fontSize: "24px" }}>Creación de Usuario</p>
                                <Button className="out-css-header" onClick={handleClosedModal} ><img className="closed-css-modal" src={closedicon} alt="closedicon" />
                                </Button>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col lg="6">
                                        <div className="form-group">
                                            <label className="title-filter-modal">Nombre</label>
                                            <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => setNombre(e.target.value)} />
                                            {!nombre && (
                                                <>
                                                    <span className="text-validate">Dato requerido</span>
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="form-group">
                                            <label className="title-filter-modal">Correo Electrónico</label>
                                            <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => setMail(e.target.value)} />
                                            {!mail && (
                                                <>
                                                    <span className="text-validate">Dato requerido</span>
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "5%" }}>
                                    <Col lg="6">
                                        <div className="form-group">
                                            <label className="title-filter-modal">Contraseña</label>
                                            <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => setPassword(e.target.value)} />
                                            {!password && (
                                                <>
                                                    <span className="text-validate">Dato requerido</span>
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                { /*<Row style={{ marginTop: "5%" }}>
                                    <Col lg="6">
                                        <div className="form-group">
                                            <label className="title-filter-modal">Contraseña</label>
                                            <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => setPassword(e.target.value)} />
                                            {!password && (
                                                <>
                                                    <span className="text-validate">Dato requerido</span>
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                    <Col lg="6" style={{ alignSelf: "center" }}>

                                        <div className="SelectBusqueda">
                                            <label className="title-filter-modal">Seleccionar Rol*</label>
                                            <Select
                                                options={arrRols}
                                                defaultValue={arrRols.value}
                                                onChange={handleRoll}
                                                getOptionLabel={(arrRols) => arrRols.value}
                                                getOptionValue={(arrRols) => arrRols.value}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6" className="text-center">

                                    </Col>
                                    <Col lg="6" className="text-center">
                                        <Button className="restPassword" onClick={() => setRollModal(true)}>
                                            Agregar nuevo Rol
                                        </Button>
                                        <Modal show={rollModal}
                                            size="lg"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered style={{ padding: "40px" }}>
                                            <div className="Content">
                                                <Modal.Header>
                                                    <p className="modal-title" style={{ fontSize: "24px" }}>Datos del Roll</p>
                                                    <Button className="out-css-header" onClick={() => setRollModal(false)} ><img className="closed-css-modal" src={closedicon} alt="closedicon" />
                                                    </Button>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Row>
                                                        <Col lg="3" style={{ textAlign: "center" }}>


                                                        </Col>
                                                        <Col lg="6" style={{ textAlign: "center" }}>
                                                            <div className="form-group">
                                                                <label className="title-filter-modal">Nombre</label>
                                                                <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} value={rollName} onChange={(e) => setRollName(e.target.value)} />
                                                            </div>

                                                        </Col>
                                                        <Col lg="3" style={{ textAlign: "center" }}>


                                                        </Col>
                                                    </Row>


                                                </Modal.Body>
                                                <Row style={{ marginTop: "5%" }}>

                                                    <Col lg="12" style={{ alignSelf: "center" }}>
                                                        <Row>
                                                            <Col lg="6">
                                                                <Button className="out-modal" onClick={() => setRollModal(false)} >
                                                                    Cancelar
                                                                </Button>
                                                            </Col>
                                                            <Col lg="6">
                                                                <Button className="uploadLogotipo" onClick={saveRoll} >
                                                                    Guardar Cambios
                                                                </Button>
                                                            </Col>
                                                        </Row>

                                                    </Col>

                                                </Row>

                                            </div>
                                        </Modal>
                                    </Col>
                                </Row>
                                            */}


                            </Modal.Body>

                            <ModalFooter>
                                {showSpinner === true && (
                                    <>
                                        <Spinner style={{ marginTop: "35px" }} animation="border" variant="primary" />
                                    </>
                                )}
                                {showOptions === true && (
                                    <>
                                        <Button className="out-modal" onClick={handleClosedModal} >
                                            Salir
                                        </Button>

                                        <Button className="uploadLogotipo" disabled={!password || !nombre || !mail} onClick={addUser}>
                                            Guardar cambios
                                        </Button>
                                    </>
                                )}
                            </ModalFooter>
                        </form>

                    </div>
                </Modal>





            </div>
        </>
    );
}
