import { Form, Input, Row, Col, Button } from 'antd';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap';
import logo from "../../assets/logo/LogoCerti.png";
import logoEsade from "../../assets/logo/esadeletter2.png";
import './LoginCss.css'
import Spinner from 'react-bootstrap/Spinner'
import swal from 'sweetalert';
import axios from "axios";

import Forms from 'react-bootstrap/Form';
import { EyeOutlined, EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";



const Login = ({ authenticate, notauth }) => {

    const [correo, setCorreo] = useState("");
    const [password, setPassword] = useState("");
    const [loginUser, setLoginUser] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    // Validate
    const [correoError, setCorreoError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const navigate = useNavigate();
    const [showBoxTwo, setShowBoxTwo] = useState(true);
    const [showBox, setShowBox] = useState(true);
    const [showSpinner, setShowSpinner] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [resetModal, setResetModal] = useState(false);
    // Rset
    const [resetCorreo, setResetCorreo] = useState("");
    const [code, setCode] = useState("");
    const [correoPassword, setCorreoPassword] = useState('');
    const [showFooter, setShowFooter] = useState(true);
    const [resetField, setResetField] = useState(true);
    const [passwordField, setPasswordField] = useState(false);
    const [codebuttons, setCodebuttons] = useState(false);


    useEffect(() => {

    })

    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown);
    };



    const login = async () => {
        if (validate()) {
            setShowBoxTwo(false)
            setShowBox(false)
            let item = { correo, password };
            try {
                let result = await fetch(`${process.env.REACT_APP_URLSERVER}/v2/auth/login`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(item)
                });

                result = await result.json();
                setShowBoxTwo(true)
                setShowBox(true)
                if (result.body === "Correo o Contraseña invalido") {
                    setShowBoxTwo(true)
                    setShowBox(true)
                    setShowAlert(true)
                } else if (result.body === "Este usuario esta deshabilitado") {
                    swal({
                        title: "Este usuario esta deshabilitado!",
                        icon: "warning",
                        button: "Aceptar"
                    });
                }

                let newV = result.body.user.rol
                let token = result.body.jwt
                console.log('token en login', token);
                localStorage.setItem("rol-user", newV)
                localStorage.setItem("token", token)
                localStorage.setItem("user-info", JSON.stringify(result))

                if (newV === "USER_ROL" || newV === "CLIENT_ROL") {
                    //setLoginUser(newV)
                    navigate('/documentacion')
                } else if (newV === "ADMIN_ROL") {
                    //setLoginUser(newV)
                    navigate('/clientes')
                }
            } catch (error) {

            }
        }
    }

    const validate = () => {
        let correoError = "";
        let passwordError = "";
        if (correo === "") {
            correoError = "Por favor ingresa el correo de Usuario"
        }
        if (password === "") {
            passwordError = "Por favor ingrese su contraseña"
        }
        if (correoError || passwordError) {
            setCorreoError(correoError);
            setPasswordError(passwordError)
            return false;
        }
        return true;
    }

    const resetPassword = async () => {
        setShowFooter(false)
        setShowSpinner(false)
        setCodebuttons(false)
        let dataReset = {
            "correo": resetCorreo
        };
        try {
            const result = await axios.put(`${process.env.REACT_APP_URLSERVER}/v2/auth/generarCodigoReset`, dataReset, {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Accept": "application/json"
                }
            })
            if (result.status === 200) {
                swal({
                    title: "Codigo enviado!",
                    text: "Por favor revise su buzón de entrada!",
                    icon: "success",
                    button: "Aceptar"
                });
                setShowFooter(false)
                setShowSpinner(true)
                setResetField(false)
                setPasswordField(true)
                setCodebuttons(true)
            }

        } catch (e) {
            swal({
                title: e.response.data.body,
                icon: "warning",
                button: "Aceptar"
            });
            setShowFooter(true)
            setShowSpinner(true)
            setCodebuttons(false)
        }

    }


    const sendReset = async () => {
        setShowSpinner(false)
        setCodebuttons(false)
        let newData = {
            "codigoValidacion": code,
            "correo": resetCorreo,
            "password": correoPassword
        };
        try {
            const result = await axios.put(`${process.env.REACT_APP_URLSERVER}/v2/auth/resetPassword`, newData, {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Accept": "application/json"
                }
            })
            if (result.status === 200) {
                swal({
                    title: "Cambio de contraseña Exitoso!",
                    icon: "success",
                    button: "Aceptar"
                });
                setResetField(true)
                setPasswordField(false)
                setCodebuttons(false)
                setResetModal(false)
                setShowFooter(true)
                setShowSpinner(true)
                setResetCorreo('')
            }

        } catch (e) {
            swal({
                title: e.response.data.body,
                icon: "warning",
                button: "Aceptar"
            });
            setCodebuttons(true)
            setShowSpinner(true)
        }
    }

    const toggleModal = () => {
        setResetModal(false)
        setResetCorreo("");
        setCode("");
        setCorreoPassword("");
        setShowFooter(true)
        setResetField(true)
        setPasswordField(false)
        setCodebuttons(false)
    };

    return (
        <>
            <Row>

                <Col lg={14} className="bgPrimaryLogin d-none" style={{ height: "100vh" }}>
                    <div className="bgCapaLogin">

                    </div>
                </Col>
                <Col lg={24} xs={24} style={{ height: "100vh" }}>

                    <div className="content-form">

                        <Form className="main-form" style={{ width: "100%" }}>
                            <div>
                                <img src={logoEsade} alt="esade" width={250} />
                            </div>
                            <Forms.Label style={{ marginTop: "3%" }}>Correo de Usuario</Forms.Label>

                            <Forms.Group>
                                <Input type="email" name="correo" value={correo} onChange={(event) => setCorreo(event.target.value)} className="input-login text-start-login" placeholder="usuario@correo.com" />
                            </Forms.Group>
                            <span className="text-validate">{correoError}</span>



                            <Forms.Group className="mt-3">
                            <Forms.Label>Contraseña</Forms.Label><br></br>

                                <Input.Password
                                    style={{ height: "38px" }}
                                    onChange={(event) => setPassword(event.target.value)}
                                    className="input-login control"
                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />


                            </Forms.Group>
                            <span className="text-validate">{passwordError}</span>


                            <Forms.Group>
                                <Button onClick={() => setResetModal(true)} className="mt-2 passwordButton" style={{ border: "none" }}>¿Olvidaste tu contraseña?</Button>

                            </Forms.Group>
                            {showAlert === true && (
                                <>
                                    <span style={{ color: "red" }}>Correo o Contraseña  incorrecta</span>
                                    <br></br>
                                </>
                            )}


                            {showBox === true && (
                                <>
                                    <Button onClick={login} className="login-button">Iniciar Sesión</Button>
                                </>
                            )}
                            {showBoxTwo === false && (
                                <>
                                    <Spinner animation="border" variant="primary" />
                                </>
                            )}
                            <Row style={{ marginTop: "4%" }}>
                                <Col lg={24} xs={24} className="mb-3">
                                    <p style={{ marginBottom: "0px" }}>Tecnología blockchain Desarrollada por:</p>
                                </Col>
                                <Col lg={24} xs={24}>
                                    <div>
                                        <img src={logo} width={120} />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>

                <Modal show={resetModal}
                    backdrop="static"
                    keyboard={false}
                    centered
                    onHide={toggleModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Restablecer contraseña</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {resetField === true &&
                            (
                                <>
                                    Por favor ingresa tu correo para solicitar restablecer tu contraseña!
                                    <Forms.Group className="mb-3 text-center" controlId="exampleForm.ControlInput1">
                                        <Forms.Label>Correo de Usuario</Forms.Label><br></br>
                                        <Form.Item className="form-css"
                                            type="text"
                                            value=""
                                            name="correoPassword"
                                            onChange={(e) => setResetCorreo(e.target.value)}
                                            rules={[{ required: true, message: 'Por favor ingresa el correo de Usuario' }]}
                                        >
                                            <Input className="input-login" />
                                        </Form.Item>
                                    </Forms.Group>
                                </>
                            )
                        }

                        {passwordField === true &&
                            (
                                <>
                                    Por favor ingresa la nueva contraseña y el codigo enviado al correo!
                                    <Row style={{ justifyContent: "center" }}>
                                        <Col lg="3">

                                        </Col>
                                        <Col lg="6">
                                            <Forms.Group className="mb-3 text-center" controlId="exampleForm.ControlInput1">
                                                <Forms.Label>Nueva Contraseña</Forms.Label><br></br>
                                                <Form.Item className="form-css"
                                                    type="text"
                                                    value=""
                                                    name="correoPassword"
                                                    onChange={(e) => setCorreoPassword(e.target.value)}
                                                    rules={[{ required: true, message: 'Por favor ingresa la contraseña' }]}
                                                >
                                                    <Input className="input-login" />
                                                </Form.Item>
                                            </Forms.Group>

                                        </Col>
                                        <Col lg="3">

                                        </Col>
                                    </Row>
                                    <Row style={{ justifyContent: "center" }}>
                                        <Col lg="3">

                                        </Col>
                                        <Col lg="6">
                                            <Forms.Group className="mb-3 text-center" controlId="exampleForm.ControlInput1">

                                                <Forms.Label>Codigo de Verificación</Forms.Label><br></br>
                                                <Form.Item className="form-css"
                                                    type="text"
                                                    value=""
                                                    name="correoPassword"
                                                    onChange={(e) => setCode(e.target.value)}
                                                    rules={[{ required: true, message: 'Por favor ingresa el codigo' }]}
                                                >
                                                    <Input className="input-login" />
                                                </Form.Item>
                                            </Forms.Group>

                                        </Col>
                                        <Col lg="3">

                                        </Col>
                                    </Row>
                                </>
                            )
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        {showFooter === true && (
                            <>
                                <Button className="cancelButtonLogin" onClick={() => setResetModal(false)} >
                                    Cancelar
                                </Button>
                                <Button variant="primary" className="sendButton" onClick={resetPassword} disabled={!resetCorreo} >
                                    Enviar
                                </Button>
                            </>
                        )}
                        {codebuttons === true && (
                            <>
                                <Button className="cancelButtonLogin" onClick={resetPassword} >
                                    Volver a enviar
                                </Button>
                                <Button variant="primary" style={{ marginLeft: "60px" }} className="sendButton" onClick={sendReset} >
                                    Aceptar
                                </Button>
                            </>
                        )}
                        {showSpinner === false && (
                            <>
                                <Spinner animation="border" variant="primary" />
                            </>
                        )}
                    </Modal.Footer>
                </Modal>

            </Row>
        </>
    )
}

export default Login