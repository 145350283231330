import axios from "axios";
import moment from 'moment';
import QRCode from 'qrcode';
import swal from 'sweetalert';

const user = JSON.parse(localStorage.getItem('user-info'));

// Data From Backend

const getData = async (
    setDataSource, setTotalPeso, setTotalVistos, setArrCategory, setSelecCategoria, setSelecCuenta, setArrTipo, setTiposModal, setArrEstado, setTotalDoc
) => {
    await axios(`${process.env.REACT_APP_URLSERVER}/v2/documentaciones`, {
        responseType: 'json',
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Authorization": user?.body?.jwt
        }

    }).then((response) => {
        let data = response.data.body.reverse()
        setDataSource(data);

        let totalDocs = data.length
        setTotalDoc(totalDocs)
        data.map((item) => {
            item.fechaTransform = moment(moment(item.fecha)).format('DD/MM/YYYY')
            item.nombreDoc = item.infoDocumento.nombre
            item.visibilidadNombre = item.visibilidad.nombre
            item.etiquetasSplit = item.etiquetas.join(', ')
        })


        // Nuevo Arreglo Categorias
        let cateARR = [{
            nombre: "Selecciona una categoría"
        }];
        let cateARRNAV = [{
            nombre: "Todas las categorías"
        }];
        // Nuevo Arreglo Cuentas
        let accountsArr = [{
            nombre: "Selecciona una cuenta"
        }];
        // Nuevo Arreglo Tipos
        let typeArr = [{
            nombre: "Selecciona un tipo"
        }];
        let typeArrNav = [{
            nombre: "Todos los tipos"
        }];
        // Nuevo Arreglo Visibilidad
        let estadoArr = [{
            nombre: "Mostrar todos"
        }]
        let arrayVisto = []
        let arrayPesoarchivo = []
        // ForEach Data
        data.forEach((item) => {

            // Peso 2
            arrayPesoarchivo.push(item.infoDocumento.size)
            let initialValuePeso = 0;
            let sumWithInitialPeso = arrayPesoarchivo.reduce(
                (previousValuePeso, currentValuePeso) => previousValuePeso + currentValuePeso,
                initialValuePeso
            );

            // Peso
            let dataPeso = sumWithInitialPeso;
            let decimals = 2;
            if (dataPeso === 0) return '0 Bytes';

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            const i = Math.floor(Math.log(dataPeso) / Math.log(k));
            let arrayPeso = [];
            arrayPeso.push(parseFloat((dataPeso / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]);

            setTotalPeso(arrayPeso);

            // Vistos
            arrayVisto.push(item.visto)
            let initialValue = 0;
            let sumWithInitial = arrayVisto.reduce(
                (previousValue, currentValue) => previousValue + currentValue,
                initialValue
            );

            setTotalVistos(sumWithInitial);
            item.passwordDoc = item.visibilidad.password;

            // For Categorias
            const categorias = cateARR.find((el) => el.nombre == item.categoria);
            const categorias2 = cateARRNAV.find((el) => el.nombre == item.categoria);
            if (categorias === undefined && categorias2 === undefined) {
                cateARR.push({
                    nombre: item.categoria
                })
                cateARRNAV.push({
                    nombre: item.categoria
                })
            }

            // For Cuentas
            const cuentas = accountsArr.find((el) => el.nombre == item.cuentaCliente);
            if (cuentas === undefined) {
                accountsArr.push({
                    nombre: item.cuentaCliente
                })
            }

            // For Tipos
            const tipos = typeArr.find((el) => el.nombre == item.tipo);
            const tipos2 = typeArr.find((el) => el.nombre == item.tipo);
            if (tipos === undefined && tipos2 === undefined) {
                typeArr.push({
                    nombre: item.tipo
                })
                typeArrNav.push({
                    nombre: item.tipo
                })
            }

            // For Estado
            const estado = estadoArr.find((el) => el.nombre == item.visibilidad.nombre);
            if (estado === undefined) {
                estadoArr.push({
                    nombre: item.visibilidad.nombre
                })
            }

        });

        setArrCategory(cateARRNAV)
        setSelecCategoria(cateARR)
        setSelecCuenta(accountsArr)
        setArrTipo(typeArrNav)
        setTiposModal(typeArr)
        setArrEstado(estadoArr)

    });
}
// Check IP device
const getipData = async (setIP) => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setIP(res.data.IPv4)
}
// Create Document
const createDocument = async (formData, setSrc, setIdDoc, setCreador, setHash, setDateHash, setTimeHash, setModalScreen, setShowBoxOne, setShowBoxTwo,setDocumentCreate,setDataSource) => {

    try {
        await axios.post(`${process.env.REACT_APP_URLSERVER}/v2/documentaciones`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Access-Control-Allow-Origin": "*",
                "Authorization": user?.body?.jwt
            }
        })
            .then(res => {
                if (res.data.code === 200) {
                    swal({
                        title: res.data.msg,
                        icon: "success",
                        button: "Aceptar"
                    });
                    QRCode.toDataURL(`${process.env.REACT_APP_QRURL}/qr/${res.data.body._id}`).then((data) => {
                        setSrc(data);
                    })
                    const hash = res.data.body.infoDocumento.hash
                    const dateHash = res.data.body.fecha
                    const dateHashFormate = moment(moment(dateHash)).format('DD/MM/YYYY')
                    const timeHash = moment(moment(dateHash)).format("hh:mm:ss a")
                    const creador = res.data.body.creador.nombre
                    const idoc = res.data.body._id
                    setIdDoc(idoc)
                    setCreador(creador)
                    setHash(hash);
                    setDateHash(dateHashFormate);
                    setTimeHash(timeHash)
                    getData(setDataSource);
                    //setModalScreen(2);
                    setDocumentCreate(true);
                    setShowBoxOne(false);
                    setShowBoxTwo(true);
                } else if (res.data.code === 400) {
                    swal({
                        title: res.data.msg,
                        icon: "warning",
                        button: "Aceptar"
                    });
                    setShowBoxOne(true);
                    setShowBoxTwo(true);
                }
            })
    } catch (e) {
        swal({
            title: "Ocurrio un error con la creación del Documento, por favor verifica e intenta nuevamente",
            icon: "warning",
            button: "Aceptar"
        });
        setShowBoxOne(true);
        setShowBoxTwo(true);
    }

}
// Send Mail
const sendMail = async (dataMail, setMailModal) => {
    try {
        axios.post(`${process.env.REACT_APP_URLSERVER}/v2/share/qr`, dataMail, {
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Authorization": user?.body?.jwt
            }
        }).then(res => {
            swal({
                title: "Correo enviado!",
                icon: "success",
                button: "Aceptar"
            });
            setMailModal(false)
        })
    } catch (e) {
        console.log(e)
    }
}

export {
    getData,
    getipData,
    createDocument,
    sendMail
}