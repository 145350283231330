import React, { useState, useEffect } from "react";
import { Table, Input, Switch } from 'antd';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import '../../Components/Navbar/NavbarHeader.css';
import circleupload from "../../assets/circleupload.svg";
import closedicon from "../../assets/boton-x.png";
import axios from "axios";
import moment from 'moment'
import swal from 'sweetalert';
import Spinner from 'react-bootstrap/Spinner'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Select from "react-select";
import { InputNumber } from 'antd';

import pdf from "../../assets/pdf.svg";
import art005 from "../../assets/art005.svg";
import placeholder from "../../assets/logo/placeholder.png";
import { EditOutlined } from "@ant-design/icons";
import esadedefault from "../../assets/logo/sadefav.jpeg"
import esadedefaultfav from "../../assets/logo/sadelogo.jpeg"
import logoCerti from "../../assets/logo/LogoCerti.png";

// components
import SideMenuAdmin from '../../Components/SideBarAdmin/SideMenuAdmin';
import NavbarHeader from '../../Components/Navbar/NavbarHeader';


import { useNavigate } from 'react-router-dom'



const estado = [
  {
    nombre: "Mostrar todos"
  },
  {
    nombre: "Activo"
  },
  {
    nombre: "Bloqueado",
  }
]



export default function Clientes() {
  const logo = require('../../assets/logo/placeholder.png');
  const image = { logo }



  const [file, setFile] = useState();

  const [dataSource, setDataSource] = useState(null);
  const [filterTable, setFilterTable] = useState(null);
  const [showOptions, setShowOptions] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showData, setShowData] = useState(false);
  const [showSpinnerData, setShowSpinnerData] = useState(true);
  const [usersList, setUsersList] = useState(null);
  // Datos de Formulario
  const [reasonSocial, setReasonSocial] = useState('');
  const [cif, setCif] = useState('');
  const [contact, setContact] = useState('');
  const [usuario, setUsuario] = useState('');
  const [password, setPassword] = useState('');
  const [tlf, setTlf] = useState('');
  const [direccion, setDireccion] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [comunidadAutonoma, setComunidadAutonoma] = useState('');
  const [cp, setCp] = useState('');
  const [stado, setStado] = useState();
  const [previewEdit, setPreviewEdit] = useState();
  const [previewEditFav, setPreviewEditFav] = useState();

  const [showpreviewEdit, setShowpreviewEdit] = useState(false)
  const [showpreviewEditFav, setShowpreviewEditFav] = useState(false)

  // Planes
  const [plan, setPlan] = useState({});
  const [arrPlanes, setArrPlanes] = useState([])

  // Plan Datos
  const [nombrePlan, setNombrePlan] = useState('');
  const [costoPlan, setCostoPlan] = useState('');
  const [descuentoPlan, setDescuentoPlan] = useState('')
  const [totalPlan, setTotalplan] = useState('')
  const [descuentoparcial, setDescuentoparcial] = useState('')
  // Modals
  const [modalPlan, setModalPlan] = useState(false)


  // Validate Datos
  const [direccionError, setDireccionError] = useState("");
  const [ciudadError, setCiudadError] = useState("");
  const [comunidadAutonomaError, setComunidadAutonomaError] = useState("");
  const [cpError, setCpError] = useState("")
  const [contactoError, setContactoError] = useState("")
  const [tlfError, setTlfError] = useState("")
  const [planError, setPlanError] = useState("");
  const [passwordError, setPasswordError] = useState("")
  const [correoError, setCorreoError] = useState("")
  const [reasonSocialError, setReasonSocialError] = useState("")


  //edit User
  const [isEditing, setIsEditing] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [inactive, setInative] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [showFile, setShowFile] = useState(false);
  const [icon, setIcon] = useState();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [showpreviewLogo, setShowpreviewLogo] = useState(false);
  const [showpreviewIcon, setShowpreviewIcon] = useState(false);
  const [preview, setPreview] = useState()

  const [previewt, setPreviewt] = useState()

  const [defaultimg, setDefaultimg] = useState()

  const [validicon, setValidicon] = useState(false)
  const [validimg, setValidimg] = useState(false)
  const [validimgEdit, setValidimgEdit] = useState(false)
  const [validiconEdit, setValidiconEdit] = useState(false)

  // navigate
  const navigate = useNavigate();

  // KBS
  const [value, setValue] = useState('');
  const [modalform, setModalform] = useState(false);

  const columns = [
    {
      title: 'Cliente',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Documentos',
      dataIndex: 'nroDocumentos',
      key: 'nroDocumentos',
      render: text => <span>{text} <img clasName="imgpdf" src={pdf} /></span>
    },
    {
      title: 'Gas Fee',
      dataIndex: 'totalParcial',
      key: 'totalParcial',
      render: text => <span>{text.toFixed(10)} €</span>
    },
    {
      title: 'Visualizaciones',
      dataIndex: 'nroVisualizaciones',
      key: 'nroVisualizaciones',
    },
    {
      title: 'Almacenamiento',
      dataIndex: 'pesoarchivos',
      key: 'pesoarchivos',
      render: text => !text ? <span>0 Bytes</span> : <span>{text}</span>
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      key: 'plan',
      render: plan => <span>{plan.nombre}</span>
    },
    {
      title: 'Estado',
      key: 'estadoString',
      dataIndex: 'estadoString',
      //render: estado => estado === true ? <span >Activo</span> : <span >Bloqueado</span>
    },
    {
      title: 'Ultimo Acceso',
      key: 'ultAcceso',
      dataIndex: 'ultAcceso',
      render: text => moment(moment(text)).format('DD/MM/YYYY')
    },

    {
      key: "7",
      title: "Opciones",
      render: (record) => {
        return (
          <>
            <Button className="btn-Edit editButton" onClick={() => {
              onEditUser(record);
            }}>
              <EditOutlined style={{ color: "red", cursor: "pointer" }}

              />
            </Button>

          </>
        );
      },
    },
  ];



  useEffect(() => {
    callData()
    transformBlob()
    if (!file && !icon) {
      setPreview(undefined)
      setPreviewt(undefined)
      return
    }



    const objectUrl = URL.createObjectURL(file)
    const objectUrlt = URL.createObjectURL(icon)

    setPreview(objectUrl)
    setPreviewt(objectUrlt)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, []);

  const transformBlob = async () => {
    //const url = { placeholder }
    //const fileName = 'myFile.jpg'
    const url = { placeholder }
    const fileName = 'myFile.jpg'
    fetch(url)
      .then(async response => {
        const contentType = response.headers.get('content-type')
        const blob = await response.blob()
        const file = new File([blob], fileName, { contentType })
        setDefaultimg(file)
        // access file here
      })
  }


  const user = JSON.parse(localStorage.getItem('user-info'));



  const handleClosedModalEdit = () => {
    setDireccionError("");
    setCiudadError("");
    setComunidadAutonomaError("");
    setCpError("");
    setContactoError("");
    setTlfError("")
    setShowpreviewEdit(false)
    setShowpreviewEditFav(false)
    setPreviewEdit()
    setPreviewEditFav()
    setIsEditing(false)
  }

  function handleChange(event) {
    setFile(event.target.files[0]);
    //setShowpreviewLogo(true)


    // Fetching current height and width

    var img = document.createElement("img");

    let image = event.target.files[0];

    img.onload = function () {
      if (img.width === 1024 && img.height === 700 && image.name.match(/\.(jpg|jpeg|png)$/)) {
        setShowpreviewLogo(true)
        setValidimg(true)
        setFile(event.target.files[0]);
      } else {
        swal({
          title: "Dimensiones o Formato incorrecto",
          text: "Las dimensiones son 1024px x 700px y el formato debe de ser PNG o JPG",
          icon: "warning",
          button: "Aceptar"
        });
        setShowpreviewLogo(false)
        setValidimg(false)
      }
    };

    var reader = new FileReader();
    reader.onloadend = function (ended) {
      img.src = ended.target.result;
    }
    reader.readAsDataURL(event.target.files[0]);


  }



  function editImg(event) {


    // Fetching current height and width

    var img = document.createElement("img");


    let image = event.target.files[0];

    img.onload = function () {
      if (img.width === 1024 && img.height === 700 && image.name.match(/\.(jpg|jpeg|png)$/)) {
        setShowpreviewEdit(true)
        setValidimgEdit(true)
        setEditingUser((pre) => {
          return {
            ...pre, logo: event.target.files[0],
          }
        })
        setPreviewEdit(URL.createObjectURL(event.target.files[0]))
      } else {
        swal({
          title: "Dimensiones o Formato incorrecto",
          text: "Las dimensiones son 1024px x 700px y el formato debe de ser PNG o JPG",
          icon: "warning",
          button: "Aceptar"
        });
        setShowpreviewEdit(false)
        setValidimgEdit(false)
      }
    };

    var reader = new FileReader();
    reader.onloadend = function (ended) {
      img.src = ended.target.result;
    }
    reader.readAsDataURL(event.target.files[0]);

  }

  function editFav(event) {




    // Fetching current height and width

    var img = document.createElement("img");



    let image = event.target.files[0];

    img.onload = function () {

      if (img.width === 32 && img.height === 32 && image.name.match(/\.(jpg|jpeg|png)$/)) {
        setShowpreviewEditFav(true)
        setValidiconEdit(true)
        setEditingUser((pre) => {
          return {
            ...pre, favicon: event.target.files[0],
          }
        })
        setPreviewEditFav(URL.createObjectURL(event.target.files[0]))
      } else {
        swal({
          title: "Dimensiones o Formato incorrecto",
          text: "Las dimensiones son 32px x 32px y el formato debe de ser PNG o JPG",
          icon: "warning",
          button: "Aceptar"
        });
        setShowpreviewEditFav(false)
        setValidiconEdit(false)
      }
    };

    var reader = new FileReader();
    reader.onloadend = function (ended) {
      img.src = ended.target.result;
    }
    reader.readAsDataURL(event.target.files[0]);

  }

  const handleChangeIcon = (event) => {
    setIcon(event.target.files[0]);
    //setShowpreviewIcon(true)


    // Fetching current height and width

    var icon = document.createElement("img");

    let image = event.target.files[0];

    icon.onload = function () {
      if (icon.width === 32 && icon.height === 32 && image.name.match(/\.(jpg|jpeg|png)$/)) {
        setShowpreviewIcon(true)
        setValidicon(true)
        setIcon(event.target.files[0]);

      } else {
        swal({
          title: "Dimensiones o Formato incorrecto",
          text: "Las dimensiones son 32px x 32px y el formato debe de ser PNG o JPG",
          icon: "warning",
          button: "Aceptar"
        });
        setShowpreviewIcon(false)
        setValidicon(false)
      }
    };

    var reader = new FileReader();
    reader.onloadend = function (ended) {
      icon.src = ended.target.result;
    }
    reader.readAsDataURL(event.target.files[0]);




  }

  const callData = async () => {
    try {
      let resp = await axios.get(`${process.env.REACT_APP_URLSERVER}/v2/usuarios`, {
        responseType: 'json',
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Authorization": user?.body?.jwt
        }

      })
      let data = resp.data.body.reverse()
      setUsersList(data.length)
      data.map((item) => {
        item.ciudad = item.domicilioFiscal.ciudad
        item.comunidad = item.domicilioFiscal.comunidadAutonoma
        item.cp = item.domicilioFiscal.cp
        item.direccion = item.domicilioFiscal.direccion
        item.nombreContacto = item.contacto.nombre
        item.tlfnro = item.contacto.nroTlf
        item.estadoString = item.estado === true ? "Activo" : "Bloqueado"
      })
      let planesArr = [
        {
          nombre: "Seleccione un Plan"
        },
        {
          nombre: "Pack TEST",
          costo: 0,
          descuento: 0
        },
        {
          nombre: "Pack BASIC",
          costo: 249,
          descuento: 0
        },
        {
          nombre: "Pack EXECUTIVE",
          costo: 499,
          descuento: 0
        },
        {
          nombre: "Pack UNLIMITED",
          costo: 999,
          descuento: 0
        }
      ]
      data.forEach((item) => {

        const planes = planesArr.find((el) => el.nombre == item.plan.nombre);

        if (planes === undefined) {
          planesArr.push({
            nombre: item.plan.nombre,
            costo: item.plan.costo,
            descuento: item.plan.descuento
          })
        }
      })
      setArrPlanes(planesArr)
      axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=EUR&ids=matic-network&order=market_cap_desc&per_page=100&page=1&sparkline=false`, {
        responseType: 'json',
        headers: {
          "Access-Control-Allow-Origin": "*",
        }

      }).then((response) => {
        let currencyEur = " "
        let TotalParcial = " "
        let currency = " "
        let dataapi = response.data
        dataapi.forEach((item) => {
          currency = item.current_price
          data.forEach((item2) => {
            currencyEur = item2.consumoTotal
            TotalParcial = currencyEur * currency
            item2.totalParcial = TotalParcial
            let dataPeso = item2.pesoTotalArchivos
            let decimals = 2
            if (dataPeso === 0) return '0 Bytes';

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            const i = Math.floor(Math.log(dataPeso) / Math.log(k));
            let arrayPeso = []
            arrayPeso.push(parseFloat((dataPeso / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]);
            item2.pesoarchivos = arrayPeso
          });
        })
        setDataSource(data)
        setShowData(true)
        setShowSpinnerData(false)
      });
    } catch (error) {
      if (error.response.data.body.body === "Token Expirado") {
        localStorage.removeItem('rol-user');
        localStorage.removeItem('user-info');
        navigate('/')
      } else if (error.response.data.body.body === "Token invalido") {
        localStorage.removeItem('rol-user');
        localStorage.removeItem('user-info');
        navigate('/')
      }
    }


  }

  const handleEstado = (e) => {
    const currValue = e.target.value;
    if (currValue === "Mostrar todos") {
      return setFilterTable(null)
    } else {
      const filterTable = dataSource.filter(entry =>
        entry.estadoString.includes(currValue)
      );
      setFilterTable(filterTable);
    }

  }

  const handleModal = () => {
    setModalform(true)
  }

  const handleClosedModal = () => {
    setModalform(false)
    setDireccionError("");
    setCiudadError("");
    setComunidadAutonomaError("");
    setCpError("");
    setContactoError("");
    setTlfError("")
    setShowOptions(true)
    setShowSpinner(false)
    setShowpreviewLogo(false);
    setShowpreviewIcon(false);
    resetEditing()
  }



  const addClient = async () => {
    setShowOptions(false)
    setShowSpinner(true)
    const formData = new FormData();

    formData.append(
      "nombre",
      reasonSocial,
    );
    formData.append(
      "correo",
      usuario,
    );
    formData.append(
      "password",
      password,
    );
    formData.append(
      "cif",
      cif,
    );
    formData.append(
      "plan",
      JSON.stringify({
        nombre: plan.nombre,
        costo: plan.costo,
        descuento: descuentoparcial === '' ? plan.descuento : descuentoparcial
      })
    );
    formData.append(
      "domicilioFiscal",
      JSON.stringify({
        direccion: direccion,
        ciudad: ciudad,
        comunidadAutonoma: comunidadAutonoma,
        cp: cp
      })
    );
    formData.append(
      "contacto",
      JSON.stringify({
        nombre: contact,
        nroTlf: tlf
      })
    );

    formData.append(
      "logo",
      file,
    );
    formData.append(
      "favicon",
      icon,
    );

    if (validatePlan() && validateUser() && validimg === true && validicon === true) {
      try {
        let result = await axios.post(`${process.env.REACT_APP_URLSERVER}/v2/auth/signin/cliente`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            "Access-Control-Allow-Origin": "*",
            "Authorization": user?.body?.jwt
          }
        })
        //result = result.json();
        if (result.status === 200) {
          swal({
            title: result.data.msg,
            icon: "success",
            button: "Aceptar"
          });
          setNombrePlan('')
          setCostoPlan('')
          setTotalplan('')
          setDescuentoPlan('')
          setDescuentoparcial('')
          callData();
          setReasonSocial('')
          setCif('')
          setContact('')
          setPassword('')
          setTlf('')
          setDireccion('')
          setCiudad('')
          setComunidadAutonoma('')
          setCp('')
          setPlan('')
          handleClosedModal()
          setShowOptions(true)
          setShowSpinner(false)
        } else if (result.status === 400) {
          swal({
            title: "Por favor Verifica todos los datos requeridos",
            icon: "warning",
            button: "Aceptar"
          });
          setShowOptions(true)
          setShowSpinner(false)
        }

      } catch (e) {
        swal({
          title: e.response.data.body,
          icon: "warning",
          button: "Aceptar"
        });
        setShowOptions(true)
        setShowSpinner(false)
        //swal(e);
      }
    } else {
      swal({
        title: "Por favor Verifica todos los datos requeridos",
        icon: "warning",
        button: "Aceptar"
      });
      setShowOptions(true)
      setShowSpinner(false)
    }
  }

  /*const onFileChange = async (event) => {
    setShowFile(true);
    setSelectedFile(event.target.files[0])
    await setSelectedFileName(event.target.files[0].name)
  };*/

  const onEditUser = (record) => {
    setIsEditing(true);
    setEditingUser({ ...record });
  };
  const resetEditing = () => {
    setIsEditing(false);
    setEditingUser(null);
  };

  const changeProfile = async () => {
    setShowOptions(false)
    setShowSpinner(true)
    const formData = new FormData();
    formData.append(
      "idActualizar",
      editingUser?._id
    );
    formData.append(
      "logo",
      editingUser?.logo
    );
    formData.append(
      "estado",
      editingUser?.estado
    );
    formData.append(
      "favicon",
      editingUser?.favicon
    );
    formData.append(
      "plan",
      JSON.stringify({
        nombre: editingUser?.plan.nombre,
        costo: editingUser?.plan.costo,
        descuento: editingUser?.plan.descuento
      })
    );
    formData.append(
      "domicilioFiscal",
      JSON.stringify({
        direccion: editingUser?.direccion,
        ciudad: editingUser?.ciudad,
        comunidadAutonoma: editingUser?.comunidad,
        cp: editingUser?.cp
      })
    );
    formData.append(
      "contacto",
      JSON.stringify({
        nombre: editingUser?.nombreContacto,
        nroTlf: editingUser?.tlfnro
      })
    );

    if (editingUser?.password == " " || editingUser?.password == undefined) {
      formData.delete(
        "password",
      );
    } else {
      formData.append(
        "password",
        editingUser?.password
      );
    }

    if (validate()) {
      try {
        let res = await axios.put(`${process.env.REACT_APP_URLSERVER}/v2/usuarios/cliente`, formData, {
          headers: {
            //'Content-Type': 'application/json',
            'Content-Type': 'multipart/form-data',
            "Access-Control-Allow-Origin": "*",
            "Authorization": user?.body?.jwt
          }
        })
        if (res.status === 200) {
          setShowOptions(true)
          setShowSpinner(false)
          swal({
            title: "Cliente actualizado!",
            icon: "success",
            button: "Aceptar"
          });
          callData()
          setDireccionError("");
          setCiudadError("");
          setComunidadAutonomaError("");
          setCpError("");
          setContactoError("");
          setTlfError("")
          resetEditing()
          setShowpreviewEdit(false)
          setShowpreviewEditFav(false)
          setPreviewEdit()
          setPreviewEditFav()
          handleClosedModal()
        }
      } catch (error) {
        swal({
          title: "Ocurrio un error , por favor verifica e intenta nuevamente",
          icon: "warning",
          button: "Aceptar"
        });
        setShowOptions(true)
        setShowSpinner(false)
      }
    } else {
      swal({
        title: "Por favor verifica todos los datos antes de continuar",
        icon: "warning",
        button: "Aceptar"
      });
      setShowOptions(true)
      setShowSpinner(false)
      setShowpreviewEdit(false)
      setShowpreviewEditFav(false)
      setPreviewEdit()
      setPreviewEditFav()
    }
    //resetEditing()
  }
  const validate = () => {
    let direccionError = "";
    let ciudadError = "";
    let comunidadAutonomaError = "";
    let cpError = "";
    let contactoError = "";
    let tlfError = ""

    if (editingUser?.direccion === "") {
      direccionError = "No puedes enviar datos vacios"
    }
    if (editingUser?.ciudad === "") {
      ciudadError = "No puedes enviar datos vacios"
    }
    if (editingUser?.comunidad === "") {
      comunidadAutonomaError = "No puedes enviar datos vacios"
    }
    if (editingUser?.cp === "") {
      cpError = "No puedes enviar datos vacios"
    }
    if (editingUser?.nombreContacto === "") {
      contactoError = "No puedes enviar datos vacios"
    }
    if (editingUser?.tlfnro === "") {
      tlfError = "No puedes enviar datos vacios"
    }
    if (direccionError || ciudadError || comunidadAutonomaError || cpError || contactoError || tlfError) {
      setDireccionError(direccionError);
      setCiudadError(ciudadError);
      setComunidadAutonomaError(comunidadAutonomaError);
      setCpError(cpError);
      setContactoError(contactoError);
      setTlfError(tlfError)
      return false;
    }
    return true;
  }

  const validateUser = () => {
    let direccionError = "";
    let ciudadError = "";
    let comunidadAutonomaError = "";
    let cpError = "";
    let contactoError = "";
    let tlfError = ""
    let passwordError = ""
    let correoError = ""
    let razonError = ""

    if (direccion === "") {
      direccionError = "No puedes enviar datos vacios"
    }
    if (usuario === "") {
      correoError = "No puedes enviar datos vacios"
    }
    if (reasonSocial === "") {
      razonError = "No puedes enviar datos vacios"
    }
    if (ciudad === "") {
      ciudadError = "No puedes enviar datos vacios"
    }
    if (comunidadAutonoma === "") {
      comunidadAutonomaError = "No puedes enviar datos vacios"
    }
    if (cp === "") {
      cpError = "No puedes enviar datos vacios"
    }
    if (contact === "") {
      contactoError = "No puedes enviar datos vacios"
    }
    if (tlf === "") {
      tlfError = "No puedes enviar datos vacios"
    }
    if (password === "") {
      passwordError = "No puedes enviar datos vacios"
    }
    if (correoError || passwordError || direccionError || ciudadError || comunidadAutonomaError || cpError || contactoError || tlfError) {
      setDireccionError(direccionError);
      setCiudadError(ciudadError);
      setComunidadAutonomaError(comunidadAutonomaError);
      setCpError(cpError);
      setContactoError(contactoError);
      setTlfError(tlfError)
      setPasswordError(passwordError)
      setCorreoError(correoError)
      setReasonSocialError(razonError)
      return false;
    }
    return true;
  }

  const validatePlan = () => {
    let planError = ""

    if (plan === "" || plan === "Seleccione un Plan") {
      planError = "El plan es requerido"
    }
    if (planError) {
      setPlanError(planError)
      return false;
    }
    return true;
  }








  const savePlan = () => {
    const newPlan = {
      nombre: nombrePlan,
      costo: costoPlan,
      descuento: descuentoPlan
    }
    setArrPlanes([...arrPlanes, newPlan])
    setModalPlan(false)
  }

  const descuentoTotalPlan = (value) => {
    const porcentaje = value
    const percentage = (100 / value)
    const percentage2 = (costoPlan / percentage)
    const valorTotal = costoPlan - percentage2
    const something = percentage
    setDescuentoparcial(porcentaje)
    setDescuentoPlan(percentage2)
    setTotalplan(valorTotal)

  }

  const closedUserModal = () => {
    setModalform(false)
    setNombrePlan('')
    setCostoPlan('')
    setTotalplan('')
    setDescuentoPlan('')
    setDescuentoparcial('')
    callData();
    setReasonSocial('')
    setCif('')
    setContact('')
    setPassword('')
    setTlf('')
    setDireccion('')
    setCiudad('')
    setComunidadAutonoma('')
    setCp('')
    setPlan('')
    setShowpreviewLogo(false);
    setShowpreviewIcon(false);
  }



  return (
    < div style={{
      height: "100vh"
    }} >


      <SideMenuAdmin onCollapse={(inactive) => {
        setInative(inactive);
      }} />

      <div className={`containerTwo ${inactive ? "inactive" : ""}`}>
        <img className="logo-css d-sm-none" src={logoCerti} alt="certiblock" />

        <NavbarHeader listUsers={usersList} inputPlaceHolder={"Buscar cliente"} titlePage={"Clientes"} setValue={setValue} setFilterTable={setFilterTable} dataFilter={dataSource} inputValue={value} />



        <div className="containerTable">


          <div className="table-responsive">
            <Row>

              <Col lg="12" style={{ display: "flex", justifyContent: "end" }}>
                <div className="SelectBusqueda">
                  <p className="title-filter">Estado</p>

                  <Form.Select onChange={handleEstado} className="select-css" aria-label="Default select example">
                    {
                      estado.map((estado, index) => (
                        <option key={index} value={estado.nombre} >{estado.nombre}</option>
                      ))
                    }
                  </Form.Select>

                </div>
              </Col>

            </Row>
            {
              showSpinnerData === true && (
                <>
                  <div style={{ justifyContent: "center", height: "50vh" }} className="d-flex align-items-center">
                    <strong>Cargando Data...</strong>
                    <div className="spinner-borderData ml-auto" role="status" aria-hidden="true"></div>
                  </div>
                </>
              )
            }
            {
              showData === true && (
                <>
                  <Table columns={columns} scroll={{ x: 'max-content' }} pagination={{ pageSize: 6 }} dataSource={filterTable == null ? dataSource : filterTable} />

                </>
              )
            }
            <Button className="uploadUser" onClick={handleModal}>Crear nueva cuenta cliente <img src={circleupload} alt="circleupload" /></Button>

          </div>

        </div>



        <Modal show={modalform} size="lg"
          aria-labelledby="contained-modal-title-vcenter pModal"
          centered style={{ padding: "40px" }}>
          <div className="Content">
            <Modal.Header>
              <p className="modal-title" style={{ fontSize: "24px" }}>Ficha de la empresa</p>

              <Button className="out-css-header" onClick={handleClosedModal} ><img className="closed-css-modal" src={closedicon} alt="closedicon" />
              </Button>
            </Modal.Header>
            <Modal.Body>
              <Row style={{ marginBottom: "20px" }}>
                <Col lg="12">
                  <span style={{ fontSize: "20px" }}>Todos los campos son requeridos</span>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <div className="form-group">
                    <label className="title-filter-modal">Razón social*</label>
                    <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => setReasonSocial(e.target.value)} />
                  </div>
                  <span className="text-validate">{reasonSocialError}</span>
                </Col>
                <Col lg="6">
                  <div className="form-group">
                    <label className="title-filter-modal">CIF*</label>
                    <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => setCif(e.target.value)} />
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: "5%" }}>
                <Col lg="6">
                  <div className="form-group">
                    <label className="title-filter-modal">Dirección*</label>
                    <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => setDireccion(e.target.value)} />
                  </div>
                  <span className="text-validate">{direccionError}</span>
                </Col>
                <Col lg="6">
                  <div className="form-group">
                    <label className="title-filter-modal">Ciudad*</label>
                    <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => setCiudad(e.target.value)} />
                  </div>
                  <span className="text-validate">{ciudadError}</span>
                </Col>
              </Row>

              <Row style={{ marginTop: "5%" }}>
                <Col lg="6">
                  <div className="form-group">
                    <label className="title-filter-modal">Comunidad Autónoma*</label>
                    <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => setComunidadAutonoma(e.target.value)} />
                  </div>
                  <span className="text-validate">{comunidadAutonomaError}</span>
                </Col>
                <Col lg="6">
                  <div className="form-group">
                    <label className="title-filter-modal">Codígo Postal*</label>
                    <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => setCp(e.target.value)} />
                  </div>
                  <span className="text-validate">{cpError}</span>
                </Col>
              </Row>

              <Row style={{ marginTop: "5%" }}>
                <Col lg="6">
                  <div className="form-group">
                    <label className="title-filter-modal">Persona de Contacto*</label>
                    <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => setContact(e.target.value)} />
                  </div>
                  <span className="text-validate">{contactoError}</span>
                </Col>
                <Col lg="6">
                  <div className="form-group">
                    <label className="title-filter-modal">Tlf de contacto*</label>
                    <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => setTlf(e.target.value)} />
                  </div>
                  <span className="text-validate">{tlfError}</span>
                </Col>
              </Row>


              <Row style={{ marginTop: "5%" }}>
                <p className="modal-title" style={{ fontSize: "24px", marginBottom: "5%" }}>Datos del acceso de administración</p>
                <Col lg="6">
                  <div className="form-group">
                    <label className="title-filter-modal">Correo de usuario*</label>
                    <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => setUsuario(e.target.value)} />
                  </div>
                  <span className="text-validate">{correoError}</span>
                </Col>
                <Col lg="6">
                  <div className="form-group">
                    <label className="title-filter-modal">Clave de acceso*</label>
                    <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => setPassword(e.target.value)} />
                  </div>
                  <span className="text-validate">{passwordError}</span>
                </Col>
              </Row>
              <Row style={{ marginTop: "5%" }}>
                <Col lg="6" style={{ alignSelf: "center" }}>

                  <Button className="uploadLogotipo" onClick={() => setModalPlan(true)} >
                    Crear nuevo plan
                  </Button>

                  <Modal show={modalPlan}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered style={{ padding: "40px" }}>
                    <div className="Content">
                      <Modal.Header>
                        <p className="modal-title" style={{ fontSize: "24px" }}>Datos del Plan</p>
                        <Button className="out-css-header" onClick={() => setModalPlan(false)} ><img className="closed-css-modal" src={closedicon} alt="closedicon" />
                        </Button>
                      </Modal.Header>
                      <Modal.Body>
                        <Row>
                          <Col lg="6" style={{ textAlign: "center" }}>
                            <div className="form-group">
                              <label className="title-filter-modal">Nombre</label>
                              <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} value={nombrePlan} onChange={(e) => setNombrePlan(e.target.value)} />
                            </div>
                            {nombrePlan === "" && (
                              <>
                                <span className="text-validate">Este campo es requerido</span>
                              </>
                            )}
                          </Col>
                          <Col lg="6" style={{ textAlign: "center" }}>
                            <label className="title-filter-modal">Costo</label>
                            <div className="form-group">
                              <InputNumber min={1} max={10000} className="f" formatter={value => `${value}€`}
                                value={costoPlan}
                                style={{ marginLeft: "0px" }} onChange={(value) => setCostoPlan(value)} />
                            </div>
                            {costoPlan === "" && (
                              <>
                                <span className="text-validate">Este campo es requerido</span>
                              </>
                            )}
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "5%" }}>
                          <Col lg="6" style={{ textAlign: "center" }}>
                            <label className="title-filter-modal">Descuento %</label>

                            <div className="form-group">
                              <InputNumber min={0} max={99} className="f"
                                style={{ marginLeft: "0px" }} formatter={value => `${value}%`} value={descuentoparcial}
                                parser={value => value.replace('%', '')} onChange={descuentoTotalPlan} />
                            </div>
                            {descuentoparcial === "" && (
                              <>
                                <span className="text-validate">Este campo es requerido</span>
                              </>
                            )}
                          </Col>
                          <Col lg="6" style={{ textAlign: "center" }}>
                            <label className="title-filter-modal">Descuento Total</label>

                            <div className="form-group">
                              <InputNumber className="f" formatter={value => `${value}€`}
                                style={{ marginLeft: "0px" }} value={descuentoPlan} disabled />
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "5%" }}>
                          <Col lg="6" style={{ textAlign: "center" }}>
                            <label className="title-filter-modal">Costo Total</label>

                            <div className="form-group">
                              <InputNumber className="f" formatter={value => `${value}€`}
                                style={{ marginLeft: "0px" }} value={totalPlan} disabled />
                            </div>
                          </Col>
                        </Row>
                      </Modal.Body>
                      <Row style={{ marginTop: "5%" }}>

                        <Col lg="12" style={{ alignSelf: "center" }}>
                          <Row>
                            <Col lg="6">
                              <Button className="out-modal" onClick={() => setModalPlan(false)} >
                                Cancelar
                              </Button>
                            </Col>
                            <Col lg="6">
                              <Button className="uploadLogotipo" onClick={savePlan} disabled={!nombrePlan || !costoPlan} >
                                Guardar Cambios
                              </Button>
                            </Col>
                          </Row>

                        </Col>

                      </Row>

                    </div>
                  </Modal>
                </Col>
                <Col lg="6" style={{ alignSelf: "center" }}>

                  <div className="SelectBusqueda">
                    <label className="title-filter-modal">Seleccionar Plan*</label>
                    <Select
                      options={arrPlanes}
                      defaultValue={arrPlanes.nombre}
                      onChange={setPlan}
                      getOptionLabel={(arrPlanes) => arrPlanes.nombre}
                      getOptionValue={(arrPlanes) => arrPlanes.nombre}
                    />
                    <span className="text-validate">{planError}</span>
                  </div>
                </Col>

              </Row>
              {showFile === true && (
                <>
                  <Row>
                    <Col lg="12" className="dis-row">
                      <label><span className="fileName-css">Nombre del Documento:</span> {selectedFileName}</label>
                    </Col>
                  </Row>
                </>
              )}
              <Row style={{ marginTop: "5%" }}>

                <Col lg={6}>
                  <label className="title-filter-modal">Logotipo*</label>

                  <label htmlFor="files" className="uploadButtonModal wButton btn">Seleccionar el Logotipo <img src={circleupload} alt="circleupload" /></label>
                  <input id="files" type="file" style={{ visibility: "hidden" }} accept="image/*" onChange={handleChange} />


                </Col>
                <Col lg={6} className="text-center">
                  <label className="title-filter-modal" style={{ color: "red !important", fontSize: "12px" }}>Dimensiones requeridas 1024px x 700px</label>

                  {showpreviewLogo === true && (
                    <>
                      <img src={URL.createObjectURL(file)} style={{ width: "200px" }} />

                    </>
                  )
                  }
                </Col>

              </Row>
              <Row style={{ marginTop: "5%" }}>

                <Col lg={6}>
                  <label className="title-filter-modal">FavIcon*</label>

                  <label htmlFor="filesicon" className="uploadButtonModal wButton btn">Seleccionar el FavIcon <img src={circleupload} alt="circleupload" /></label>
                  <input id="filesicon" type="file" style={{ visibility: "hidden" }} accept="image/*" onChange={handleChangeIcon} />


                </Col>
                <Col lg={6} className="text-center" >
                  <label className="title-filter-modal" style={{ color: "red !important", fontSize: "12px" }}>Dimensiones requeridas 32px x 32px</label>
                  {showpreviewIcon === true && (
                    <>
                      <img src={URL.createObjectURL(icon)} style={{ width: "200px" }} />

                    </>
                  )
                  }
                </Col>
              </Row>

            </Modal.Body>
            <Row style={{ marginTop: "5%" }}>
              {showSpinner === true && (
                <>
                  <Col lg="12" style={{ alignSelf: "center", textAlign: "center" }}>
                    <Spinner style={{ marginTop: "35px" }} animation="border" variant="primary" />
                  </Col>
                </>
              )}
              {showOptions === true && (
                <>
                  <Col lg="12" style={{ alignSelf: "center" }}>
                    <Row>
                      <Col lg="6">
                        <Button className="out-modal" onClick={closedUserModal} >
                          Salir
                        </Button>
                      </Col>
                      <Col lg="6">
                        <Button className="uploadLogotipo" disabled={plan.nombre === "Seleccione un Plan" || !password || password === " " || !usuario || !tlf || !contact || !cp || !comunidadAutonoma || !direccion || !ciudad || !cif || !reasonSocial} onClick={addClient} >
                          Crear cliente
                        </Button>
                      </Col>
                    </Row>

                  </Col>
                </>
              )}

            </Row>

          </div>
        </Modal>
      </div>

      <Modal show={isEditing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter pModal"
        centered style={{ padding: "40px" }}>
        <div className="Content">
          <Modal.Header>
            <p className="modal-title" style={{ fontSize: "24px" }}>Ficha de la empresa</p>
            <Button className="out-css-header" onClick={handleClosedModalEdit} ><img className="closed-css-modal" src={closedicon} alt="closedicon" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg="6">
                <div className="form-group">
                  <label className="title-filter-modal">Razón social</label>
                  <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => {
                    setEditingUser((pre) => {
                      return { ...pre, nombre: e.target.value };
                    });
                  }} value={editingUser?.nombre} disabled />
                </div>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label className="title-filter-modal">CIF</label>
                  <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => {
                    setEditingUser((pre) => {
                      return { ...pre, cif: e.target.value };
                    });
                  }} value={editingUser?.cif} disabled />
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: "5%" }}>
              <Col lg="6">
                <div className="form-group">
                  <label className="title-filter-modal">Dirección</label>
                  <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => {
                    setEditingUser((pre) => {
                      return { ...pre, direccion: e.target.value };
                    });
                  }} value={editingUser?.direccion} />
                  <span className="text-validate">{direccionError}</span>
                </div>
              </Col>
              <Col lg="6" style={{ alignSelf: "center" }}>
                <div className="form-group">
                  <label className="title-filter-modal">Ciudad</label>
                  <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => {
                    setEditingUser((pre) => {
                      return { ...pre, ciudad: e.target.value };
                    });
                  }} value={editingUser?.ciudad} />
                  <span className="text-validate">{ciudadError}</span>
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: "5%" }}>
              <Col lg="6">
                <div className="form-group">
                  <label className="title-filter-modal">Comunidad Autónoma</label>
                  <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => {
                    setEditingUser((pre) => {
                      return { ...pre, comunidad: e.target.value };
                    });
                  }} value={editingUser?.comunidad} />
                  <span className="text-validate">{comunidadAutonomaError}</span>
                </div>
              </Col>
              <Col lg="6" style={{ alignSelf: "center" }}>
                <div className="form-group">
                  <label className="title-filter-modal">Codígo Postal</label>
                  <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => {
                    setEditingUser((pre) => {
                      return { ...pre, cp: e.target.value };
                    });
                  }} value={editingUser?.cp} />
                  <span className="text-validate">{cpError}</span>
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: "5%" }}>
              <Col lg="6">
                <div className="form-group">
                  <label className="title-filter-modal">Persona de Contacto</label>
                  <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => {
                    setEditingUser((pre) => {
                      return { ...pre, nombreContacto: e.target.value };
                    });
                  }} value={editingUser?.nombreContacto} />
                  <span className="text-validate">{contactoError}</span>
                </div>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label className="title-filter-modal">Tlf de contacto</label>
                  <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => {
                    setEditingUser((pre) => {
                      return { ...pre, tlfnro: e.target.value };
                    });
                  }} value={editingUser?.tlfnro} />
                  <span className="text-validate">{tlfError}</span>
                </div>
              </Col>
            </Row>


            <Row style={{ marginTop: "5%" }}>
              <p className="modal-title" style={{ fontSize: "24px", marginBottom: "5%" }}>Datos del acceso de administración</p>
              <Col lg="6">
                <div className="form-group">
                  <label className="title-filter-modal">Correo de usuario</label>
                  <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => {
                    setEditingUser((pre) => {
                      return { ...pre, correo: e.target.value };
                    });
                  }} value={editingUser?.correo} disabled />
                </div>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label className="title-filter-modal">Clave de acceso</label>
                  <Input className="form-control newCategory-css" style={{ marginLeft: "0px" }} onChange={(e) => {
                    setEditingUser((pre) => {
                      return { ...pre, password: e.target.value };
                    });
                  }} value={editingUser?.password} />
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: "5%" }}>
              <Col lg="6" style={{ alignSelf: "center" }}>
                <label>Estado</label>
                <div className="form-group" style={{ paddingTop: "5px" }}>
                  <BootstrapSwitchButton
                    checked={editingUser?.estado === true ? true : false}
                    onlabel='Activo'
                    offlabel='Bloqueado'
                    offstyle="danger"
                    width={100}
                    onChange={(e) => {
                      setEditingUser((pre) => {
                        return { ...pre, estado: e }
                      })
                    }}
                  />
                </div>

              </Col>
              <Col lg="6" style={{ alignSelf: "center" }}>

                <div className="SelectBusqueda">
                  <label className="title-filter-modal">Plan</label>
                  <Select
                    onChange={(e) => {
                      setEditingUser((pre) => {
                        return { ...pre, plan: e }
                      });
                    }}
                    options={arrPlanes}
                    defaultValue={editingUser?.plan}
                    getOptionLabel={(arrPlanes) => arrPlanes.nombre}
                    getOptionValue={(arrPlanes) => arrPlanes.nombre}
                  />
                  {editingUser?.plan.nombre === "Seleccione un Plan" && (
                    <>
                      <span className="text-validate">Debe de seleccionar un Plan</span>
                    </>
                  )}
                </div>
              </Col>

            </Row>

            <Row style={{ marginTop: "5%" }}>

              <Col lg={6}>
                <label className="title-filter-modal">Logotipo*</label>

                <label htmlFor="files" className="uploadButtonModal wButton btn">Seleccionar el Logotipo <img src={circleupload} alt="circleupload" /></label>
                <input id="files" type="file" style={{ visibility: "hidden" }} accept="image/*"

                  onChange={editImg}

                />

              </Col>


              <Col lg={6} className="text-center lexSrc">
                <label className="title-filter-modal" style={{ color: "red !important", fontSize: "12px" }}>Dimensiones requeridas 1024px x 700px</label>
                {
                  showpreviewEdit === false && (
                    <>
                      <img src={`${process.env.REACT_APP_URLSERVER}/imagenes/logos/${editingUser?.logo}`}

                        style={{ width: "200px" }} />

                    </>
                  )
                }
                {
                  showpreviewEdit === true && (
                    <>
                      <img src={previewEdit}

                        style={{ width: "200px" }} />
                    </>
                  )
                }

              </Col>


            </Row>

            <Row style={{ marginTop: "5%" }}>

              <Col lg={6}>
                <label className="title-filter-modal">FavIcon*</label>

                <label htmlFor="filesicon" className="uploadButtonModal wButton btn" >Seleccionar el FavIcon <img src={circleupload} alt="circleupload" /></label>
                <input id="filesicon" type="file" style={{ visibility: "hidden" }} accept="image/*" onChange={editFav}
                />


              </Col>


              <Col lg={6} className="text-center lexSrc">
                <label className="title-filter-modal" style={{ color: "red !important", fontSize: "12px" }}>Dimensiones requeridas 32px x 32px</label>
                {
                  showpreviewEditFav === true && (
                    <>
                      <img src={previewEditFav} style={{ width: "200px" }} />
                    </>
                  )
                }

                {
                  showpreviewEditFav === false && (
                    <>


                      <img src={`${process.env.REACT_APP_URLSERVER}/imagenes/favicons/${editingUser?.favicon}`}

                        style={{ width: "200px" }} />



                    </>
                  )
                }
              </Col>



            </Row>

          </Modal.Body>
          <Row style={{ marginTop: "5%" }}>
            {showSpinner === true && (
              <>
                <Spinner style={{ marginTop: "35px" }} animation="border" variant="primary" />
              </>
            )}
            {showOptions === true && (
              <>
                <Col lg="12" style={{ alignSelf: "center" }}>
                  <Row>
                    <Col lg="6">
                      <Button className="out-modal" onClick={handleClosedModalEdit} >
                        Salir
                      </Button>
                    </Col>
                    <Col lg="6">
                      <Button className="uploadLogotipo" onClick={changeProfile} disabled={editingUser?.plan.nombre === "Seleccione un Plan"}  >
                        Guardar Cambios
                      </Button>
                    </Col>
                  </Row>

                </Col>
              </>
            )}

          </Row>

        </div>
      </Modal >
    </div >
  );
}