import React from 'react';
import ReactDOM from 'react-dom';
import { LangProvider } from './context/langContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter } from "react-router-dom";

//import 'mdbreact/dist/css/mdb.css';

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>




ReactDOM.render(
  <BrowserRouter>

    <LangProvider>
      <App />
    </LangProvider>
    </BrowserRouter>,
  
  document.getElementById('root')
);


