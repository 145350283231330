import axios from "axios";

const user = JSON.parse(localStorage.getItem('user-info'));



const apiConsumo = (setCurrency) => {
    axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=EUR&ids=matic-network&order=market_cap_desc&per_page=100&page=1&sparkline=false`, {
        responseType: 'json',
        headers: {
            "Access-Control-Allow-Origin": "*",
        }

    }).then((response) => {
        let data = response.data
        setCurrency(data.curreny_price)
    });
}

export { 
    apiConsumo
}
