import react, { useState, useEffect } from "react";
import { Table } from 'antd';
import { Container, Row, Col, Form, Navbar, Nav, Button } from 'react-bootstrap';
import '../../Components/Navbar/NavbarHeader.css';
import circleupload from "../../assets/circleupload.svg";
import moment from 'moment'
import axios from "axios";

import * as XLSX from 'xlsx';
import logoCerti from "../../assets/logo/LogoCerti.png";

// Services
import { apiConsumo } from "../../Services/consumoAdminService";
// Css
import "../Documentation/ListDocu.css"
// Components
import NavbarHeader from '../../Components/Navbar/NavbarHeader';
import SideMenuAdmin from '../../Components/SideBarAdmin/SideMenuAdmin';
const polygon = process.env.REACT_APP_URL_POLYGON;


const columns = [
    {
        title: 'Fecha',
        dataIndex: 'fechatransform',
        key: 'fechatransform',
        //render: text => 
    },
    {
        title: 'Cliente',
        dataIndex: 'nombre_cliente',
        key: 'nombre_cliente',
        render: cliente => <span>{cliente}</span>
    },
    {
        title: 'Usuario',
        dataIndex: 'nombre_usuario',
        key: 'nombre_usuario',
        render: creador => <span>{creador}</span>
    },
    {
        title: (<><p className="text-start" style={{ marginBottom: "0px" }}>Documento</p></>),
        dataIndex: 'documento',
        key: 'documento',
        render: text => <p className="text-start" style={{ color: "#181C32", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginBottom: "0px" }}>{text.nombre} </p>
    },
    {
        title: <div style={{ textAlign: "left" }}>Hash</div>,
        key: 'detalle_hash',
        dataIndex: 'detalle_hash',
        width: '10%',
        render: text => <p style={{ textAlign: "left", marginBottom: "0" }} ><a href={`${polygon}tx/${text}`} target="_blank" ><p className="breakText">{text}</p></a></p>,
    },
    {
        title: 'Red',
        key: 'red',
        dataIndex: 'red',
        render: text => <span>Polygon</span>,
    },
    {
        title: 'Consumo',
        dataIndex: 'totalParcial',
        key: 'totalParcial',
        width: '10%',
        render: text => <p style={{ textAlign: "center", marginBottom: "0" }} >{text.toFixed(10)} €</p>,
    },
    {
        title: 'Total Almacenamiento',
        dataIndex: 'consumo',
        key: 'consumo',
        render: text => <span>{text.toFixed(7)} $</span>
    },
];



export default function Consumo() {
    const [dataSource, setDataSource] = useState(null);
    const [filterTable, setFilterTable] = useState(null);
    const [clienteArr, setClienteArr] = useState([]);
    const [value, setValue] = useState('');
    const [currency, setCurrency] = useState('');
    const [dataexcel, setDataexcel] = useState([]);
    const [inactive, setInative] = useState(false);
    const [showData, setShowData] = useState(false);
    const [showSpinnerData, setShowSpinnerData] = useState(true);

    const [cliente, setCliente] = useState('')
    const [datefilter, setDatefilter] = useState('');
    const [dateoriginal, setDateoriginal] = useState('');




    const user = JSON.parse(localStorage.getItem('user-info'));



    useEffect(() => {
        callData()
        apiConsumo(setCurrency)
    }, []);


    useEffect(() => {
        filtrar()
    }, [cliente, datefilter, dateoriginal]);


    const callData = async () => {
        try {
            let resp = await axios.get(`${process.env.REACT_APP_URLSERVER}/v2/admin/consumo`, {
                responseType: 'json',
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": user?.body?.jwt
                }

            })
            let data = resp.data.body.reverse()
            // Nuevo Arreglo clientes
            let clientesArr = [];
            // ForEach clientes
            data.forEach((item) => {
                // For Actividades
                const clientes = clientesArr.find((el) => el.nombre == item.cliente.nombre);
                if (clientes === undefined) {
                    clientesArr.push({
                        nombre: item.cliente.nombre
                    })
                }
            });
            setClienteArr(clientesArr)

            axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=EUR&ids=matic-network&order=market_cap_desc&per_page=100&page=1&sparkline=false`, {
                responseType: 'json',
                headers: {
                    "Access-Control-Allow-Origin": "*",
                }

            }).then((res) => {
                let currencyEur = " "
                let TotalParcial = " "
                let currency = " "
                let dataapi = res.data
                let nombreCliente = " "
                let nombreUsuario = " "
                let nombreDocumento = " "
                let detallesHash = " "
                let fechacreate = ""
                let dataExcel = []
                let expedientedetail = ""
                dataapi.forEach((item) => {
                    currency = item.current_price
                    data.forEach((item2) => {

                        currencyEur = item2.consumo
                        TotalParcial = currencyEur * currency
                        // Datos para Excel
                        nombreCliente = item2.cliente.nombre
                        nombreUsuario = item2.consumidor.nombre
                        nombreDocumento = item2.documento.nombre
                        detallesHash = item2.documento.hash
                        expedientedetail = item2.expediente
                        fechacreate = moment(moment(item2.fecha)).format('DD/MM/YYYY')
                        // Agregar a Json
                        item2.nombre_cliente = nombreCliente
                        item2.nombre_usuario = nombreUsuario
                        item2.nombre_documento = nombreDocumento
                        item2.detalle_hash = detallesHash
                        item2.totalParcial = TotalParcial
                        item2.fechatransform = moment(moment(item2.fecha)).format('DD/MM/YYYY')
                        dataExcel.push(
                            {
                                fecha: fechacreate,
                                cliente: nombreCliente,
                                usuario: nombreUsuario,
                                documento: nombreDocumento,
                                //expediente: expedientedetail,
                                hash: detallesHash,
                                consumo: item2.consumo,
                                total_almacenamiento: `${TotalParcial} €`
                                
                            },
                        )
                        setDataexcel(dataExcel)
                    });
                })

                setDataSource(data)
                setFilterTable(data)
                setShowData(true)
                setShowSpinnerData(false)
            });
        } catch (error) {
            console.log("error, consumo", error.response)
        }



    }

    const exportfile = () => {
        let dataExcel = []
        if (filterTable === null) {
            const workSheet = XLSX.utils.json_to_sheet(dataexcel)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, "actividad")
            //Binary string
            XLSX.write(workBook, { bookType: "xlsx", type: "array" })
            //Download
            XLSX.writeFile(workBook, "ConsumoData.xlsx")
        } else {
            filterTable.map((item) => {
                dataExcel.push(
                    {
                        Fecha: moment(moment(item.fecha)).format('DD/MM/YYYY'),
                        Cliente: item.nombre_cliente,
                        Usuario: item.nombre_usuario,
                        Documento: item.nombre_documento,
                        Hash: item.documento.hash,
                        Consumo: item.consumo,
                        Total: item.totalParcial
                        
                    },
                )

            })
            const workSheet = XLSX.utils.json_to_sheet(dataExcel)
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, "actividad")
            //Binary string
            XLSX.write(workBook, { bookType: "xlsx", type: "array" })
            //Download
            XLSX.writeFile(workBook, "ConsumoData.xlsx")
        }

    }



    const handleClient = (e) => {
        const currValue = e.target.value;
        setCliente(currValue)

    }

    const handleFilterDate = (e) => {
        const currValueTransform = moment(moment(e.target.value)).format('DD/MM/YYYY');
        const dateOriginal = e.target.value
        const currValue = currValueTransform;
        setDateoriginal(dateOriginal)
        setDatefilter(currValue)
    }

    const filtrar = () => {
        let listaFiltrada = dataSource;
        if (cliente) {
            listaFiltrada = listaFiltrada.filter((el) => el.cliente.nombre === cliente)
        }
        if (datefilter) {
            listaFiltrada = listaFiltrada.filter((el) => el.fechatransform === datefilter)
        }
        if (datefilter === "Invalid date") {
            setDatefilter('')
            return setFilterTable(null)
        }

        setFilterTable(listaFiltrada)

    }







    return (
        < div style={{
            height: "100vh"
        }} >
            <SideMenuAdmin onCollapse={(inactive) => {
                setInative(inactive);
            }} />

            <div className={`containerTwo ${inactive ? "inactive" : ""}`}>
                <img className="logo-css  d-sm-none" src={logoCerti} alt="certiblock" />


                <NavbarHeader inputPlaceHolder={"Buscar cliente o usuario"} titlePage={"Consumo"} setValue={setValue} setFilterTable={setFilterTable} dataFilter={dataSource} inputValue={value} />


                <div className="containerTable">

                    <div className="table-responsive">
                        <Row>

                            <Col lg="3" className="d-none d-sm-block">

                            </Col>

                            <Col lg="3" className="d-none d-sm-block">

                            </Col>

                            <Col xs="6" lg="3" style={{ display: "flex", justifyContent: "end" }}>
                                <div className="SelectBusqueda">
                                    <p className="title-filter">Clientes</p>

                                    <Form.Select onClick={handleClient} className="select-css" aria-label="Default select example">
                                        <option key="0" value="" >Todos los clientes</option>
                                        {
                                            clienteArr.map((item, index) => (
                                                <option key={index} value={item.nombre} >{item.nombre}</option>
                                            ))
                                        }
                                    </Form.Select>

                                </div>
                            </Col>

                            <Col xs="6" lg="3" style={{ display: "flex", justifyContent: "end" }}>
                                <div className="SelectBusqueda">
                                    <p className="title-filter">Fecha</p>

                                    <Form.Group controlId="dob">
                                        <Form.Control onChange={handleFilterDate} className="select-css" type="date" value={dateoriginal} name="dob" placeholder="Date of Birth" />
                                    </Form.Group>

                                </div>
                            </Col>

                        </Row>
                        {
                            showSpinnerData === true && (
                                <>
                                    <div style={{ justifyContent: "center", height: "50vh" }} className="d-flex align-items-center">
                                        <strong>Cargando Data...</strong>
                                        <div className="spinner-borderData ml-auto" role="status" aria-hidden="true"></div>
                                    </div>
                                </>
                            )
                        }
                        {
                            showData === true && (
                                <>
                                    <Table columns={columns} scroll={{ x: 'max-content' }} pagination={{ pageSize: 6 }} dataSource={filterTable == null ? dataSource : filterTable} />

                                </>
                            )
                        }

                        <Button className="uploadUser" onClick={exportfile}>Exportar resultados <img src={circleupload} alt="circleupload" /></Button>


                    </div>

                </div>

            </div>
        </ div>
    );
}