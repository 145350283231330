import React, { useState, useEffect } from 'react';
import { Button, Navbar, Nav, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'

import { Input } from 'antd';
import userSearch from "../../assets/userSearch.svg";
import { Navigate, NavLink } from "react-router-dom";

import doc from "../../assets/doc.svg";
import actividad from "../../assets/actividad.svg";
import actividadwhite from "../../assets/actividadwhite.svg";
import docwhite from "../../assets/docwhite.svg";
import userwhite from "../../assets/userwhite.svg";
import user from "../../assets/user.svg";
import docacti from "../../assets/docacti.svg";


const menuItemsUser = [
    {
        name: "Documentos", exact: "true", to: '/documentacion', iconClassName: doc, iconClassNameWhite: docwhite,
    },
    {
        name: "Actividad", exact: "true", to: '/actividad', iconClassName: actividad, iconClassNameWhite: actividadwhite,
    },
    /*{
      name: "Facturación", exact: "true", to: '/', iconClassName: 'bi bi-speedometer2'
    },*/
    {
        name: "Usuarios", exact: "true", to: '/usuario', iconClassName: user, iconClassNameWhite: userwhite,
    }
]

const menuItemsAdmin = [
    {
        name: "Clientes", exact: "true", to: '/clientes', iconClassNameWhite: docwhite,
    },
    {
        name: "Consumo", exact: "true", to: '/consumo', iconClassNameWhite: actividadwhite,
    },
    {
        name: "Actividad", exact: "true", to: '/actividadadm', iconClassNameWhite: docacti,
    },
    {
        name: "Facturación", exact: "true", to: '/facturacion', iconClassNameWhite: userwhite,
    },
]

const NavbarHeader = (props) => {
    const [valueSearch, setValueSearch] = useState("");
    const user = JSON.parse(localStorage.getItem('user-info'));
    const [activeDrop, setActiveDrop] = useState(false)
    const [idSearch, setIdSearch] = useState(0);
    const navigate = useNavigate();

    const logeOut = () => {
        localStorage.removeItem('rol-user');
        localStorage.removeItem('user-info');
        navigate('/usuario')
    }

    const onSearch = (searchTerm) => {
        setValueSearch(searchTerm);
        setActiveDrop(false)

        // our api to fetch the search result
    };


    useEffect(() => {
    }, []);

    return (
        <>
            <Navbar className="nav-css" collapseOnSelect expand="lg">
                <div className="container-user">
                    <div className="containerNavtitle">
                        <h4 className="navTitle">{props.titlePage}</h4>
                        {props.titlePage === 'Clientes' &&
                            (
                                <>
                                    <small className="rol-user fs-6  ms-1 pt-1 infoSmall">Tienes actualmente  {props.listUsers}  {props.listUsers === 1 && (
                                        <>
                                            cliente registrado
                                        </>
                                    )}
                                        {props.listUsers > 1 && (
                                            <>
                                                clientes registrados
                                            </>
                                        )}
                                    </small>
                                </>
                            )
                        }

                        {props.titlePage === 'Usuarios' &&
                            (
                                <>
                                    <small className="rol-user fs-6  ms-1 pt-1 infoSmall">Tienes actualmente  {props.listUsers}  {props.listUsers === 1 && (
                                        <>
                                            usuario registrado
                                        </>
                                    )}
                                        {props.listUsers > 1 && (
                                            <>
                                                usuarios registrados
                                            </>
                                        )}
                                    </small>
                                </>
                            )
                        }

                        {props.titlePage === 'Actividad' &&
                            (
                                <>
                                    <small className="rol-user fs-6  ms-1 pt-1 infoSmall">Tienes actualmente  {props.listUsers}  {props.listUsers === 1 && (
                                        <>
                                            Actividad registrada
                                        </>
                                    )}
                                        {props.listUsers > 1 && (
                                            <>
                                                Actividades registradas
                                            </>
                                        )}
                                    </small>
                                </>
                            )
                        }

                        {props.titlePage === 'Documentos' &&
                            (
                                <>
                                    <small className="rol-user fs-6  ms-1 pt-1 infoSmall">Tienes actualmente  {props.testing }  documentos
                                      
                                    </small>
                                </>
                            )
                        }

                    </div>
                </div>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="margin-navbar">
                    <Nav className="me-auto">


                        {/*<span className="input-group-append">
                            <button
                                className="btn btn-outline-secondary  border-bottom-0 border rounded-pill ms-n5555" type="button">
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                        </span>*/}
                    </Nav>


                    <form className="nosubmit">
                        <Input
                            placeholder={props.inputPlaceHolder}
                            value={props.inputValue}
                            style={{ width: "300px" }}
                            className="nosubmit"
                            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                            onChange={e => {

                                const currValue = e.target.value;
                                setValueSearch(currValue);
                                props.setValue(currValue);
                                props.searchName(currValue === "" ? null : currValue);
                                // if (props.titlePage === 'Documentos') {
                                //     const filterTable = props.dataFilter.filter(entry =>
                                //         entry.infoDocumento.nombre.toLowerCase().includes(currValue.toLowerCase()) || entry.estudiante.nombre.toLowerCase().includes(currValue.toLowerCase()) || entry.estudiante.id.toLowerCase().includes(currValue.toLowerCase()) || entry.programa.nombre.toLowerCase().includes(currValue.toLowerCase()) || entry.programa.id.toLowerCase().includes(currValue.toLowerCase())
                                //     );
                                //     props.setFilterTable(filterTable);
                                // } else if (props.titlePage === 'Actividad') {
                                //     props.setValue(currValue);

                                //     const filterTable = props.dataFilter.filter(entry =>
                                //         entry.actividad.toLowerCase().includes(currValue.toLowerCase())
                                //     );
                                    
                                //     props.setFilterTable(filterTable);
                                // } else if (props.titlePage === 'Usuarios') {
                                //     props.setValue(currValue);
                                //     const filterTable = props.dataFilter.filter(entry =>
                                //         entry.nombre.toLowerCase().includes(currValue.toLowerCase())
                                //     );
                                //     props.setFilterTable(filterTable);
                                // } else if (props.idNav === '1') {
                                //     props.setValue(currValue);
                                //     const filterTable = props.dataFilter.filter(entry =>
                                //         entry.infoCliente.nombre.toLowerCase().includes(currValue.toLowerCase()) || entry.actividad.toLowerCase().includes(currValue.toLowerCase()) || entry.detalles.toLowerCase().includes(currValue.toLowerCase()) || entry.infoDocumento.nombre.toLowerCase().includes(currValue.toLowerCase()) || entry.infoUsuario.nombre.toLowerCase().includes(currValue.toLowerCase())
                                //     );
                                //     props.setFilterTable(filterTable);
                                // } else if (props.titlePage === 'Clientes') {
                                //     props.setValue(currValue);
                                //     const filterTable = props.dataFilter.filter(entry =>
                                //         entry.nombre.toLowerCase().includes(currValue.toLowerCase())
                                //     );
                                   
                                //     props.setFilterTable(filterTable);
                                // } else if (props.titlePage === 'Consumo') {
                                //     props.setValue(currValue);
                                //     const filterTable = props.dataFilter.filter(entry =>
                                //         entry.cliente.nombre.toLowerCase().includes(currValue.toLowerCase()) || entry.creador.nombre.toLowerCase().includes(currValue.toLowerCase())
                                //     );
                                    
                                //     props.setFilterTable(filterTable);
                                // }
                                // else if (props.titlePage === 'Facturación') {
                                //     props.setValue(currValue);
                                //     const filterTable = props.dataFilter.filter(entry =>
                                //         entry.nombre.toLowerCase().includes(currValue.toLowerCase())
                                //     );
                                    
                                //     props.setFilterTable(filterTable);
                                // }
                            }}
                        />
                        
                    </form>
                    <div className="container-user">
                        <div className="user-info-nav">
                            <p className="name-user">{user?.body?.user.nombre}</p>
                            {user?.body?.user.rol === "CLIENT_ROL" && (
                                <>
                                    <p className="rol-user">Cliente</p>
                                </>
                            )}
                            {user?.body?.user.rol === "USER_ROL" && (
                                <>
                                    <p className="rol-user">Usuario</p>
                                </>
                            )}
                            {user?.body?.user.rol === "ADMIN_ROL" && (
                                <>
                                    <p className="rol-user">Administrador</p>
                                </>
                            )}
                            <Button onClick={logeOut} className="loge-out-css">Cerrar sesión</Button>
                        </div>
                    </div>

                    <ul className="d-sm-none" style={{ width: "100%" }}>
                        {user?.body?.user.rol === "CLIENT_ROL" && (
                            <>
                                {
                                    menuItemsUser.map((item, index) => (
                                        <div className='menu-item flex-column-auto px-9 mb-9' id='kt_aside_logo' key={index}>
                                            <li className="padding-li">
                                                <NavLink
                                                    exact={item.exact}
                                                    to={item.to}
                                                    className={`menu-item`}
                                                >

                                                    <div className="menu-icon-new">
                                                        <img className="share-icon2" src={item.iconClassNameWhite} alt="share" />
                                                    </div>

                                                    <span>{item.name}</span>
                                                </NavLink>
                                            </li>
                                        </div>
                                    ))
                                }
                            </>
                        )}

                        {user?.body?.user.rol === "USER_ROL" && (
                            <>
                                {
                                    menuItemsUser.map(item => (
                                        <div className='menu-item flex-column-auto px-9 mb-9' id='kt_aside_logo'>
                                            <li className="padding-li">
                                                <NavLink
                                                    exact
                                                    to={item.to}
                                                    className={`menu-item`}
                                                >

                                                    <div className="menu-icon-new">
                                                        <img className="share-icon2" src={item.iconClassNameWhite} alt="share" />
                                                    </div>

                                                    <span>{item.name}</span>
                                                </NavLink>
                                            </li>
                                        </div>
                                    ))
                                }
                            </>
                        )}

                        {user?.body?.user.rol === "ADMIN_ROL" && (
                            <>
                                {
                                    menuItemsAdmin.map(item => (
                                        <div className='menu-item flex-column-auto px-9 mb-9' id='kt_aside_logo'>
                                            <li className="padding-li">
                                                <NavLink
                                                    exact
                                                    to={item.to}
                                                    className={`menu-item`}
                                                >

                                                    <div className="menu-icon-new">
                                                        <img className="share-icon2" src={item.iconClassNameWhite} alt="share" />
                                                    </div>

                                                    <span>{item.name}</span>
                                                </NavLink>
                                            </li>
                                        </div>
                                    ))
                                }
                            </>
                        )}

                    </ul>

                </Navbar.Collapse>
            </Navbar>
        </>
    )
}

export default NavbarHeader