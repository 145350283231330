import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import logo from "../../assets/logo/LogoCerti.png";
import doc from "../../assets/doc.svg";
import actividad from "../../assets/actividad.svg";
import actividadwhite from "../../assets/actividadwhite.svg";
import docwhite from "../../assets/docwhite.svg";
import userwhite from "../../assets/userwhite.svg";
import user from "../../assets/user.svg";
import menuIcon from "../../assets/ic_menu.svg";

import favcerti from "../../assets/logo/favicon_certi.png";

// http://back.credentials.esade.edu/imagenes/favicons/esade@esade.com-favicon.png
// https://back.credentials.esade.edu/imagenes/logos/esade@esade.com-logo.png

const menuItems = [
  {
    name: "Documentos", exact: "true", to: '/documentacion', iconClassName: doc, iconClassNameWhite: docwhite,
  },
  {
    name: "Actividad", exact: "true", to: '/actividad', iconClassName: actividad, iconClassNameWhite: actividadwhite,
  },
  /*{
    name: "Facturación", exact: true, to: '/', iconClassName: 'bi bi-speedometer2'
  },*/
  {
    name: "Usuarios", exact: "true", to: '/usuario', iconClassName: user, iconClassNameWhite: userwhite,
  }
]

const SideMenu = (props) => {
  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
  const [inactive, setInative] = useState(sidebarCollapsed ? false : true);


  useEffect(() => {
    props.onCollapse(inactive);
  }, [inactive]);

  const user = JSON.parse(localStorage.getItem('user-info'));

  const handleToggler = () => {
    if (inactive) {
      setInative(false);
      localStorage.setItem('sidebar-collapsed', true);
      return;
    }
    setInative(true);
    localStorage.removeItem('sidebar-collapsed');
  };


  return (
    <div
      id='kt_aside'
      className={`aside py-9 pt-4 d-none d-sm-block side-menu ${inactive ? "inactive" : "side-menu.inactive"}`}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_toggle'
    >
      <div onClick={handleToggler}
        className="toggle-menu-btn">
        {inactive ? (
          <img className="menu-icon-css" src={menuIcon} alt="menuIcon" />
        ) : (
          <img className="menu-icon-css" src={menuIcon} alt="menuIcon" />
        )}
      </div>
      <div className={`aside-logo flex-column-auto px-9 mb-9 ${inactive ? "inactive" : ""}`}>
        <img className="logo-css-certi" src={`https://back.credentials.esade.edu/imagenes/logos/esade@esade.com-logo.png`} alt="certiblock" />
      </div>
      <div className={`aside-menu flex-column-fluid  mb-9  ${inactive ? "inactive" : ""}`}>
        <ul style={{ width: "100%" }}>
          {
            menuItems.map((item, index) => {
              return <div className='menu-item flex-column-auto px-9 mb-9' id='kt_aside_logo' key={index}>
                <li className="padding-li" onClick={props.onClick}>
                  <NavLink
                    exact={item.exact}
                    to={item.to}
                    className={`menu-item`}
                  >

                    <div className="menu-icon-new">
                      <img className="share-icon2" src={item.iconClassNameWhite} alt="share" />
                    </div>

                    <span>{item.name}</span>
                  </NavLink>
                </li>
              </div>
            })
          }
          <div
            className={`menu-content ${inactive ? "inactive" : ""}`}
          >

          </div>
        </ul>

      </div>
      <div className="side-menu-footer">
        <div className="user-info">
          <img className="logo-css" style={{ marginLeft: "18px" }} src={logo} alt="certiblock" />
        </div>
        <div className={` ${inactive ? "d-none" : "copyText"}`} style={{marginBottom: "57px", fontSize: "10px"}}>
          <span>ⓒ Certiblock v1.0.1</span>
        </div>
      </div>

      <div className={`side-menu-fav ${inactive ? "inactive" : ""}`}>
        <div className={`${inactive ? "inactive" : "user-fav"}`}>
          <img className="fav-css" src={favcerti} alt="certiblock" />
        </div>
      </div>
    </div>

  );
};

export default SideMenu