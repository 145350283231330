import React from 'react'
import logo from "../../assets/logo/LogoCerti.png";
import { Row, Col, Form, Button, Modal, Alert, Tooltip } from 'react-bootstrap';

const NoRoute = () => {
  return (
    <>
      <div className="centerDiv">
        <div style={{ margin: "auto" }}>
          <img  src={logo} alt="certiblock" />

          <h2>¡Error , Ruta no existente!</h2>
        </div>
      </div>
    </>
  )
}

export default NoRoute