import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';

import en from './../lang/en-US.json';
import es from './../lang/es-ES.json';
import ca from './../lang/ca-ES.json';

const langContext = React.createContext();

const LangProvider = ({ children }) => {
    const [mensajes, setMensajes] = useState(en);
    const [locale, setLocale] = useState('en-US');

    const setLenguaje = (lenguaje) => {
        switch (lenguaje) {
            case 'es-ES':
                setMensajes(es);
                setLocale('es-ES');
                break;
            case 'en-US':
                setMensajes(en);
                setLocale('en-US');
                break;
            case 'ca-ES':
                setMensajes(ca);
                setLocale('ca-ES');
                break;
            default:
                setMensajes(es);
                setLocale('es-ES');
        }
    }

    return (
        <langContext.Provider value={{ setLenguaje: setLenguaje }}>
            <IntlProvider locale={locale} messages={mensajes}>
                {children}
            </IntlProvider>
        </langContext.Provider>
    );
}

export { LangProvider, langContext };
